import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
// const { Helmet } = require("react-helmet");
import EmployeeListPanel from "./components/EmployeeListPanel";
import EmployeeDetails from "./components/EmployeeDetails";

//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import axios from "axios";
import { apiURL } from "../../constants/URL";

const drawerWidth = 410;

type Anyobj = {
  [key: string]: any;
};

const styles: Anyobj = (theme: any) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: "408px",
    [theme.breakpoints.up("md")]: {
      position: "relative",
      width: drawerWidth,
    },
    backgroundColor: "#fff",
  },
  content: {
    flexGrow: 1,
  },
});

type Indexprops = {
  classes: any;
  theme: any;
};

type AppState = {
  mobileOpen: boolean;
  domain: any;
  formdata: {
    name: string;
    role: any;
    phone: any;
    email: string;
    userID: string;
    search: any;
    searchInvitationListInput: any;
    domain: string;
  };
  initialEmployeeList: any;
  initialInvitationList: any;
  employeeList: any;
  invitationList: any;
  open: boolean;
  status: any;
  errors: Anyobj;
  employeeDetail: any;
  serverstatus: boolean;
  showEmployeeDetails: boolean;
};

class EmployeePage extends Component<Indexprops, AppState> {
  _isMounted = false;

  constructor(props: Indexprops) {
    super(props);
    this.state = {
      mobileOpen: false,
      showEmployeeDetails: false,
      employeeDetail: {},
      domain: "",
      formdata: {
        name: "",
        role: "",
        phone: "",
        email: "",
        userID: "",
        search: "",
        searchInvitationListInput: "",
        domain: "",
      },
      initialEmployeeList: [],
      employeeList: [],
      invitationList: [],
      initialInvitationList: [],
      open: false,
      status: "",
      errors: {},
      serverstatus: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getEmployeesList();
  }

  getEmployeesList = () => {
    if (sessionStorage.getItem("email")) {
      let useremail: any = sessionStorage.getItem("email");
      let user = sessionStorage.getItem("username");
      console.log("user is ---> ", user);
      console.log("User name is -- " + useremail + " User id is -- ");
      let domain = useremail.split("@");
      console.log("HostName --" + domain[1]);
      let reqDomain = domain[1].split(".").join("_");
      console.log("User domain is --", reqDomain);
      let domainRef = firebase
        .database()
        .ref("domains/" + reqDomain + "/users");
      domainRef.on(
        "value",
        (usersSnap) => {
          let users = { ...usersSnap.val() };
          if (users !== null) {
            let dataRef = firebase
              .database()
              .ref("domains/" + reqDomain + "/invitations");
            dataRef.on(
              "value",
              (invitationSnap) => {
                let invitationList: any[] = [];
                let invitations = { ...invitationSnap.val() };
                if (invitations !== null) {
                  for (let key in invitations) {
                    let tempObj = { key, details: invitations[key] };
                    invitationList.push(tempObj);
                  }
                  this.fetchEmployeeDetails(users, invitationList);
                }
              },
              (error: Anyobj) => {
                console.log("Error -- ", error.code);
                this.fetchEmployeeDetails(users, []);
              }
            );
          }
        },
        (error: Anyobj) => {
          console.log("Error -- ", error.code);
          this.setState({
            serverstatus: true,
            invitationList: [],
            employeeList: [],
            initialEmployeeList: [],
          });
        }
      );
    }
  };

  fetchEmployeeDetails = (users: Anyobj, invitationList: Anyobj[] | []) => {
    let tempEmployeeList: any = [];
    let profileCounter = 0;
    let admin_domain = sessionStorage.getItem("admin_domain") || "";
    for (let key in users) {
      console.log("Fetching details of --- ", key);
      let usersRef = firebase.database().ref("Users/" + key);
      usersRef.on(
        "value",
        (userSnap) => {
          console.log("Got details of --- ", key);
          let UserDetails = userSnap.val();
          let details: any = {};
          details.role = users[key].role;
          details.name = UserDetails.profile.fullname;
          details.phoneNumber = UserDetails.profile.phoneNumber;
          details.email = UserDetails.profile.email;
          if (UserDetails["allow_domain"]) {
            details.allowed = UserDetails["allow_domain"][admin_domain];
          }
          if (UserDetails.profile.domain) {
            details.domain = UserDetails.profile.domain;
          }

          console.log("Details are", details);
          let tempObj = { key, details };
          var index = tempEmployeeList
            .map(function (e: Anyobj) {
              return e.key;
            })
            .indexOf(key);
          if (index < 0) {
            tempEmployeeList.push(tempObj);
            profileCounter += 1;
          } else tempEmployeeList[index] = tempObj;

          console.log(
            "profile counter -- ",
            profileCounter,
            "object length---",
            Object.keys(users).length
          );
          if (profileCounter === Object.keys(users).length) {
            this.setState({
              serverstatus: true,
              initialEmployeeList: tempEmployeeList,
              employeeList: tempEmployeeList,
              invitationList,
              initialInvitationList: invitationList,
              status: "",
              open: false,
            });
          }
        },
        (error: Anyobj) => {
          console.log("Error while fetching details -- ", error.code);
          profileCounter += 1;
          // checkCounter()
          if (profileCounter === Object.keys(users).length) {
            if (this._isMounted) {
              this.setState({
                serverstatus: true,
                initialEmployeeList: tempEmployeeList,
                employeeList: tempEmployeeList,
                invitationList,
                initialInvitationList: invitationList,
                status: "",
                open: false,
              });
            }
          }
        }
      );
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  validateInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (e.currentTarget.name) {
      case "name":
        if (!e.currentTarget.value.trim()) return "*Name is required";
        break;
      case "email":
        if (!e.currentTarget.value.trim()) return "*Email is required";
        else if (
          !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            e.currentTarget.value
          )
        )
          return "*not a valid email";
        break;
      case "phone":
        if (!e.currentTarget.value.trim()) return "*Contact no. is required";
        //else if (!/^\d{12}$/.test(e.currentTarget.value))
        else if (
          !/^\+?([0-9]{1,4})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{5})$/.test(
            e.currentTarget.value
          )
        )
          return "*not a valid phone number";
        break;
      case "role":
        if (!e.currentTarget.value.trim()) return "*required";
        break;
      default:
        break;
    }
    return "";
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: input } = e;
    let errString = this.validateInput(e);
    const { formdata, initialEmployeeList, initialInvitationList } = this.state;
    const data: any = { ...formdata };
    let errors = { ...this.state.errors };
    errors[input.name] = errString;
    data[input.name] = input.value;
    let updatedList = [...initialEmployeeList];
    let updatedInvitationList = [...initialInvitationList];
    if (input.name === "search") {
      updatedList = updatedList.filter(function (item) {
        let item1 = item.details.name;
        return item1.toLowerCase().search(input.value.toLowerCase()) !== -1;
      });
    }
    if (input.name === "searchInvitationListInput") {
      updatedInvitationList = updatedInvitationList.filter(function (item) {
        let item1 = item.details.email;
        if (item1 !== undefined)
          return item1.toLowerCase().search(input.value.toLowerCase()) !== -1;
      });
    }
    console.log("Editing -----" + input.name);
    this.setState({
      formdata: data,
      errors,
      employeeList: updatedList,
      invitationList: updatedInvitationList,
    });
  };

  handlePhoneInputChange = (value: string) => {
    this.setState({
      formdata: {
        ...this.state.formdata,
        phone: value,
      },
    });
  };

  handleClose = () => {
    this.setState({ open: false, status: "" });
  };

  onEditIconClick = (data: Anyobj) => {
    const { name, phoneNumber, role, email, domain } = data.details;
    this.setState({
      open: true,
      status: "edit",
      formdata: {
        name: name,
        phone: phoneNumber,
        role: role,
        userID: data.key,
        email: email,
        search: "",
        searchInvitationListInput: "",
        domain,
      },
    });
  };

  onInviteIconClick = () => {
    this.setState({
      open: true,
      status: "invite",
      formdata: {
        name: "",
        role: "",
        phone: "",
        email: "",
        userID: "",
        search: "",
        searchInvitationListInput: "",
        domain: "",
      },
    });
  };

  onDeleteIconClick = (data: Anyobj) => {
    const { name, phoneNumber, role, email } = data.details;
    this.setState({
      open: true,
      status: "delete",
      formdata: {
        name: name,
        phone: phoneNumber,
        role: role,
        userID: data.key,
        email: email,
        search: "",
        searchInvitationListInput: "",
        domain: "",
      },
    });
  };

  onShowEmployeeDetails = (data: any) => {
    this.setState({ showEmployeeDetails: true, employeeDetail: data });
  };
  onMenuIconPress = () => {
    console.log("closing details");
    this.setState({ showEmployeeDetails: false });
  };

  onme;

  render() {
    const { classes, theme } = this.props;
    const {
      open,
      formdata,
      status,
      errors,
      serverstatus,
      employeeList,
      mobileOpen,
      invitationList,
      showEmployeeDetails,
      employeeDetail,
    } = this.state;
    const drawer = (
      <EmployeeListPanel
        formdata={formdata}
        status={status}
        serverstatus={serverstatus}
        errors={errors}
        open={open}
        employees={employeeList}
        invitationList={invitationList}
        onDeleteIconClick={this.onDeleteIconClick}
        onEditIconClick={this.onEditIconClick}
        onInputChange={this.handleInputChange}
        onInviteIconClick={this.onInviteIconClick}
        onPhoneInputChange={this.handlePhoneInputChange}
        onClose={this.handleClose}
        onShowEmployeeDetails={this.onShowEmployeeDetails}
      />
    );
    return (
      <div className="blank-wrapper">
        <Helmet>
          <title>Employee Lists</title>
          <meta name="description" content="Reactify Blank Page" />
        </Helmet>
        <div>
          <div className={classes.root}>
            <Hidden
              mdUp
              // className="user-list-wrap"
            >
              <Drawer
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden
              smDown
              implementation="css"
              // className="user-list-wrap"
            >
              <Drawer
                variant="permanent"
                open
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <div className={`chat-content ${classes.content}`}>
              {showEmployeeDetails ? (
                <EmployeeDetails
                  employeeDetail={employeeDetail}
                  onMenuIconPress={this.onMenuIconPress}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EmployeePage);
