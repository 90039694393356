import React, { Component } from "react";
// import * as React from 'react';

import SweetAlert from "react-bootstrap-sweetalert";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import { apiURL } from "../../../constants/URL";

//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

type Deleteprops = {
  formdata: {
    userID: string;
    name: string;
  };
  onClose: any;
  open: any;
};

class DeleteDialogueBox extends Component<Deleteprops> {

  confirmDelete = () => {
    console.log("props are ---", this.props);
    let data = { ...this.props.formdata };
    let email: any = sessionStorage.getItem("email");
    let domain = email.split("@");
    console.log("HostName --" + domain[1]);
    let reqDomain = domain[1].split(".").join("_");
    let domainRef = firebase
      .database()
      .ref("/domains")
      .child(reqDomain)
      .child("users")
      .child(data.userID);
    domainRef
      .remove()
      .then(() => {
        let allowDomainRefRef = firebase
          .database()
          .ref("/Users")
          .child(data.userID)
          .child("/allow_domain");
        allowDomainRefRef
          .child(`${reqDomain}`)
          .remove()
          .then(() => {
            NotificationManager.success("User deleted successfully!");
            console.log("success");
          })
          .catch((error) => {
            // Handle Errors here.
            NotificationManager.error(error.message);
            console.log("domain is not deleted from Users/AllowDomain tree...", error);
          });
      })
      .catch((error) => {
        // Handle Errors here.
        NotificationManager.error(error.message);
        console.log("User is not deleted from domains/domain-name/users...", error);
      });

  };

  render() {
    const { open, formdata } = this.props;
    console.log("in the delete dialog box", this.props);
    return (
      <React.Fragment>
        <SweetAlert
          warning
          btnSize="sm"
          show={open}
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title={"Are you sure?"}
          onConfirm={this.confirmDelete}
          // onConfirm={console.log("sweet alert.")}
          onCancel={this.props.onClose}
        >
          {formdata.name}
          will be permanently deleted.
        </SweetAlert>
      </React.Fragment>
    );
  }
}

export default DeleteDialogueBox;
