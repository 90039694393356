import React, { Component } from "react";
// import * as React from 'react';

import SweetAlert from "react-bootstrap-sweetalert";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import { apiURL } from "../../../constants/URL";

//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

type AnyObj = {
  [key: string]: any;
};

type Deleteprops = {
  open: boolean;
  deleteUser: AnyObj;
  onClose(): void;
};

class DeleteDialogueBox extends Component<Deleteprops> {
  confirmDelete = (userid) => {
    axios
      .delete(apiURL + `/${userid}/deleteprofile`)
      .then((response) => {
        console.log("User is deleted from the database completely..", response);
        NotificationManager.success("Deleted successfully!");
      })
      .catch((error) => {
        // this.setState({ loading: false });
        console.log("error is --->", error);
        NotificationManager.error(error.message);
        this.props.onClose();
        //this.setState({ open: false, deleteloader: false });
      });
  };

  render() {
    const { open, deleteUser } = this.props;
    return (
      <React.Fragment>
        <SweetAlert
          warning
          btnSize="sm"
          show={open}
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title={"Are you sure?"}
          onConfirm={() => this.confirmDelete(deleteUser.key)}
          onCancel={this.props.onClose}
        >
          {Object.keys(deleteUser).length > 0 &&
          deleteUser.details &&
          deleteUser.details.profile &&
          deleteUser.details.profile.phoneNumber
            ? deleteUser.details.profile.phoneNumber
            : "NA"}{" "}
          will be permanently deleted.
        </SweetAlert>
      </React.Fragment>
    );
  }
}

export default DeleteDialogueBox;
