/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
import firebase from "firebase/app";
import "firebase/auth";
import axios from "axios";
import { apiURL } from "../constants/URL";

import { NotificationManager } from "react-notifications";
const {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
} = require("Actions/types");

/**
 * Redux Action To Sigin User With Firebase
 */

export const signinUserByPhoneInFirebase = (user, history) => (dispatch) => {
  // console.log("sign in by phone", user)
  const applicationVerifier: any = new firebase.auth.RecaptchaVerifier(
    "recaptcha-container",
    {
      size: "invisible",
      callback: function (response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
        console.log("captcha solved...");
      },
      "expired-callback": function () {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
      },
    }
  );
  const provider = new firebase.auth.PhoneAuthProvider();
  const phoneNumber = `+${user.phone}`;
  sessionStorage.setItem("phone", phoneNumber);

  provider
    .verifyPhoneNumber(phoneNumber, applicationVerifier)
    .then((verificationId) => {
      const verificationCode: any = window.prompt(
        "Please enter the verification " +
          "code that was sent to your mobile device."
      );
      return firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
    })
    .then((phoneCredential) => {
      return firebase.auth().signInWithCredential(phoneCredential);
    })
    .then((result: any) => {
      dispatch({ type: LOGIN_USER });
      console.log("User ID is -- ", result.user);
      console.log("User Email is -- ", result.user.email);
      sessionStorage.setItem("user_id", result.user.uid);
      sessionStorage.setItem("phone", result.user.phoneNumber);
      // localStorage.setItem("user_id", result.user.uid);
      if (!result.user.email) {
        dispatch({ type: LOGIN_USER_FAILURE });
        return history.push("/user-details");
      }
      axios
        .post(apiURL + "/user/createAceessToken", {
          userID: result.user.uid,
        })
        .then(({ data: data }) => {
          if (data.code) {
            console.log("Error occured. Code is --- ", data.code);
          } else {
            console.log("Custom token is --- ", data.customToken);
            firebase
              .auth()
              .signInWithCustomToken(data.customToken)
              .then((message) => {
                console.log(
                  "signInWithCustomToken successfull with message --",
                  message
                );
                let userRef = firebase
                  .database()
                  .ref("Users/" + result.user.uid + "/profile");
                userRef
                  .once("value", (snapshot) => {
                    let userID = result.user.uid;

                    //Check for super-admin
                    if (snapshot.val().isSuperAdmin) {
                      console.log("User is Super-Admin...");
                      sessionStorage.setItem(
                        "super_admin",
                        snapshot.val().isSuperAdmin
                      );
                    }

                    if (snapshot.val().domain) {
                      //Admin Login
                      console.log("Welcome Admin. --");
                      console.log("Your email is. --", snapshot.val().email);
                      sessionStorage.setItem("email", snapshot.val().email);
                      sessionStorage.setItem(
                        "username",
                        snapshot.val().fullname
                      );
                      sessionStorage.setItem("user_id", userID);
                      // localStorage.setItem("user_id", userID);

                      let domainRef = firebase
                        .database()
                        .ref("domains/" + snapshot.val().domain);
                      domainRef
                        .once("value", (domainSnap) => {
                          console.log("domainRef", domainSnap.val().isActive);
                          if (domainSnap.val().isActive) {
                            sessionStorage.setItem(
                              "admin_domain",
                              snapshot.val().domain
                            );
                          }
                        })
                        .then((response) => {
                          dispatch({
                            type: LOGIN_USER_SUCCESS,
                            payload: sessionStorage.getItem("user_id"),
                            // payload: localStorage.getItem("user_id"),
                          });
                          if (result.user.emailVerified) {
                            console.log("Your email is verified");
                            history.push("/app/EmployeeList");
                          } else {
                            console.log("Your email is not verified");
                            history.push("/app/user/user-profile-1");
                          }
                          NotificationManager.success(
                            "Welcome " + snapshot.val().fullname + "!"
                          );
                        })
                        .catch((error) => {
                          console.log(
                            "error while fetching the data from domainRef..."
                          );
                        });
                    } else {
                      //Employee Login
                      console.log("Welcome Employee. --");
                      sessionStorage.setItem("user_id", userID);
                      // localStorage.setItem("user_id", userID);
                      if (snapshot.val().email) {
                        console.log("Your email is. --", snapshot.val().email);
                        sessionStorage.setItem("email", snapshot.val().email);
                        sessionStorage.setItem(
                          "username",
                          snapshot.val().fullname
                        );
                      }
                      dispatch({
                        type: LOGIN_USER_SUCCESS,
                        payload: sessionStorage.getItem("user_id"),
                        // payload: localStorage.getItem("user_id"),
                      });
                      // history.push("/app/invitations");
                      if (result.user.emailVerified) {
                        console.log("Your email is verified");
                        history.push("/app/invitations");
                      } else {
                        console.log("Your email is not verified");
                        history.push("/app/user/user-profile-1");
                      }
                      NotificationManager.success(
                        "Welcome " + snapshot.val().fullname + "!"
                      );
                    }
                  })
                  .catch((error) => {
                    console.log("Error : ", error);
                  });
                //
              })
              .catch(function (error) {
                console.log(
                  "Error Occured During signInWithCustomToken --",
                  error
                );
              });
          }
        })
        .catch((error) => {
          console.log("Error occured. Details --- ", error.message);
        });
    })
    .catch((error) => {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};

export const signinUserInFirebase = (user, history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  axios
    .post(apiURL + "/user/signin", {
      email: user.email,
      password: user.password,
    })
    .then(({ data: data }) => {
      if (data.code) {
        dispatch({ type: LOGIN_USER_FAILURE });
        NotificationManager.error(data.message);
      } else {
        console.log("Signing in -- ", data.response);
        console.log("Email is -- " + data.response.user.email);
        console.log("userId is -- " + data.response.user.uid);
        console.log("phoneNumber is -- ", data.response.user.phoneNumber);
        sessionStorage.setItem("email", data.response.user.email);
        sessionStorage.setItem("username", data.response.user.displayName);
        sessionStorage.setItem("phone", data.response.user.phoneNumber);
        let domain = user.email.split("@");
        console.log("HostName --" + domain[1]);
        //let reqDomain = domain[1].split(".").join("_");
        firebase
          .auth()
          .signInWithCustomToken(data.customToken)
          .then((message) => {
            console.log(
              "signInWithCustomToken successfull with message --",
              message
            );
            //firebase.auth().currentUser.sendEmailVerification();
            let userRef = firebase
              .database()
              .ref("Users/" + data.response.user.uid + "/profile");
            userRef
              .once("value", (snapshot) => {
                let userID = data.response.user.uid;

                //Check for super-admin
                if (snapshot.val().isSuperAdmin) {
                  console.log("User is Super-Admin...");
                  sessionStorage.setItem(
                    "super_admin",
                    snapshot.val().isSuperAdmin
                  );
                }

                if (snapshot.val().domain) {
                  //Admin Login
                  console.log("Welcome Admin. --");
                  sessionStorage.setItem("user_id", userID);
                  // localStorage.setItem("user_id", userID);
                  let domainRef = firebase
                    .database()
                    .ref("domains/" + snapshot.val().domain);
                  domainRef
                    .once("value", (domainSnap) => {
                      console.log("domainRef", domainSnap.val().isActive);
                      if (domainSnap.val().isActive) {
                        sessionStorage.setItem(
                          "admin_domain",
                          snapshot.val().domain
                        );
                      }
                    })
                    .then((response) => {
                      dispatch({
                        type: LOGIN_USER_SUCCESS,
                        payload: sessionStorage.getItem("user_id"),
                        // payload: localStorage.getItem("user_id"),
                      });
                      if (data.response.user.emailVerified) {
                        console.log("your email is verified.");
                        history.push("/app/EmployeeList");
                      } else {
                        console.log("your email is not verified.");
                        history.push("/app/user/user-profile-1");
                      }
                      NotificationManager.success(
                        "Welcome " + data.response.user.displayName + "!"
                      );
                    })
                    .catch((error) => {
                      console.log(
                        "error while fetching the data from domainRef..."
                      );
                    });
                } else {
                  //Employee Login
                  console.log("Welcome Employee. --");
                  sessionStorage.setItem("user_id", userID);
                  // localStorage.setItem("user_id", userID);
                  dispatch({
                    type: LOGIN_USER_SUCCESS,
                    payload: sessionStorage.getItem("user_id"),
                    // payload: localStorage.getItem("user_id"),
                  });
                  // history.push("/app/invitations");
                  if (data.response.user.emailVerified) {
                    console.log("your email is verified.");
                    history.push("/app/invitations");
                  } else {
                    console.log("your email is not verified.");
                    history.push("/app/user/user-profile-1");
                  }
                  NotificationManager.success(
                    "Welcome " + data.response.user.displayName + "!"
                  );
                }
              })
              .catch((error) => {
                console.log("Error : ", error);
              });
          })
          .catch(function (error) {
            console.log("Error Occured During signInWithCustomToken --", error);
          });
      }
    })
    .catch((error) => {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};
/**
 * Redux Action To Signout User From  Firebase
 */
export const logoutUserFromFirebase = () => (dispatch) => {
  console.log(apiURL);
  axios
    .get(apiURL + "/user/signout")
    .then((response) => {
      console.log(response.data);
      dispatch({ type: LOGOUT_USER });
      sessionStorage.removeItem("username");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("admin_domain");
      sessionStorage.removeItem("user_id");
      sessionStorage.removeItem("phone");
      sessionStorage.removeItem("required-data-type");
      sessionStorage.removeItem("super_admin");
      NotificationManager.success("User Logout Successfully");
    })
    .catch((error) => {
      NotificationManager.error(error.message);
    });
};

/**
 * Redux Action To Signup Admin In Firebase
 */
export const signupAdminInFirebase = (newuser, history) => (dispatch) => {
  /*Validate Domain Uniqueness*/
  const url = new URL(newuser.companyURL);
  let domain = newuser.email.split("@");
  console.log("HostName --" + url.hostname);
  console.log("Domain --" + domain[1]);
  if (url.hostname == domain[1]) {
    /*Domain and Hostname are same. Go Ahead.*/
    let reqDomain = domain[1].split(".").join("_");
    let dataRef = firebase.database().ref("domains/");
    dataRef.once(
      "value",
      (snapshot) => {
        if (snapshot.hasChild(reqDomain)) {
          /*Domain Exists*/
          console.log("Domain exists already --");
          NotificationManager.error(
            "Domain " +
              domain[1] +
              " exists already. Please selecte a new domain."
          );
        } else {
          /*Create new domain*/
          console.log("Domain does not exists. --");
          dispatch({ type: SIGNUP_USER });
          console.log("In progress...");
          axios
            .post(apiURL + "/admin/signup", {
              name: newuser.name,
              email: newuser.email,
              password: newuser.password,
              phone: newuser.phone,
              companyURL: newuser.companyURL,
              role: newuser.role,
            })
            .then((response) => {
              console.log(response.data);
              if (response.data.code) {
                dispatch({ type: SIGNUP_USER_FAILURE });
                NotificationManager.error(response.data.message);
              } else {
                console.log(
                  "Successfully created user in auth and database ...",
                  response
                );
                console.log("User ID ---" + response.data.uid);
                sessionStorage.setItem("username", newuser.name);
                sessionStorage.setItem("user_id", response.data.uid);
                // localStorage.setItem("username", newuser.name);
                // localStorage.setItem("user_id", response.data.uid);
                dispatch({
                  type: SIGNUP_USER_SUCCESS,
                  payload: sessionStorage.getItem("user_id"),
                  // payload: localStorage.getItem("user_id"),
                });
                history.push("/signin");
                NotificationManager.success("Account Created Successfully!");
              }
            })
            .catch((error) => {
              dispatch({ type: SIGNUP_USER_FAILURE });
              NotificationManager.error(error.message);
            });
        }
      },
      (error: any) => {
        console.log("Error : ", error.code);
      }
    );
  } else {
    /*Domain and Hostname are not same.*/
    console.log("Domain for site and email are not same.");
    NotificationManager.error("Company URL and email must have same host.");
  }
};

/**
 * Redux Action To Signup Employee In Firebase
 */
export const signupEmployeeInFirebase = (newuser, history) => (dispatch) => {
  dispatch({ type: SIGNUP_USER });
  axios
    .post(apiURL + "/employee/signup", {
      name: newuser.name,
      email: newuser.email,
      password: newuser.password,
      phone: newuser.phone,
      role: newuser.role,
      reqDomain: newuser.reqDomain,
      invitationID: newuser.invitationID,
      userId: newuser.userId,
    })
    .then((response) => {
      if (response.data.code) {
        NotificationManager.error(response.data.message);
      } else {
        console.log("User ID ---" + response.data.uid);
        dispatch({ type: SIGNUP_USER_SUCCESS });
        history.push("/welcome");
        NotificationManager.success("Account Created Successfully!");
      }
    })
    .catch((error) => {
      dispatch({ type: SIGNUP_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};

/**
 * Redux Action To Save User Details In Firebase
 */
export const saveUserDetailsInFirebase = (newuser, history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  axios
    .put(apiURL + `/${newuser.uid}/edit`, {
      email: newuser.email,
      password: newuser.password,
      name: newuser.name,
    })
    .then(({ data: result }) => {
      console.log("after details update user looks like ---", result);
      axios
        .post(apiURL + "/user/createAceessToken", {
          userID: result.uid,
        })
        .then(({ data: data }) => {
          if (data.code) {
            console.log("Error occured. Code is --- ", data.code);
          } else {
            console.log("Custom token got successfully... ");
            firebase
              .auth()
              .signInWithCustomToken(data.customToken)
              .then((message) => {
                console.log(
                  "signInWithCustomToken successfull with message --",
                  message
                );
                let userRef = firebase
                  .database()
                  .ref("Users/")
                  .child(result.uid)
                  .child("/profile");
                userRef
                  .set({
                    email: result.email,
                    fullname: result.displayName,
                    phoneNumber: result.phoneNumber,
                    isActive: true,
                  })
                  .then(() => {
                    console.log(
                      "successfully set user profile in Users/uid/profile --"
                    );
                    let userRef = firebase
                      .database()
                      .ref("Users/" + result.uid + "/profile");
                    userRef
                      .once("value", (snapshot) => {
                        let userID = result.uid;
                        if (snapshot.val().domain) {
                          //Admin Login
                          console.log("Welcome Admin. --");
                          console.log(
                            "Your email is. --",
                            snapshot.val().email
                          );
                          sessionStorage.setItem("email", snapshot.val().email);
                          sessionStorage.setItem(
                            "username",
                            snapshot.val().fullname
                          );
                          sessionStorage.setItem("user_id", userID);
                          // localStorage.setItem("user_id", userID);
                          sessionStorage.setItem(
                            "admin_domain",
                            snapshot.val().domain
                          );
                          dispatch({
                            type: LOGIN_USER_SUCCESS,
                            payload: sessionStorage.getItem("user_id"),
                            // payload: localStorage.getItem("user_id"),
                          });
                          history.push("/app/EmployeeList");
                          NotificationManager.success(
                            "Welcome " + result.displayName + "!"
                          );
                        } else {
                          //Employee Login
                          console.log("Welcome Employee. --");
                          sessionStorage.setItem("user_id", userID);
                          if (snapshot.val().email) {
                            console.log(
                              "Your email is. --",
                              snapshot.val().email
                            );
                            sessionStorage.setItem(
                              "email",
                              snapshot.val().email
                            );
                            sessionStorage.setItem(
                              "username",
                              snapshot.val().fullname
                            );
                          }
                          dispatch({
                            type: LOGIN_USER_SUCCESS,
                            payload: sessionStorage.getItem("user_id"),
                            // payload: localStorage.getItem("user_id"),
                          });
                          history.push("/app/invitations");
                          NotificationManager.success(
                            "Welcome " + result.displayName + "!"
                          );
                        }
                      })
                      .catch((error) => {
                        console.log("Error : ", error);
                      });
                  })
                  .catch((error) => {
                    console.log("Error : ", error);
                  });
                //
                //
              })
              .catch(function (error) {
                console.log(
                  "Error Occured During signInWithCustomToken --",
                  error
                );
              });
          }
        })
        .catch((error) => {
          console.log("Error occured. Details --- ", error.message);
        });
    })
    .catch((error) => {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};

/**
 * Redux Action To Signin User In Firebase With Facebook
 */
export const signinUserWithFacebook = (history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  const provider = new firebase.auth.FacebookAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((result: any) => {
      sessionStorage.setItem("user_id", "user-id");
      // localStorage.setItem("user_id", "user-id");
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: sessionStorage.getItem("user_id"),
        // payload: localStorage.getItem("user_id"),
      });
      history.push("/app/EmployeeList");
      NotificationManager.success(`Hi ${result.user.displayName}!`);
    })
    .catch(function (error) {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};

/**
 * Redux Action To Signin User In Firebase With Google
 */
export const signinUserWithGoogle = (history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  const provider = new firebase.auth.GoogleAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((result: any) => {
      sessionStorage.setItem("user_id", "user-id");
      // localStorage.setItem("user_id", "user-id");
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: sessionStorage.getItem("user_id"),
        // payload: localStorage.getItem("user_id"),
      });
      history.push("/");
      NotificationManager.success(`Hi ${result.user.displayName}!`);
    })
    .catch(function (error) {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};

/**
 * Redux Action To Signin User In Firebase With Github
 */
export const signinUserWithGithub = (history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  const provider = new firebase.auth.GithubAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((result: any) => {
      sessionStorage.setItem("user_id", "user-id");
      // localStorage.setItem("user_id", "user-id");
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: sessionStorage.getItem("user_id"),
        // payload: localStorage.getItem("user_id"),
      });
      history.push("/");
      NotificationManager.success(`Hi ${result.user.displayName}!`);
    })
    .catch(function (error) {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};

/**
 * Redux Action To Signin User In Firebase With Twitter
 */
export const signinUserWithTwitter = (history) => (dispatch) => {
  dispatch({ type: LOGIN_USER });
  const provider = new firebase.auth.TwitterAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then((result) => {
      sessionStorage.setItem("user_id", "user-id");
      // localStorage.setItem("user_id", "user-id");
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: sessionStorage.getItem("user_id"),
        // payload: localStorage.getItem("user_id"),
      });
      history.push("/");
      NotificationManager.success("User Login Successfully!");
    })
    .catch((error) => {
      dispatch({ type: LOGIN_USER_FAILURE });
      NotificationManager.error(error.message);
    });
};
