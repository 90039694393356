import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import { NotificationManager } from "react-notifications";
// const { NotificationManager } = require("react-notifications");
import RctPageLoader from "../../../components/RctPageLoader/RctPageLoader";
import moment from "moment";

//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

type Anyobj = {
  [key: string]: any;
};

type InvitationsListprops = {
  serverstatus: any;
  searchedInput: any;
  invitationList: any;
};

class InvitationsList extends Component<InvitationsListprops> {
  // state = {};

  confirmDeleteInvitation = (index: number) => {
    const { invitationList } = this.props;
    let tempObj = invitationList[index];
    let useremail: any = sessionStorage.getItem("email");
    let domain = useremail.split("@");
    console.log("HostName --" + domain[1]);
    let reqDomain = domain[1].split(".").join("_");
    console.log(reqDomain);

    let dataRef = firebase
      .database()
      .ref("domains/" + reqDomain + "/invitations");
    dataRef
      .child(tempObj.key)
      .remove()
      .then((response) => {
        console.log("User signed up....response - " + response);
        NotificationManager.success("Invitation deleted successfully!");
      });
  };

  getDateFormat = (date: string) => {
    return moment(date).calendar();
    //return moment(date).startOf("hour").fromNow();
    //return moment(date, "YYYYMMDD").fromNow();
  };

  render() {
    const { invitationList, serverstatus, searchedInput } = this.props;
    if (invitationList.length === 0 && !serverstatus) {
      return (
        <div style={{ position: "absolute", top: "45%", left: "45%" }}>
          <RctPageLoader />
        </div>
      );
    }
    if (invitationList.length === 0 && serverstatus) {
      return (
        <div
          style={{
            position: "absolute",
            left: "35%",
            top: "45%",
            opacity: ".3",
            fontWeight: "bold",
            fontSize: "17px",
          }}
        >
          {searchedInput ? "No results found" : "No Invitaions found"}
        </div>
      );
    }
    return (
      <React.Fragment>
        <Grid item xs={12} md={12}>
          <div style={{ marginTop: "-8px" }}>
            <List>
              {invitationList.map((obj: Anyobj, index: number) => (
                <ListItem button key={index}>
                  <ListItemText
                    primary={obj.details.email}
                    secondary={this.getDateFormat(obj.details.date)}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => this.confirmDeleteInvitation(index)}
                    >
                      <i className="zmdi zmdi-delete text-primary"></i>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}

export default InvitationsList;
