/**
 * Chat
 */
import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import { NotificationManager } from "react-notifications";
// components
import DomainDetails from "./components/DomainDetails";
import DoaminSidebar from "./components/DomainSidebar";
//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import axios from "axios";
import { apiURL } from "./../../constants/URL";

const drawerWidth = 410;

const styles: {
  [key: string]: any;
} = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: 408,
    [theme.breakpoints.up("md")]: {
      position: "relative",
      width: drawerWidth,
    },
    backgroundColor: "#fff",
  },
  content: {
    flexGrow: 1,
  },
});

type DomainsPageProps = {
  classes: any;
  theme: any;
};

class DomainsPage extends Component<DomainsPageProps> {
  state = {
    mobileOpen: false,
    domainsList: [],
    initialDomainList: [],
    serverstatus: false,
    open: false,
    selectedDomain: {},
    domainsAdminList: [],
    searchedinput: "",
    deleteDomain: {},
  };

  componentDidMount() {
    this.getDomainsList();
  }

  getDomainsList = () => {
    let domainRef = firebase.database().ref("domains/");
    domainRef.on(
      "value",
      (domainSnap) => {
        console.log(
          "Number of domains are -->",
          //domainSnap.val(),
          Object.keys(domainSnap.val()).length
        );
        let domains = { ...domainSnap.val() };
        if (domains !== null) {
          let domainsList: any = [];
          for (let key in domains) {
            let tempUsersObj = { ...domains[key].users };
            let resultObj = {};
            let totalAdmin: string[] = [];
            let totalEmployee: string[] = [];
            for (let userkey in tempUsersObj) {
              if (tempUsersObj[userkey].role === "Admin") {
                totalAdmin.push(userkey);
              } else if (tempUsersObj[userkey].role === "Employee") {
                totalEmployee.push(userkey);
              }
            }
            resultObj["domain"] = key;
            resultObj["isActive"] = domains[key].isActive
              ? domains[key].isActive
              : false;
            resultObj["url"] = domains[key].companyURL;
            resultObj["totalAdmin"] = [...totalAdmin];
            resultObj["totalEmployee"] = [...totalEmployee];
            domainsList.push(resultObj);
          }
          this.setState({
            domainsList,
            initialDomainList: domainsList,
            serverstatus: true,
            open: false,
            selectedDomain: {},
            deleteDomain: {},
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  getAdminProfile = (selectedDomain) => {
    let domainsAdminList: any = [];
    let usersRef = firebase.database().ref("Users/");
    for (let i = 0; i < selectedDomain.totalAdmin.length; i++) {
      usersRef.child(`${selectedDomain.totalAdmin[0]}/profile`).once(
        "value",
        (profileSnap) => {
          domainsAdminList.push(profileSnap.val());
          if (domainsAdminList.length === selectedDomain.totalAdmin.length) {
            this.setState({ domainsAdminList });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  handleSearch = (e) => {
    let domainRef = firebase.database().ref("domains/");
    const { domainsList, initialDomainList } = this.state;
    let updatedList = [...initialDomainList];
    // domainRef.startAt(e.currentTarget.value).on("child_added", (snapshot) => {
    //   console.log(snapshot.val());
    // });
    updatedList = updatedList.filter((item: any) => {
      let item1 = item.domain.split("_")[0];
      return (
        item1.toLowerCase().search(e.currentTarget.value.toLowerCase()) !== -1
      );
    });
    this.setState({
      searchedinput: e.currentTarget.value,
      domainsList: updatedList,
    });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleDomainItemClick = (selectedDomain) => {
    this.setState({ selectedDomain, domainsAdminList: [] });
    this.getAdminProfile(selectedDomain);
  };

  handleDeleteIconClick = (deleteDomain) => {
    this.setState({ deleteDomain, open: true });
  };

  onClose = () => {
    this.setState({ open: false });
  };

  // handleSwitchChange = (data) => {
  //   console.log("function argument -->", data);
  //   let tempDomainsList: any = [...this.state.domainsList];
  //   let bool = data.isActive ? false : true;
  //   let index: number = tempDomainsList.findIndex(
  //     (obj: any) => obj.domain === data.domain
  //   );
  //   tempDomainsList[index]["isActive"] = !data["isActive"];
  //   this.setState({ domainsList: tempDomainsList });
  //   for (let i = 0; i < data.totalAdmin.length; i++) {
  //     axios
  //       .put(apiURL + `/${data.totalAdmin[i]}/disableadminn`, {
  //         isActive: bool,
  //         domain: data.domain,
  //       })
  //       .then((response) => {
  //         if (response.data.code) {
  //           console.log(
  //             "error while changing domain isActive field",
  //             response.data.message
  //           );
  //           tempDomainsList[index]["isActive"] = data["isActive"];
  //           this.setState({ domainsList: tempDomainsList });
  //         } else {
  //           console.log(
  //             "Admin has successfully change the user authentication"
  //           );
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("error is --->", error);
  //         console.log(
  //           "index is -->",
  //           index,
  //           " array is --> ",
  //           tempDomainsList[index],
  //           " my state -->",
  //           this.state.domainsList[index]
  //         );
  //         tempDomainsList[index]["isActive"] = !this.state.domainsList[index][
  //           "isActive"
  //         ];
  //         this.setState({ domainsList: tempDomainsList });
  //         console.log("after update --->", this.state.domainsList[index]);
  //       });
  //   }
  // };

  handleSwitchChange = (data) => {
    let bool = data.isActive ? false : true;
    for (let i = 0; i < data.totalAdmin.length; i++) {
      // axios
      //   .put(apiURL + `/${data.totalAdmin[i]}/disableadmin`, {
      //     isActive: bool,
      //   })
      //   .then((response) => {
      //     console.log("Admin has successfully change the user authentication");
      let domainRef = firebase
        .database()
        .ref(`domains/${data.domain}/isActive`);
      domainRef.set(bool).then((response) => {
        console.log("successfully changed the domain.", bool)
        if (bool) {
          NotificationManager.success("successfully allowed the domain.");
        } else {
          NotificationManager.success("successfully disabled the domain.");
        }

      }).catch((error) => {
        console.log("error", error);
        NotificationManager.error("error while changing the domain status.");

      });
      // })
      // .catch((error) => {
      //   console.log("error is --->", error);
      // });
    }
  };

  render() {
    const { classes, theme } = this.props;
    const {
      domainsList,
      serverstatus,
      open,
      selectedDomain,
      domainsAdminList,
      searchedinput,
      deleteDomain,
    } = this.state;
    const drawer = (
      <DoaminSidebar
        domainsList={domainsList}
        serverstatus={serverstatus}
        open={open}
        searchedinput={searchedinput}
        selectedDomain={selectedDomain}
        deleteDomain={deleteDomain}
        onDomainItemClick={this.handleDomainItemClick}
        onDeleteIconClick={this.handleDeleteIconClick}
        onClose={this.onClose}
        onSearch={this.handleSearch}
        onSwitchChange={this.handleSwitchChange}
      />
    );
    return (
      <div className="chat-wrapper">
        <Helmet>
          <title>Domains</title>
          <meta name="description" content="Reactify Chat App" />
        </Helmet>
        <div className={classes.root}>
          <Hidden mdUp className="user-list-wrap">
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css" className="user-list-wrap">
            <Drawer
              variant="permanent"
              open
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <div className={`chat-content ${classes.content}`}>
            <DomainDetails
              selectedDomain={selectedDomain}
              domainsAdminList={domainsAdminList}
              onMenuIconPress={this.handleDrawerToggle}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DomainsPage);
