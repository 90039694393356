/**
 * Address Page
 */
import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import {
  FormGroup,
  Form,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Collapse } from "reactstrap";
import classnames from "classnames";
import firebase from "firebase";

import CircularProgress from "@material-ui/core/CircularProgress";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import axios from "axios";

// edit address from
import EditAddressForm from "./EditAddressForm";

// intl messages
import IntlMessages from "Util/IntlMessages";

import DeleteConfirmationDialog from "Components/DeleteConfirmationDialog/DeleteConfirmationDialog";

// user addresses
// import userAddresses from "../data/userAddresses";

const key =
  "pk.eyJ1IjoibXJpbmFsOTEiLCJhIjoiY2tjcDl3ZXVmMHI4YTJ5bzJqZnUweTg2MyJ9.57_E5T8QVnvbBzR-soJ-IQ";

export default class Address extends Component {
  _isMounted = false;
  state = {
    default: false,
    collapse: false,
    loading: false,
    addresses: [],
    addNewAddressDetail: {
      id: null,
      name: "",
      description: "",
    },
    deleteAddress: null,
    editAddressModal: false,
    selectedAddress: null,
  };

  componentDidMount() {
    this._isMounted = true;
    console.log("address tab");
    this.getAddress();
  }

  getAddress = () => {
    console.log("get address function started.");
    const { formdata } = this.props;
    let addresses = [];
    let allAddress = formdata.locations;
    console.log("locations", allAddress);
    let i = 0;
    for (let key in allAddress) {
      console.log("all the addresses", key);
      let data = {
        id: i,
        name: key,
        description: allAddress[key]["address"],
        latitude: allAddress[key]["latitude"],
        longitude: allAddress[key]["longitude"],
        updatedAt: allAddress[key]["updatedAt"],
      };
      addresses.push(data);
      i = i + 1;
    }

   let  addNewAddressDetail = {
      id: null,
      name: "",
      description: "",
    },
    this.setState({ addresses, addNewAddressDetail });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  handleChange = (name) => (event, checked) => {
    this.setState({ [name]: checked });
  };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse }, () => this.getAddress());
  };

  getLocation = (description) => {
    console.log("description is", description);
    return axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${description}.json?access_token=${key}`
      )
      .then((result) => {
        console.log("result is ", result);
        return result.data.features[0].center;
      })
      .catch((error) => {
        console.log("error is", error);
        return error;
      });
  };

  /**
   * Add New Address Hanlder
   */
  addNewAddress = async () => {
    const { name, description } = this.state.addNewAddressDetail;
    let addresses = this.state.addresses;
    let Data = {};
    let userid = sessionStorage.getItem("user_id");
    if (name !== "" && description !== "") {
      let Location = await this.getLocation(description);
      console.log("Location is", Location);
      this.setState({ loading: true });
      Data[name] = {
        address: description,
        updatedAt: moment().format("DD-MMMM-YYYY h:mm:ss A"),
        latitude: Location[1],
        longitude: Location[0],
      };
      for (let i = 0; i < addresses.length; i++) {
        Data[addresses[i]["name"]] = {
          address: addresses[i]["description"],
          latitude: addresses[i]["latitude"],
          longitude: addresses[i]["longitude"],
          updatedAt: addresses[i]["updatedAt"],
        };
      }

      console.log("the data is", Data);
      let LocationRef = firebase.database().ref("Users/").child(`${userid}`);
      LocationRef.update({ locations: Data })
        .then((result) => {
          console.log("address successfully Added", result);
          this.setState({ loading: false }, () => {
            this.toggle();
          });
        })
        .catch((error) => {
          console.log("error while updating address is", error);
          this.setState({ loading: false }, () => {
            this.toggle();
          });
        });
    }
  };

  /**
   * Hanlde Change Default Address
   */
  handleChangeDefaultAddress() {
    this.setState({
      selectedAddress: {
        ...this.state.selectedAddress,
        isDefault: !this.state.selectedAddress.isDefault,
      },
    });
  }

  /**
   * On Delete Address
   */
  onDeleteAddress(address) {
    this.refs.deleteConfirmationDialog.open();
    this.setState({ deleteAddress: address });
  }

  /**
   * Delete Address
   */
  deleteAddress() {
    let userid = sessionStorage.getItem("user_id");
    let addresses = this.state.addresses;
    let indexOfDeleteAddress = addresses.indexOf(this.state.deleteAddress);
    addresses.splice(indexOfDeleteAddress, 1);
    console.log("index of the address", indexOfDeleteAddress);
    console.log("adress remaining", addresses)
    this.refs.deleteConfirmationDialog.close();
    this.setState({ loading: true });
    let Data = {}
    for (let i = 0; i < addresses.length; i++) { 
        Data[addresses[i]["name"]] = {
          address: addresses[i]["description"],
          latitude: addresses[i]["latitude"],
          longitude: addresses[i]["longitude"],
          updatedAt: addresses[i]["updatedAt"],
        };
    }
    let LocationRef = firebase.database().ref("Users/").child(`${userid}`);
    LocationRef.update({ locations: Data })
      .then((result) => {
        console.log("address successfully deleted", result);
      this.setState({ addresses, loading: false });
      NotificationManager.success("Address Deleted!");
      })
      .catch((error) => {
        console.log("error while updating address is", error);
        this.setState({ loading: false });
        NotificationManager.error("Error while deleting address Deleted!");
      });

    
  }

  /**
   * Edit Address
   */
  onEditAddress(address) {
    console.log("edit address button clicked");
    this.setState({ editAddressModal: true, selectedAddress: address });
  }

  /**
   * Toggle Edit Address Modal
   */
  toggleEditAddressModal() {
    this.setState({ editAddressModal: false });
  }

  /**
   * On Update Edit Address
   */
  onUpdateEditAddressModal(key, value) {
    this.setState({
      selectedAddress: {
        ...this.state.selectedAddress,
        [key]: value,
      },
    });
  }

  /**
   * On Update Address
   */
  updateEditAddressModal() {
    let addresses = this.state.addresses;
    const { selectedAddress } = this.state;
    let Data = {};
    console.log("current address", selectedAddress);
    console.log("all addresses", addresses);
    for (let i = 0; i < addresses.length; i++) {
      const address = addresses[i];
      if (address.id === selectedAddress.id) {
        addresses[i] = selectedAddress;
        Data[addresses[i]["name"]] = {
          address: addresses[i]["description"],
          latitude: addresses[i]["latitude"],
          longitude: addresses[i]["longitude"],
          updatedAt: moment().format("DD-MMMM-YYYY h:mm:ss A"),
        };
      } else {
        Data[addresses[i]["name"]] = {
          address: addresses[i]["description"],
          latitude: addresses[i]["latitude"],
          longitude: addresses[i]["longitude"],
          updatedAt: addresses[i]["updatedAt"],
        };
      }
    }
    let userid = sessionStorage.getItem("user_id");
    let LocationRef = firebase.database().ref("Users/").child(`${userid}`);
    LocationRef.update({ locations: Data })
      .then((result) => {
        console.log("address successfully updated", result);
        this.toggleEditAddressModal();
      })
      .catch((error) => {
        console.log("error while updating address is", error);
        this.toggleEditAddressModal();
      });
  }

  render() {
    const {
      addresses,
      addNewAddressDetail,
      loading,
      editAddressModal,
      selectedAddress,
    } = this.state;
    const { formdata } = this.props;
    return (
      <div className="address-wrapper">
        <h2 className="heading">
          Address
          {/* <IntlMessages id="widgets.selectADefaultAddress" /> */}
        </h2>
        <div className="row row-eq-height">
          {addresses.map((address, key) => (
            <div className="col-sm-6 col-md-4 col-lg-3" key={key}>
              <div
                className={classnames("card-base", {
                  //   "border-primary": address.isDefault,
                })}
              >
                <div className="d-flex justify-content-between">
                  <h5 className="fw-bold text-truncate">{address.name}</h5>
                  <div className="list-action">
                    {/* <a href="javascript:void(0)" onClick={() => this.onEditAddress(address)}><i className="ti-pencil"></i></a>
										<a href="javascript:void(0)" onClick={() => this.onDeleteAddress(address)}><i className="ti-close"></i></a> */}
                    <button
                      type="button"
                      className="rct-link-btn"
                      onClick={() => this.onEditAddress(address)}
                    >
                      <i className="ti-pencil"></i>
                    </button>
                    <button
                      type="button"
                      className="rct-link-btn"
                      onClick={() => this.onDeleteAddress(address)}
                    >
                      <i className="ti-close"></i>
                    </button>
                  </div>
                </div>
                <address>
                  <span>{address.description}</span>
                  {/* <span>
                    {address.apt !== "" ? `${address.apt}, ` : ""}
                    {address.addressLine2 !== ""
                      ? `${address.addressLine2}, `
                      : ""}{" "}
                    {address.city}
                  </span>
                  <span>
                    {address.state !== "" ? `${address.state}, ` : ""}
                    {address.country}
                  </span> */}
                </address>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={address.isDefault}
                      // onChange={() => this.handleChangeDefaultAddress(address)}
                      disabled
                      color="primary"
                    />
                  }
                  label="Default"
                /> */}
              </div>
            </div>
          ))}
        </div>
        <Button
          variant="contained"
          color="primary"
          className="text-white"
          onClick={this.toggle}
        >
          Add New Address
        </Button>
        <div className="py-50 w-50">
          <Collapse isOpen={this.state.collapse}>
            <div className="mb-20">
              <h2 className="heading mb-5">Add a new address</h2>
            </div>
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label" for="name">
                      name
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      className="input-lg"
                      value={addNewAddressDetail.name}
                      onChange={(e) =>
                        this.setState({
                          addNewAddressDetail: {
                            ...addNewAddressDetail,
                            name: e.target.value,
                          },
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label" for="description">
                      description
                    </Label>
                    <Input
                      type="text"
                      name="description"
                      id="description"
                      className="input-lg"
                      value={addNewAddressDetail.description}
                      onChange={(e) =>
                        this.setState({
                          addNewAddressDetail: {
                            ...addNewAddressDetail,
                            description: e.target.value,
                          },
                        })
                      }
                    />
                  </FormGroup>
                </div>
              </div>
              <Button
                variant="contained"
                color="primary"
                className="text-white"
                onClick={() => this.addNewAddress()}
              >
                Save
              </Button>
            </Form>
          </Collapse>
        </div>
        <DeleteConfirmationDialog
          title="Are You Sure Want To Delete?"
          message="This will delete your address permanently."
          onConfirm={() => this.deleteAddress()}
          ref="deleteConfirmationDialog"
        />
        <Modal
          isOpen={editAddressModal}
          toggle={() => this.toggleEditAddressModal()}
        >
          <ModalHeader toggle={() => this.toggleEditAddressModal()}>
            Edit Address
          </ModalHeader>
          <ModalBody>
            <EditAddressForm
              selectedAddress={selectedAddress}
              onUpdate={this.onUpdateEditAddressModal.bind(this)}
              handleChangeDefaultAddress={() =>
                this.handleChangeDefaultAddress()
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button
              variant="contained"
              className="text-white btn-success"
              onClick={() => this.updateEditAddressModal()}
            >
              Update
            </Button>{" "}
            <Button
              variant="contained"
              className="text-white btn-danger"
              onClick={() => this.toggleEditAddressModal()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {loading && (
          <div className="d-flex justify-content-center loader-overlay">
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}
