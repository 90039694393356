/**
 * Chat Area Component
 */
import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
const { withRouter } = require("react-router-dom");
import MenuIcon from "@material-ui/icons/Menu";
import RctPageLoader from "../../../components/RctPageLoader/RctPageLoader";

// actions
import { sendMessageToUser } from "Actions";

// app layouts
import { getAppLayout } from "Helpers/helpers";

type AnyObj = {
  [key: string]: any;
};

type DomainDetailsProps = {
  location: any;
  fullHeight: any;
  onMenuIconPress: any;
  selectedDomain: AnyObj;
  domainsAdminList: AnyObj[];
};

class DomainDetails extends Component<DomainDetailsProps> {
  state = {};

  getScrollHeight() {
    const { location } = this.props;
    const appLayout = getAppLayout(location);
    if (this.props.fullHeight) {
      return "calc(100vh - 226px)";
    } else {
      switch (appLayout) {
        case "app":
          return "calc(100vh - 198px)";
        case "agency":
          return "calc(100vh - 387px)";
        case "boxed":
          return "calc(100vh - 387px)";
        case "horizontal":
          return "calc(100vh - 250px)";
        default:
          break;
      }
    }
  }

  render() {
    const { selectedDomain, domainsAdminList } = this.props;
    return (
      <div className="chat-main-body">
        <div className="chat-head">
          <div className="d-flex justify-content-between align-items-center">
            <div className="media align-items-center">
              <IconButton
                className="mr-3 chat-sidebar-toggler"
                color="inherit"
                aria-label="open drawer"
                onClick={this.props.onMenuIconPress}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <div></div>
          </div>
        </div>
        <br />
        <div>
          {Object.keys(selectedDomain).length > 0 &&
          domainsAdminList.length === 0 ? (
            <RctPageLoader />
          ) : (
            ""
          )}
        </div>
        <div className="text-center">
          <h1 style={{ textTransform: "capitalize" }}>
            {Object.keys(selectedDomain).length > 0 &&
            domainsAdminList.length !== 0
              ? selectedDomain.domain.split("_")[0]
              : ""}
          </h1>
          {domainsAdminList.map((admin, index) => (
            <li key={index}>
              {admin.fullname}, {admin.phoneNumber}
            </li>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, null)(DomainDetails));
