/**
 * Chat Area Component
 */
import React, { Component } from "react";
import { FormGroup, Input } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
const { withRouter } = require("react-router-dom");
import MenuIcon from "@material-ui/icons/Menu";

// actions
import { sendMessageToUser } from "Actions";

// app layouts
import { getAppLayout } from "Helpers/helpers";

type AnyObj = {
  [key: string]: any;
};

type UsersDetailsProps = {
  location: any;
  fullHeight: any;
  onMenuIconPress: any;
  selecteduser: AnyObj;
};

class UsersDetails extends Component<UsersDetailsProps> {
  state = {};

  getScrollHeight() {
    const { location } = this.props;
    const appLayout = getAppLayout(location);
    if (this.props.fullHeight) {
      return "calc(100vh - 226px)";
    } else {
      switch (appLayout) {
        case "app":
          return "calc(100vh - 198px)";
        case "agency":
          return "calc(100vh - 387px)";
        case "boxed":
          return "calc(100vh - 387px)";
        case "horizontal":
          return "calc(100vh - 250px)";
        default:
          break;
      }
    }
  }

  render() {
    const { selecteduser } = this.props;
    return (
      <div className="chat-main-body">
        <div className="chat-head">
          <div className="d-flex justify-content-between align-items-center">
            <div className="media align-items-center">
              <IconButton
                className="mr-3 chat-sidebar-toggler"
                color="inherit"
                aria-label="open drawer"
                onClick={this.props.onMenuIconPress}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <br />
        {/* <div>
          {Object.keys(selecteduser).length > 0 &&
          domainsAdminList.length === 0 ? (
            <RctPageLoader />
          ) : (
            ""
          )}
        </div> */}
        <div className="text-center">
          {Object.keys(selecteduser).length > 0 && (
            <h1 style={{ textTransform: "capitalize" }}>
              {selecteduser.details &&
              selecteduser.details.profile &&
              selecteduser.details.profile.fullname
                ? selecteduser.details.profile.fullname
                : "NA"}
            </h1>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, null)(UsersDetails));
