import React, { Component } from "react";
// import * as React from 'react';

import SweetAlert from "react-bootstrap-sweetalert";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import { apiURL } from "../../../constants/URL";

//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

type AnyObj = {
  [key: string]: any;
};

type Deleteprops = {
  open: boolean;
  deleteDomain: AnyObj;
  onClose(): void;
};

class DeleteDialogueBox extends Component<Deleteprops> {
  confirmDelete = (deleteDomain) => {
    console.log("user deletion is in process...");
    this.setState({ deleteloader: true });
    let userid = sessionStorage.getItem("user_id");
    // New way
    console.log("Testing domain deletion ...");
    let domainRef = firebase
      .database()
      .ref("/domains")
      .child(deleteDomain.domain);
    domainRef
      .remove()
      .then(() => {
        NotificationManager.success("Domain deleted successfully!");
        console.log("Deleted ...");
      })
      .catch((error) => {
        //NotificationManager.error(error.message);
        console.log("Could not delete the domain...", error);
      });
  };

  render() {
    const { open, deleteDomain } = this.props;
    return (
      <React.Fragment>
        <SweetAlert
          warning
          btnSize="sm"
          show={open}
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title={"Are you sure?"}
          onConfirm={() => this.confirmDelete(deleteDomain)}
          onCancel={this.props.onClose}
        >
          {deleteDomain.domain} will be permanently deleted.
        </SweetAlert>
      </React.Fragment>
    );
  }
}

export default DeleteDialogueBox;
