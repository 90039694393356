/**
 * App Header
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import screenfull from "screenfull";
import Tooltip from "@material-ui/core/Tooltip";
import MenuIcon from "@material-ui/icons/Menu";
import { withRouter } from "react-router-dom";
import "../../assets/custom-styles/allstyles.css";

// actions
import { collapsedSidebarAction } from "Actions";

// helpers
import { getAppLayout } from "Helpers/helpers";

// components
import Notifications from "./Notifications";
import FamilyUserBlock from "./FamilyUserBlock";
import ChatSidebar from "./ChatSidebar";
import DashboardOverlay from "../DashboardOverlay/DashboardOverlay";
import LanguageProvider from "./LanguageProvider";
import SearchForm from "./SearchForm";
import QuickLinks from "./QuickLinks";
import MobileSearchForm from "./MobileSearchForm";
import Cart from "./Cart";

// intl messages
import IntlMessages from "Util/IntlMessages";

class Header extends Component {
  state = {
    customizer: false,
    isMobileSearchFormVisible: false,
  };

  // function to change the state of collapsed sidebar
  onToggleNavCollapsed = (event) => {
    const val = !this.props.navCollapsed;
    this.props.collapsedSidebarAction(val);
  };

  // open dashboard overlay
  openDashboardOverlay(e) {
    var el = document.getElementsByClassName("dashboard-overlay")[0];
    el.classList.toggle("d-none");
    el.classList.toggle("show");
    if (el.classList.contains("show")) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    e.preventDefault();
  }

  // close dashboard overlay
  closeDashboardOverlay() {
    var e = document.getElementsByClassName("dashboard-overlay")[0];
    e.classList.remove("show");
    e.classList.add("d-none");
    document.body.style.overflow = "";
  }

  // toggle screen full
  toggleScreenFull() {
    screenfull.toggle();
  }

  // mobile search form
  openMobileSearchForm() {
    this.setState({ isMobileSearchFormVisible: true });
  }

  render() {
    const { isMobileSearchFormVisible } = this.state;
    const { horizontalMenu, agencyMenu, location } = this.props;

    return (
      <AppBar
        position="static"
        className="rct-header"
        style={{ backgroundColor: "#4a9df7" }}
      >
        <Toolbar className="d-flex justify-content-between w-100 pl-0">
          <div className="d-inline-flex align-items-center">
            <ul className="list-inline mb-0 navbar-left mr-15">
              <li
                className="list-inline-item"
                onClick={(e) => this.onToggleNavCollapsed(e)}
              >
                <Tooltip title="Sidebar Toggle" placement="bottom">
                  <IconButton
                    color="inherit"
                    mini="true"
                    aria-label="Menu"
                    className="humburger p-0"
                  >
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
              </li>
            </ul>
            {/* <div className="d-flex align-items-center p-1">
							<Link to="/" className="logo-mini">
								<img src={require('Assets/img/white-logo.png')} alt="site logo" width="40" height="40" />
							</Link>
							<p className="m-0 text-center" style={{fontSize: 24, color: '#fff'}}>Family Tracker</p>
						</div>
						 */}
            {/*!agencyMenu &&
							<ul className="list-inline mb-0 navbar-left">
								{!horizontalMenu ?
									<li className="list-inline-item" onClick={(e) => this.onToggleNavCollapsed(e)}>
										<Tooltip title="Sidebar Toggle" placement="bottom">
											<IconButton color="inherit" mini="true" aria-label="Menu" className="humburger p-0">
												<MenuIcon />
											</IconButton>
										</Tooltip>
									</li> :
									<li className="list-inline-item">
										<Tooltip title="Sidebar Toggle" placement="bottom">
											<IconButton color="inherit" aria-label="Menu" className="humburger p-0" component={Link} to="/">
												<i className="ti-layout-sidebar-left"></i>
											</IconButton>
										</Tooltip>
									</li>
								}
								
							</ul>
						*/}
          </div>
          <ul className="navbar-right list-inline mb-0">
            {/*<li className="list-inline-item summary-icon">
							<Tooltip title="Summary" placement="bottom">
								<a href="#" className="header-icon tour-step-3" onClick={(e) => this.openDashboardOverlay(e)}>
									<i className="zmdi zmdi-info-outline"></i>
								</a>
							</Tooltip>
						</li>*/}
            {/*!horizontalMenu &&
							<li className="list-inline-item">
								<Tooltip title="Upgrade" placement="bottom">
									<Button component={Link} to={`/${getAppLayout(location)}/pages/pricing`} variant="contained" className="upgrade-btn tour-step-4 text-white" color="primary">
										<IntlMessages id="widgets.upgrade" />
									</Button>
								</Tooltip>
							</li>
						*/}
            {/*<LanguageProvider />*/}
            {/*<Notifications />
						<Cart />
						<li className="list-inline-item setting-icon">
							<Tooltip title="Chat" placement="bottom">
								<IconButton aria-label="settings" onClick={() => this.setState({ customizer: true })}>
									<i className="zmdi zmdi-comment"></i>
								</IconButton>
							</Tooltip>
						</li>
						<li className="list-inline-item">
							<Tooltip title="Full Screen" placement="bottom">
								<IconButton aria-label="settings" onClick={() => this.toggleScreenFull()}>
									<i className="zmdi zmdi-crop-free"></i>
								</IconButton>
							</Tooltip>
						</li>*/}
            <li className="list-inline-item">
              <FamilyUserBlock />
            </li>
          </ul>
          <Drawer
            anchor={"right"}
            open={this.state.customizer}
            onClose={() => this.setState({ customizer: false })}
          >
            <ChatSidebar />
          </Drawer>
        </Toolbar>
        <DashboardOverlay onClose={() => this.closeDashboardOverlay()} />
      </AppBar>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings }) => {
  return settings;
};

export default withRouter(
  connect(mapStateToProps, {
    collapsedSidebarAction,
  })(Header)
);
