import {
    GET_FAMILY_MEMBERS_HISTORY,
    GET_SUCCESS_FAMILY_MEMBERS_HISTORY,
    SHOW_FAMILY_MEMBERS_LOADING_INDICATOR_HISTORY,
    HIDE_FAMILY_MEMBERS_LOADING_INDICATOR_HISTORY
 } from 'Actions/types';


 const INITIAL_STATE = {
    memberHistoryData: null,
    loading: false,
 }
 
 export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
 
     case GET_FAMILY_MEMBERS_HISTORY:
         return {...state, loading: true}
     // get family members
     case GET_SUCCESS_FAMILY_MEMBERS_HISTORY:
         return {...state, memberHistoryData: action.payload, loading: false}
     //show loading
     case SHOW_FAMILY_MEMBERS_LOADING_INDICATOR_HISTORY:
         return {...state, loading: true}
      // hide loading   
     case HIDE_FAMILY_MEMBERS_LOADING_INDICATOR_HISTORY:
         return {...state, loading: false}
     // default case	
     default:
         return { ...state }
    }
 }