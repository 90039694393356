/**
 * Firebase Login
 * Reactify comes with built in firebase login feature
 * You Need To Add Your Firsebase App Account Details Here
 */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

/*Personal Account*/
/*
const firebaseConfig = {
  apiKey: "AIzaSyCKGy8iW1-pWxqvuyyAA550Y6ewCWxKkvQ", // Your Api key will be here
  authDomain: "fir-demoapps-c05fb.firebaseapp.com", // Your auth domain
  databaseURL: "https://fir-demoapps-c05fb.firebaseio.com", // data base url
  projectId: "fir-demoapps-c05fb", // project id
  storageBucket: "gs://fir-demoapps-c05fb.appspot.com", // storage bucket
  messagingSenderId: "514678213475", // messaging sender id
  appId: "1:514678213475:web:b42315dbc62b0de51ee156",
};
*/

/*Family Tracker Account*/
const firebaseConfig = {
  apiKey: "AIzaSyAa-_V1kK51Z71zA6CLpRc5bXOOQMI5fww", // Your Api key will be here
  authDomain: "familytracker-5fe23.firebaseapp.com", // Your auth domain
  databaseURL: "https://familytracker-5fe23.firebaseio.com", // data base url
  projectId: "familytracker-5fe23", // project id
  storageBucket: "familytracker-5fe23.appspot.com", // storage bucket
  messagingSenderId: "451097703101", // messaging sender id
  appId: "1:451097703101:web:3a7b583d6e517027a77dd4"
};

// var firebaseConfig = {
//   apiKey: "AIzaSyBjh2GcqHUj0DkRyHHUSj-3ATDJ9MxeZYk",
//   authDomain: "family-tracker-5bbb6.firebaseapp.com",
//   databaseURL: "https://family-tracker-5bbb6.firebaseio.com",
//   projectId: "family-tracker-5bbb6",
//   storageBucket: "family-tracker-5bbb6.appspot.com",
//   messagingSenderId: "194199918499",
//   appId: "1:194199918499:web:3c957b0678e74fefffac16"
// };

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

const database = firebase.database();

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  database,
};
