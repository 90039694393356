import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Input } from "reactstrap";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import RctPageLoader from "../../../components/RctPageLoader/RctPageLoader";
import Switch from "@material-ui/core/Switch";
import { Scrollbars } from "react-custom-scrollbars";
const { withRouter } = require("react-router-dom");
import { connect } from "react-redux";
import axios from "axios";
import { apiURL } from "../../../constants/URL";
import SweetAlert from "react-bootstrap-sweetalert";
const { getAppLayout } = require("Helpers/helpers");
import "../../../assets/custom-styles/allstyles.css";

//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { NotificationManager } from "react-notifications";

declare namespace JSX {
  interface IntrinsicElements {
    InvitationList: { [key: string]: any };
  }
}

type InvitationListProps = {
  searchedInput: any;
  location: any;
};
type InvitationListState = {
  serverstatus: boolean;
  invitationList: { [key: string]: any }[] | [];
  initialInvitationList: { [key: string]: any }[] | [];
  status: string;
  search: string;
  open: boolean;
  selectedindex: number;
};

class InvitationList extends Component<
  InvitationListProps,
  InvitationListState
> {
  _isMounted = false;
  state = {
    serverstatus: false,
    invitationList: [],
    initialInvitationList: [],
    status: "",
    search: "",
    open: false,
    selectedindex: -1,
  };

  componentDidMount() {
    this._isMounted = true;
    console.log("Here are the Invitations...");
    this.getInvitationList();
  }

  getInvitationList = () => {
    console.log("invitations are called from firebase.");
    const userId = sessionStorage.getItem("user_id");
    // const userId = localStorage.getItem("user_id");
    this.setState({
      invitationList: [],
      initialInvitationList: [],
    });
    console.log(userId);

    let domainRef = firebase.database().ref("Users/" + userId + "/tracking");
    domainRef.on(
      "value",
      (usersSnap) => {
        let result = { ...usersSnap.val() }; //We will get contact number of the invitee here.
        let empArr = [];
        console.log("user snap is -----> ", usersSnap);
        console.log("user snap value is ----->", usersSnap.val());
        if (usersSnap.val() !== null) {
          this.getInvitedMembers(result);
          // console.log("result is --", result);
        } else {
          this.setState({
            serverstatus: true,
            initialInvitationList: [],
            invitationList: [],
            status: "",
            open: false,
          });
        }
      },
      (error) => {
        console.log("Error while fetching users from tracking -- ", error.code);
        this.setState({
          serverstatus: true,
          open: false,
        });
      }
    );
  };

  getInvitedMembers = (result) => {
    let tempInvitationList: any = [];
    let userId: any = sessionStorage.getItem("user_id");
    // let userId: any = localStorage.getItem("user_id");
    let profileCounter = 0;
    for (let uid in result) {
      console.log("key is", uid);
      let permissionRef = firebase.database().ref("Permissions/" + uid);
      let tempObj = {};
      tempObj["uid"] = uid;
      console.log("Contact number of the invitee is--->", result[uid]);
      tempObj["phone"] = result[uid];
      tempObj["value"] = false;
      permissionRef.on(
        "value",
        (permissionsnap) => {
          console.log("permission snap is", permissionsnap.val());
          let permissiondata = { ...permissionsnap.val() };
          tempObj["value"] = permissiondata[userId];
          // console.log(permissiondata[userId]);
          // console.log("number is --->", profiledata.phoneNumber);
          // console.log("profile data is ------>", profiledata);
          // var
          let index = tempInvitationList
            .map(function (e: any) {
              return e.uid;
            })
            .indexOf(uid);
          if (index < 0) {
            tempInvitationList.push(tempObj);
            profileCounter += 1;
          } else tempInvitationList[index] = tempObj;
          if (Object.keys(result).length === profileCounter) {
            console.log("Members list is --", tempInvitationList);
            if (this._isMounted) {
              this.setState({
                serverstatus: true,
                initialInvitationList: tempInvitationList,
                invitationList: tempInvitationList,
                status: "",
                open: false,
              });
              console.log("state at the callback is ----> ", this.state);
            }
          }
        },
        (error) => {
          console.log("Error while fetching permissions -- ", error.code);
          // var
          let index = tempInvitationList
            .map(function (e: any) {
              return e.uid;
            })
            .indexOf(uid);
          if (index < 0) {
            tempInvitationList.push(tempObj);
            profileCounter += 1;
          } else tempInvitationList[index] = tempObj;
          // profileCounter += 1;
          console.log(
            "profile counter in error",
            profileCounter,
            "object length",
            Object.keys(result).length
          );
          if (Object.keys(result).length === profileCounter) {
            if (this._isMounted) {
              this.setState({
                serverstatus: true,
                initialInvitationList: tempInvitationList,
                invitationList: tempInvitationList,
                status: "",
              });
            }
          }
        }
      );
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  confirmDeleteInvitation = (data, key) => {
    let userID: any = sessionStorage.getItem("user_id");
    // let userID = localStorage.getItem("user_id");
    console.log("Delete button is clicked");
    console.log("key is ", key);
    console.log("UserId of the invitee is", data.uid, data.value);
    axios
      .delete(apiURL + `/${userID}/${data.uid}/delete`)
      .then((response) => {
        if (response.data.code) {
          console.log("error while deleting --->", response);
          NotificationManager.error(response.data.message);
        } else {
          console.log("Invited member is deleted");
          NotificationManager.success("Invited User is deleted successfully.");
          this.getInvitationList();
        }
      })
      .catch((error) => {
        console.log("error is --->", error);
        NotificationManager.error("try again");
      });
  };
  handlesearch = (e) => {
    // console.log("1111")
    const { invitationList, initialInvitationList } = this.state;

    let updateList = initialInvitationList;
    updateList = updateList.filter((item: any) => {
      return item.phone.search(e.target.value) !== -1;
    });
    this.setState({
      invitationList: updateList,
      search: e.target.value,
    });
  };

  getScrollHeight() {
    const { location } = this.props;
    const appLayout = getAppLayout(location);
    switch (appLayout) {
      case "app":
        return "calc(100vh - 188px)";
      case "agency":
        return "calc(100vh - 372px)";
      case "boxed":
        return "calc(100vh - 372px)";
      case "horizontal":
        return "calc(100vh - 238px)";
      default:
        break;
    }
  }
  onDeleteIconClick = (index) => {
    this.setState({ open: true, selectedindex: index });
  };

  onClose = () => {
    this.setState({ open: false, selectedindex: -1 });
  };
  render() {
    const { searchedInput } = this.props;
    const { serverstatus, invitationList, search, open, selectedindex }: any =
      this.state;
    console.log("invitationList:", this.state);
    let adminID = sessionStorage.getItem("user_id");
    return (
      <React.Fragment>
        <div className="search-wrapper mb-0 position-relative">
          <Input
            type="text"
            name="search"
            id="search"
            className="search-input rounded-0 py-2 px-3"
            placeholder="Search"
            onChange={this.handlesearch}
            value={search}
          />

          <i className="zmdi zmdi-search search-icon"></i>
        </div>
        <div>
          <Scrollbars
            className="rct-scroll"
            autoHide
            style={{ height: this.getScrollHeight() }}
          >
            {invitationList.length !== 0 && serverstatus ? (
              <List className="list-unstyled p-0">
                {invitationList.map((data: any, index) => (
                  <ListItem button key={index}>
                    <Avatar className="bg-primary mr-10">
                      <i className="zmdi zmdi-account-circle zmdi-hc-lg"></i>
                    </Avatar>
                    <ListItemText
                      className="mr-30"
                      primary={
                        <div>
                          <div className="font-weight-bold">{data.phone}</div>
                          {data.value === undefined ? (
                            <div>Pending</div>
                          ) : (
                            <div>
                              {data.value ? (
                                <div>Accepted</div>
                              ) : (
                                <div>Denied</div>
                              )}
                            </div>
                          )}

                          {/* <div>{`${data.value}`}</div> */}
                        </div>
                      }
                    ></ListItemText>
                    <ListItemSecondaryAction className="ml-10">
                      <IconButton
                        aria-label="Delete"
                        onClick={() => this.onDeleteIconClick(index)}
                      >
                        <i className="zmdi zmdi-delete text-primary"></i>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
              ""
            )}
            {invitationList.length === 0 && serverstatus ? (
              <div className="searchstatus">
                {search ? "No results found" : "No Members Added"}
              </div>
            ) : (
              ""
            )}
            {invitationList.length === 0 && !serverstatus ? (
              <div className="loaderposition">
                <RctPageLoader />
              </div>
            ) : (
              ""
            )}
          </Scrollbars>
        </div>
        {open ? (
          <SweetAlert
            warning
            btnSize="sm"
            show={open}
            showCancel
            confirmBtnText="Delete"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="success"
            title={"Are you sure?"}
            onConfirm={() =>
              this.confirmDeleteInvitation(
                invitationList[selectedindex],
                selectedindex
              )
            }
            onCancel={() => this.onClose()}
          >
            {invitationList[selectedindex].phone} will be deleted from the
            invited members.
          </SweetAlert>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

// export default InvitationList;

const mapStateToProps = ({ chatAppReducer }) => {
  return chatAppReducer;
};

export default withRouter(connect(mapStateToProps)(InvitationList));
