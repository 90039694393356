/**
 * Signin Firebase
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
const { Link } = require("react-router-dom");
import { Form, FormGroup, Input } from "reactstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import QueueAnim from "rc-queue-anim";
import { NotificationManager } from "react-notifications";
import { Fab } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// import ReCAPTCHA from 'react-google-recaptcha'

// components
const { SessionSlider } = require("Components/Widgets");

// app config
const AppConfig = require("Constants/AppConfig");

import firebase from "firebase/app";
import "firebase/auth";

// redux action
const {
  signinUserInFirebase,
  signinUserByPhoneInFirebase,
  signinUserWithFacebook,
  signinUserWithGoogle,
  signinUserWithGithub,
  signinUserWithTwitter,
} = require("Actions");

interface SigninState {
  errors: { [key: string]: string };
  formdata: { [key: string]: string };
  status?: string;
}

type SigninProps = {
  signinUserByPhoneInFirebase(arg1: { [key: string]: string }, arg2: any): void;
  signinUserInFirebase(arg1: { [key: string]: string }, arg2: any): void;
  loading: string;
  count: any;
  history: any;
};

//Auth File
import Auth from "../Auth/Auth";

const auth = new Auth();

class Signin extends React.Component<SigninProps, SigninState> {
  state = {
    // formdata: { email: "devesh@pragmaapps.com", password: "1234567" },
    formdata: { email: "", password: "", phone: "91" },
    errors: { email: "", password: "" },
    status: "login",
  };

  //validating input on onChange
  validateInput = (e) => {
    switch (e.currentTarget.name) {
      case "email":
        if (!e.currentTarget.value.trim()) return "*Email is required";
        else if (
          !/^([A-Za-z0-9.]+)@([A-Za-z0-9]+)\.([a-z]{3})$/.test(
            e.currentTarget.value
          )
        )
          return "*not a valid email";
        break;
      case "password":
        if (!e.currentTarget.value.trim()) return "*Password is required";
        else if (e.currentTarget.value.trim().length < 5)
          return "*Password should have at least 5 characters";
        break;
      default:
        break;
    }
    return "";
  };

  onphonelogin = () => {
    const { formdata } = this.state;
    const { phone } = formdata;
    if (phone !== "91") {
      this.props.signinUserByPhoneInFirebase(formdata, this.props.history);
    }
  };

  handlephoneinput = (e) => {
    console.log(e);
    this.setState({
      formdata: {
        ...this.state.formdata,
        phone: e,
      },
    });
  };

  signinbyphone() {
    this.setState({
      status: "by phone",
    });
  }

  onForgotPassword() {
    this.setState({
      status: "forgot password",
    });
  }

  onSignInpage() {
    this.setState({
      status: "login",
    });
  }

  // Reset Password
  onResetPassword() {
    var auth = firebase.auth();
    var emailAddress = this.state.formdata.email;

    auth
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        NotificationManager.success(
          "Reset Link sent Please check your email..."
        );
        // Email sent.
      })
      .catch(function (error) {
        // An error happened.
        NotificationManager.error("Email does not exist, please sign up..");
      });
  }

  //handling input fields
  handleChange = (e) => {
    let errString = this.validateInput(e);
    const data = { ...this.state.formdata };
    let errors = { ...this.state.errors };
    errors[e.currentTarget.name] = errString;
    data[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ formdata: data, errors });
  };

  /**
   * On User Login
   */
  onUserLogin() {
    const { formdata, errors } = this.state;
    const { email, password } = formdata;
    if (email !== "" && password !== "") {
      this.props.signinUserInFirebase(formdata, this.props.history);
    }
  }

  /**
   * On User Sign Up
   */
  onUserSignUp() {
    this.props.history.push("/signup");
  }

  //Auth0 Login
  loginAuth0() {
    auth.login();
  }

  render() {
    const { formdata, errors, status } = this.state;
    const { email, password, phone } = formdata;
    const { loading } = this.props;
    return (
      <QueueAnim type="bottom" duration={2000}>
        <div
          style={{
            backgroundColor: "#6b79ff",
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
          className=""
        >
          {loading && <LinearProgress />}
          <AppBar position="static" className="session-header">
            <Toolbar>
              <div className="container mt-2">
                <div className="d-flex justify-content-between">
                  <div className="session-logo">
                    <Link to="/">
                      <img
                        //src={AppConfig.appLogo}
                        src="https://i.ibb.co/8gsVdKL/white-logo.png"
                        alt="session-logo"
                        //width="110"
                        //height="35"
                        width={40}
                        height={40}
                      />
                      &nbsp; &nbsp;
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: 500,
                          color: "white",
                          fontFamily: `"Comic Sans MS", cursive, sans-serif`,
                        }}
                      >
                        {AppConfig.default.brandName}
                      </span>
                    </Link>
                  </div>
                  <div>
                    <a
                      className="d-none d-md-inline-block mr-15"
                      onClick={() => this.onUserSignUp()}
                    >
                      Create New account?
                    </a>
                    <Button
                      variant="contained"
                      className="btn-light"
                      onClick={() => this.onUserSignUp()}
                    >
                      Sign Up
                    </Button>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <br />
          <br />
          <br />
          <br />
          <br />
          {status === "login" ? (
            <div className="session-inner-wrapper">
              <div className="container">
                <div className="row row-eq-height">
                  <div className="d-none d-sm-inline-block col-sm-1 col-md-2"></div>
                  <div
                    style={{
                      backgroundColor: "white",
                      boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.3)",
                      borderRadius: "4px",
                    }}
                    className="col-12 col-sm-10 col-md-8"
                  >
                    <div className="session-body text-center">
                      <br />
                      <div className="session-head mb-30">
                        <h2 className="font-weight-bold">
                          Get started with {AppConfig.default.brandName}
                          {/* Get started with Family Tracker */}
                        </h2>
                      </div>

                      <Form>
                        <FormGroup className="has-wrapper">
                          <Input
                            type="mail"
                            value={email}
                            name="email"
                            id="user-mail"
                            className="has-input input-lg border"
                            placeholder="Enter Email Address"
                            onChange={this.handleChange}
                          />
                          <span className="has-icon">
                            <i className="ti-email"></i>
                          </span>
                          {errors.email ? (
                            <div
                              style={{ fontSize: "13px" }}
                              className="text-left text-danger ml-2"
                            >
                              {errors.email}
                            </div>
                          ) : (
                            ""
                          )}
                        </FormGroup>
                        <FormGroup className="has-wrapper">
                          <Input
                            value={password}
                            type="Password"
                            name="password"
                            id="pwd"
                            className="has-input input-lg border"
                            placeholder="Password"
                            onChange={this.handleChange}
                          />
                          <span className="has-icon">
                            <i className="ti-lock"></i>
                          </span>
                          {errors.password ? (
                            <div
                              style={{ fontSize: "13px" }}
                              className="text-left text-danger ml-2"
                            >
                              {errors.password}
                            </div>
                          ) : (
                            ""
                          )}
                        </FormGroup>
                        <FormGroup className="mb-15">
                          <Button
                            color="primary"
                            className="btn-block text-white w-100"
                            variant="contained"
                            size="large"
                            onClick={() => this.onUserLogin()}
                          >
                            Sign In
                          </Button>
                        </FormGroup>
                      </Form>
                      {/*
                        <FormGroup className="mb-15">
                          <Button
                            variant="contained"
                            className="btn-info btn-block text-white w-100"
                            size="large"
                            onClick={() => this.loginAuth0()}
                          >
                            Sign In With Auth0
                          </Button>
                        </FormGroup>
                      <p className="mb-20">or sign in with</p>
                      <Fab
                        size="small"
                        variant="round"
                        className="btn-facebook mr-15 mb-20 text-white"
                        onClick={() =>
                          this.props.signinUserWithFacebook(this.props.history)
                        }
                      >
                        <i className="zmdi zmdi-facebook"></i>
                      </Fab>
                      <Fab
                        size="small"
                        variant="round"
                        className="btn-google mr-15 mb-20 text-white"
                        onClick={() =>
                          this.props.signinUserWithGoogle(this.props.history)
                        }
                      >
                        <i className="zmdi zmdi-google"></i>
                      </Fab>
                      <Fab
                        size="small"
                        variant="round"
                        className="btn-twitter mr-15 mb-20 text-white"
                        onClick={() =>
                          this.props.signinUserWithTwitter(this.props.history)
                        }
                      >
                        <i className="zmdi zmdi-twitter"></i>
                      </Fab>
                      <Fab
                        size="small"
                        variant="round"
                        className="btn-instagram mr-15 mb-20 text-white"
                        onClick={() =>
                          this.props.signinUserWithGithub(this.props.history)
                        }
                      >
                        <i className="zmdi zmdi-github-alt"></i>
                      </Fab>
                      <p className="text-muted">
                        By signing up you agree to {AppConfig.brandName}
                      </p>
                      */}
                      <p className="mb-0">
                        <a
                          //target="_blank"
                          //href="#/terms-condition"
                          onClick={() => this.signinbyphone()}
                          className="text-muted text-primary"
                        >
                          sign in by Phone Number
                        </a>
                      </p>
                      <br />
                      <p className="mb-0">
                        <a
                          //target="_blank"
                          //href="#/terms-condition"
                          onClick={() => this.onForgotPassword()}
                          className="text-muted text-primary"
                        >
                          Forgot Password ?
                        </a>
                      </p>
                      <br />
                    </div>
                  </div>
                  <div className="d-none d-sm-inline-block col-sm-1 col-md-2"></div>
                  {/*<div className="col-sm-5 col-md-5 col-lg-4">
                    <SessionSlider />
                    </div>*/}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {status === "by phone" ? (
            <div className="session-inner-wrapper">
              <div className="container">
                <div className="row row-eq-height">
                  <div className="d-none d-sm-inline-block col-sm-1 col-md-2"></div>
                  <div
                    style={{
                      backgroundColor: "white",
                      boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.3)",
                      borderRadius: "4px",
                    }}
                    className="col-12 col-sm-10 col-md-8"
                  >
                    <div className="session-body text-center">
                      <br />
                      <div className="session-head mb-30">
                        <h2 className="font-weight-bold">
                          Enter your Phone Number
                          {/* Get started with Family Tracker */}
                        </h2>
                      </div>

                      <Form>
                        <FormGroup className="has-wrapper">
                          <PhoneInput
                            isValid={(value, country: any) => {
                              if (value.match(/12345/)) {
                                return (
                                  "Invalid value: " +
                                  value +
                                  ", " +
                                  country.name
                                );
                              } else if (value.match(/1234/)) {
                                return false;
                              } else if (
                                value.length < 12 &&
                                value.length > 3
                              ) {
                                return "Enter a valid number";
                              } else {
                                return true;
                              }
                            }}
                            placeholder="Enter Phone Number"
                            inputStyle={{
                              // color: "blue",
                              width: "100%",
                              height: "55px",
                              border: "1px solid #eee",
                            }}
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: true,
                            }}
                            // type="text"
                            value={phone}
                            // name="phone"
                            // id="user-name"
                            // className="has-input input-lg border"
                            // placeholder="Enter Your Phone Number"
                            onChange={this.handlephoneinput}
                          />
                          <span className="has-icon">
                            <i className="ti-mobile"></i>
                          </span>
                          <div id="recaptcha-container"></div>
                        </FormGroup>
                        <FormGroup className="mb-15">
                          <Button
                            color="primary"
                            className="btn-block text-white w-100"
                            variant="contained"
                            size="large"
                            onClick={() => this.onphonelogin()}
                          >
                            Next
                          </Button>
                        </FormGroup>
                      </Form>
                      <p className="mb-0">
                        <a
                          //target="_blank"
                          //href="#/terms-condition"
                          onClick={() => this.onSignInpage()}
                          className="text-muted text-primary"
                        >
                          Sign In by E-mail
                        </a>
                      </p>
                      <br />
                    </div>
                  </div>
                  <div className="d-none d-sm-inline-block col-sm-1 col-md-2"></div>
                  {/*<div className="col-sm-5 col-md-5 col-lg-4">
                   <SessionSlider />
                   </div>*/}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {status === "forgot password" ? (
            <div className="session-inner-wrapper">
              <div className="container">
                <div className="row row-eq-height">
                  <div className="d-none d-sm-inline-block col-sm-1 col-md-2"></div>
                  <div
                    style={{
                      backgroundColor: "white",
                      boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.3)",
                      borderRadius: "4px",
                    }}
                    className="col-12 col-sm-10 col-md-8"
                  >
                    <div className="session-body text-center">
                      <br />
                      <div className="session-head mb-30">
                        <h2 className="font-weight-bold">
                          Enter your Email
                          {/* Get started with Family Tracker */}
                        </h2>
                      </div>

                      <Form>
                        <FormGroup className="has-wrapper">
                          <Input
                            type="mail"
                            value={email}
                            name="email"
                            id="user-mail"
                            className="has-input input-lg border"
                            placeholder=" Email Address"
                            onChange={this.handleChange}
                          />
                          <span className="has-icon">
                            <i className="ti-email"></i>
                          </span>
                          {errors.email ? (
                            <div
                              style={{ fontSize: "13px" }}
                              className="text-left text-danger ml-2"
                            >
                              {errors.email}
                            </div>
                          ) : (
                            ""
                          )}
                        </FormGroup>
                        <FormGroup className="mb-15">
                          <Button
                            color="primary"
                            className="btn-block text-white w-100"
                            variant="contained"
                            size="large"
                            onClick={() => this.onResetPassword()}
                          >
                            Next
                          </Button>
                        </FormGroup>
                      </Form>
                      <p className="mb-0">
                        <a
                          //target="_blank"
                          //href="#/terms-condition"
                          onClick={() => this.onSignInpage()}
                          className="text-muted text-primary"
                        >
                          Sign In
                        </a>
                      </p>
                      <br />
                    </div>
                  </div>
                  <div className="d-none d-sm-inline-block col-sm-1 col-md-2"></div>
                  {/*<div className="col-sm-5 col-md-5 col-lg-4">
                   <SessionSlider />
                   </div>*/}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </QueueAnim>
    );
  }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(mapStateToProps, {
  signinUserInFirebase,
  signinUserByPhoneInFirebase,
  signinUserWithFacebook,
  signinUserWithGoogle,
  signinUserWithGithub,
  signinUserWithTwitter,
})(Signin);
