/**
 * Recent Chat Users
 */
import React, { Component } from "react";
import List from "@material-ui/core/List";
import { connect } from "react-redux";
import moment from "moment";

// rct section loader
const RctSectionLoader = require("Components/RctSectionLoader/RctSectionLoader");

// components
// const ActivityListItem = require("./ActivityListItem");
import ActivityListItem from "./ActivityListItem";

// actions
const {
  chatWithSelectedUser,
  getRecentChatUsers,
  getFamilyMembers,
  getMemberHistory,
} = require("Actions");

declare namespace JSX {
  interface IntrinsicElements {
    RecentActivity: { [key: string]: any };
  }
}

type RecentActivityProps = {
  chatWithSelectedUser(any): void;
  getFamilyMembers(): void;
  recentChatUsers: any;
  selectedUser?: any;
  members?: any;
  loading: any;
  memberHistoryData: any;
};

class RecentActivity extends Component<RecentActivityProps> {
  UNSAFE_componentWillMount() {
    this.fetchRecentChatUsers();
  }

  /**
   * Fetch Recent User
   */
  fetchRecentChatUsers() {
    // this.props.getRecentChatUsers();
    this.props.getFamilyMembers();
  }

  componentDidMount() {
    // this.props.getMemberHistory(this.props.userId)
  }

  /**
   * Swicth Chat With User
   * @param {*object} user
   */
  switchChatWithUser(user, key) {
    console.log("key is ------> ", key);
    this.props.chatWithSelectedUser(user);
  }

  getcurrentTime(dateString) {
    let time = dateString
      ? moment(dateString).format("ddd hh:mm a")
      : moment().format("ddd hh:mm a");
    let daysAgo = dateString
      ? moment(dateString).fromNow()
      : moment().fromNow();

    return time;
  }

  battery_image(level) {
    let imageUrl = "";
    if (level >= 0 && level < 10) {
      imageUrl = "ic_battery_alert.png";
    } else if (level >= 10 && level < 30) {
      imageUrl = "ic_battery_10.png";
    } else if (level >= 30 && level < 50) {
      imageUrl = "ic_battery_30.png";
    } else if (level >= 50 && level < 60) {
      imageUrl = "ic_battery_50.png";
    } else if (level >= 60 && level < 90) {
      imageUrl = "ic_battery_60.png";
    } else if (level >= 90 && level < 100) {
      imageUrl = "ic_battery_90.png";
    } else if (level == 100) {
      imageUrl = "ic_battery_full.png";
    } else {
      imageUrl = "ic_battery_unknown.png";
    }
    return imageUrl;
  }

  checkEventPosition(key) {
    return key % 2;
  }

  render() {
    const {
      recentChatUsers,
      selectedUser,
      members,
      loading,
      memberHistoryData,
    } = this.props;
    if (loading) {
      return <RctSectionLoader />;
    } else if (memberHistoryData === null || memberHistoryData.length === 0) {
      return (
        <div
          className="p-3 text-center"
          style={{
            border: "1px solid #eee",
            borderBottom: "2px solid #c5c1c1",
          }}
        >
          No data to display
        </div>
      );
    }
    return (
      <List className="p-0 mb-0 mr-0">
        {memberHistoryData &&
          memberHistoryData.map((user, key) => (
            <ActivityListItem
              selectedUser={selectedUser}
              key={key}
              isEven={this.checkEventPosition(key)}
              user={user}
              getBatteryImage={this.battery_image(user.battery)}
              getcurrentTime={this.getcurrentTime}
              onClickListItem={() => this.switchChatWithUser(user, key)}
            />
          ))}
      </List>
    );
  }
}

const mapStateToProps = ({ chatAppReducer, memberHistory }) => {
  const { recentChatUsers, selectedUser } = chatAppReducer;
  const { memberHistoryData, loading } = memberHistory;
  const count = memberHistoryData && memberHistoryData.length;
  return { recentChatUsers, selectedUser, loading, memberHistoryData, count };
};

export default connect(mapStateToProps, {
  chatWithSelectedUser,
  getRecentChatUsers,
  getFamilyMembers,
  getMemberHistory,
})(RecentActivity);
