/**
 * Email Prefrences Page
 */
import React, { Component } from 'react';
import Switch from 'react-toggle-switch';
import Button from '@material-ui/core/Button';
import { FormGroup, Input } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';


import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

// intl messages
import IntlMessages from 'Util/IntlMessages';
import { type } from '@amcharts/amcharts4/core';

type Emailprefrenceprops = {
   // loading: boolean,
   emailVerified: boolean,
   // showbtn: boolean
}

export default class EmailPrefrences extends Component<Emailprefrenceprops> {
   _isMounted = false;
   state = {
      'Features & Announcements': true,
      'Tips & Recommendations': true,
      'Inbox': false,
      'Community Activity': false,
      'Research': true,
      'Newsletter': false,
      loading: false,
      // emailverified: false,
      showbtn: true
   }

   // toggle switch
   toggleSwitch = (key) => {
      this.setState({ [key]: !this.state[key] });
   }

   componentDidMount() {
      this._isMounted = true;
   }

   // checkEmailVerification = () => {
   //    firebase.auth().onAuthStateChanged((user: any) => {
   //       if (user.emailVerified) {
   //          console.log('Email is verified');
   //          this.setState({ emailverified: true });
   //       }
   //       else {
   //          console.log('Email is not verified');
   //          this.setState({ emailverified: false });
   //       }
   //    });
   // }


   componentWillUnmount() {
      this._isMounted = false;
   }

   // on save changes
   onSendemail() {
      this.setState({ loading: true });
      let user: any = firebase.auth().currentUser;
      firebase.auth().currentUser?.sendEmailVerification().then(() => {
         NotificationManager.success('Email sent Successfully!');
         this.setState({ loading: false, showbtn: false });
      }).catch((error) => {
         NotificationManager.error('Email not sent, try again later');
         console.log("error while sending verification email -->", error);

         this.setState({ loading: false });
      })


   }

   render() {
      const { showbtn } = this.state
      const { emailVerified } = this.props
      console.log("emailVerified : ", emailVerified);
      return (

         <div className="prefrences-wrapper">
            {emailVerified ?

               <div className="row">
                  <div className="col-sm-12 col-md-8">
                     <div className="search-filter p-6 mt-50 mb-50">
                        <form>
                           <h2 className="heading">Your email is verified</h2>

                        </form>
                     </div>
                  </div>
               </div>
               :
               <div className="row">
                  <div className="col-sm-12 col-md-8">
                     <div className="search-filter p-6 mt-50 mb-50">
                        <form>
                           <h2 className="heading">Your email is not verified</h2>

                        </form>
                     </div>
                     {/* <FormGroup className="mb-0 w-40">
                        <Input type="search" className="input-lg" name="search" placeholder="info@example.com" />
                     </FormGroup>
                     <Button variant="contained" color="primary" className="text-white btn-lg">
                        <IntlMessages id="button.save" />
                     </Button> */}
                     {/* <ul className="list-unstyled">
                     <li className="d-flex justify-content-between">
                        <div className="">
                           <h5>Features & Announcements</h5>
                           <p>New products and feature updates, as well as occasional company announcements.</p>
                        </div>
                        <Switch
                           onClick={() => this.toggleSwitch('Features & Announcements')}
                           on={this.state['Features & Announcements']}
                        />
                     </li>
                     <li className="d-flex justify-content-between">
                        <div className="">
                           <h5>Tips & Recommendations</h5>
                           <p>Timely advice to help you make the most of our features.</p>
                        </div>
                        <Switch
                           onClick={() => this.toggleSwitch('Tips & Recommendations')}
                           on={this.state['Tips & Recommendations']}
                        />
                     </li>
                     <li className="d-flex justify-content-between">
                        <div className="">
                           <h5>Inbox</h5>
                           <p>Answers to your questions, comments, chat notifications and more.</p>
                        </div>
                        <Switch
                           onClick={() => this.toggleSwitch('Inbox')}
                           on={this.state['Inbox']}
                        />
                     </li>
                     <li className="d-flex justify-content-between">
                        <div className="">
                           <h5>Community Activity</h5>
                           <p>Notifications about upcoming events & community activity.</p>
                        </div>
                        <Switch
                           onClick={() => this.toggleSwitch('Community Activity')}
                           on={this.state['Community Activity']}
                        />
                     </li>
                     <li className="d-flex justify-content-between">
                        <div className="">
                           <h5>Research</h5>
                           <p>Invitations to participate in surveys, usability tests and more. Only a few per year.</p>
                        </div>
                        <Switch
                           onClick={() => this.toggleSwitch('Research')}
                           on={this.state['Research']}
                        />
                     </li>
                     <li className="d-flex justify-content-between">
                        <div className="">
                           <h5>Newsletter</h5>
                           <p>Our best community content of the week/month</p>
                        </div>
                        <Switch
                           onClick={() => this.toggleSwitch('Newsletter')}
                           on={this.state['Newsletter']}
                        />
                     </li>
                  </ul> */}
                     {showbtn ?
                        <div className="mb-0 w-40">
                           {this.state.loading ?
                              <CircularProgress />
                              : <Button variant="contained" color="primary" className="text-white btn-lg" onClick={() => this.onSendemail()}>Send Verification Email</Button>
                           }
                        </div> :
                        <div>
                           verification mail will be sent shortly to your mail!
                     </div>

                     }
                  </div>
               </div>
            }
         </div>
      );
   }
}
