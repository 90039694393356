/**
 * family members Actions
 */
import { array } from "@amcharts/amcharts4/core";
import firebase from "firebase/app";
import { addMember } from "../familyTrackerAPI";
const {
  GET_FAMILY_MEMBERS,
  GET_SUCCESS_FAMILY_MEMBERS,
  ADD_FAMILY_MEMBER,
  ADD_SUCCESS_FAMILY_MEMBER,
  ADD_FAIL_FAMILY_MEMBER,
  SET_SELECTED_FAMILY_MEMBER,
  SHOW_FAMILY_MEMBER_HISTORY,
  FILTER_MEMBERS_ON_SEVERITY,
  SHOW_FAMILY_MEMBERS_LOADING_INDICATOR,
  HIDE_FAMILY_MEMBERS_LOADING_INDICATOR,
} = require("Actions/types");

export const addfamilyMember = (data) => (dispatch) => {
  // let uid = localStorage.getItem("user_id");
  let uid = sessionStorage.getItem("user_id");
  let jsonData = {
    loggedInMemberuserName: uid,
    relatedMemberUsername: data.mobile,
    relationType: data.relationType,
  };
  dispatch({ type: ADD_FAMILY_MEMBER });
  addMember
    .post("member", jsonData, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => {
      console.log(response.data);
      dispatch({
        type: ADD_SUCCESS_FAMILY_MEMBER,
        payload:
          "Relation added successfully. Waiting for approval from the user.",
      });
    })
    .catch((e) => {
      console.log(e);
      dispatch({
        type: ADD_FAIL_FAMILY_MEMBER,
        payload: "Something went wrong!",
      });
    });
};
export const getFamilyMembers = () => (dispatch) => {
  //Newest Way
  dispatch({ type: GET_FAMILY_MEMBERS });
  let adminID = sessionStorage.getItem("user_id");
  // let adminID = localStorage.getItem("user_id");
  if (
    sessionStorage.getItem("admin_domain") &&
    sessionStorage.getItem("required-data-type") === "organisational"
  ) {
    console.log("Fetching data for oragnisational tracking....");
    let reqDomain = sessionStorage.getItem("admin_domain") || "NoDomain";
    let domainRef = firebase.database().ref("domains/" + reqDomain + "/users");
    domainRef.on(
      "value",
      (usersSnap) => {
        //Tracking List
        if (usersSnap === null) {
          console.log("No domain user found.");
        } else {
          var apprvUser: { [key: string]: any }[] = [];
          console.log("Got domain users list --" + usersSnap);
          usersSnap.forEach((user) => {
            console.log("Tracking user: " + user.key);
            if (user === null) {
              console.log("No  details found.");
            } else {
              console.log("Got user data --" + user);
              firebase
                .database()
                .ref(`Users/` + user.key)
                .on(
                  "value",
                  (userProfileSnap) => {
                    console.log("Inside user details...");
                    let userDetail = userProfileSnap.val();
                    userDetail["UID"] = userProfileSnap.key;
                    let index: number = apprvUser
                      .map(function (e: any) {
                        return e.UID;
                      })
                      .indexOf(user.key);
                    console.log(
                      "Admin domain is - ",
                      reqDomain,
                      " and the user detail is - ",
                      userDetail.allow_domain
                    );
                    if (userDetail.allow_domain) {
                      console.log(
                        "Permission status for domain is ->",
                        userDetail["allow_domain"][reqDomain]
                      );
                      if (userDetail["allow_domain"][reqDomain]) {
                        if (index < 0) apprvUser.push(userDetail);
                        else apprvUser[index] = userDetail;
                      } else {
                        if (index > -1) apprvUser.splice(index, 1);
                      }
                    } else {
                      if (index > -1) apprvUser.splice(index, 1);
                    }
                    // apprvUser.push(userDetail);
                    console.log("after the details are pushed--", apprvUser);
                    dispatch({
                      type: GET_SUCCESS_FAMILY_MEMBERS,
                      payload: apprvUser,
                    });
                  },
                  (error) => {
                    console.log(
                      "Error while fetching user details -- ",
                      error.code
                    );
                  }
                );
            }
          });
        }
      },
      (error) => {
        console.log("Error while fetching users list -- ", error.code);
      }
    );
  } else {
    console.log("Fetching data for personal tracking....");
    firebase
      .database()
      .ref(`Users/` + adminID + `/tracking/`)
      .on(
        "value",
        (invitedUsers) => {
          //Tracking List
          if (invitedUsers === null) {
            console.log("No tracking details found.");
          } else {
            console.log("Got tracking data --");
            console.log(invitedUsers.val());
            if (invitedUsers.val()) {
              let apprvUser: { [key: string]: any }[] = [];
              invitedUsers.forEach((trackSnap) => {
                console.log("Tracking user: " + trackSnap.key);
                if (trackSnap === null) {
                  console.log("No permission details found.");
                } else {
                  console.log("Got permission data --", trackSnap);
                  firebase
                    .database()
                    .ref("Permissions/" + trackSnap.key + "/" + adminID)
                    .on(
                      "value",
                      (permissionSnap) => {
                        console.log(
                          "Inside permission snap...",
                          permissionSnap.val()
                        );

                        if (permissionSnap.val()) {
                          firebase
                            .database()
                            .ref(`Users/` + trackSnap.key)
                            .on(
                              "value",
                              (usersSnap) => {
                                let index: number = apprvUser
                                  .map(function (e: any) {
                                    return e.UID;
                                  })
                                  .indexOf(usersSnap.key);
                                console.log(
                                  "Inside user details...",
                                  usersSnap.val()
                                );
                                let userDetail = usersSnap.val();
                                userDetail["UID"] = usersSnap.key;
                                if (index < 0) apprvUser.push(userDetail);
                                else apprvUser[index] = userDetail;
                                console.log("apprvUser...", apprvUser);
                                dispatch({
                                  type: GET_SUCCESS_FAMILY_MEMBERS,
                                  payload: apprvUser,
                                });
                              },
                              (error) => {
                                console.log(
                                  "Error while fetching user details -- ",
                                  error.code
                                );
                              }
                            );
                        }
                      },
                      (error) => {
                        console.log(
                          "Error while fetching permissions of the user -- 179",
                          error.code
                        );
                        console.log(
                          "tracknsap key is --->",
                          trackSnap.key,
                          "and users are",
                          apprvUser
                        );
                        let index: number = apprvUser
                          .map(function (e: any) {
                            return e.UID;
                          })
                          .indexOf(trackSnap.key);
                        console.log(
                          "index value in error block --->182",
                          index
                        );
                        if (index > -1) apprvUser.splice(index, 1);
                        dispatch({
                          type: GET_SUCCESS_FAMILY_MEMBERS,
                          payload: apprvUser,
                        });
                        console.log("User is removed from tracking...188");
                        console.log(
                          "list of user to be tracked outside permission snaps---189",
                          apprvUser
                        );
                      }
                    );
                }
              });
            } else {
              let apprvUser = [];
              dispatch({
                type: GET_SUCCESS_FAMILY_MEMBERS,
                payload: apprvUser,
              });
            }
          }
        },
        (error) => {
          console.log("Error while fetching tracking data -- ", error.code);
          let apprvUser = [];
          dispatch({
            type: GET_SUCCESS_FAMILY_MEMBERS,
            payload: apprvUser,
          });
        }
      );
  }
};

export const filterMembersOnSeverity = (severity) => (dispatch) => {
  dispatch({ type: FILTER_MEMBERS_ON_SEVERITY, payload: severity });
  dispatch({ type: SET_SELECTED_FAMILY_MEMBER, payload: null });
};

export const showMemberHistory = (isShow) => ({
  type: SHOW_FAMILY_MEMBER_HISTORY,
  payload: isShow,
});

export const setSelectedFamilyMember = (familyMember) => (dispatch) => {
  dispatch({ type: SET_SELECTED_FAMILY_MEMBER, payload: familyMember });
};

export const showLoadingOnMembers = () => ({
  type: SHOW_FAMILY_MEMBERS_LOADING_INDICATOR,
});

export const hideLoadingOnMembers = () => ({
  type: HIDE_FAMILY_MEMBERS_LOADING_INDICATOR,
});
