/**
 * User List Item
 */
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import classnames from "classnames";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";

// helpers
import { textTruncate } from "Helpers/helpers";

const UsersListItem = ({
  userItem,
  onUserItemClick,
  onDeleteIconClick,
  onSwitchChange,
}) => (
    <ListItem
      onClick={() => onUserItemClick(userItem)}
    // className={classnames("user-list-item", {
    //   "item-active": selectedUser && selectedUser.id === user.id,
    // })}
    >
      <img
        src={require("Assets/avatars/avatar-blank.jpg")}
        className="img-fluid rounded-circle"
        alt="user profile"
        width="40"
        height="40"
        style={{ cursor: "pointer" }}
      />
      <ListItemText
        className="mr-30 ml-5"
        primary={
          <div style={{ cursor: "pointer" }}>
            <div
              className="font-weight-bold"
              style={{ textTransform: "capitalize" }}
            >
              {userItem.details.profile && userItem.details.profile.fullname
                ? userItem.details.profile.fullname
                : "Not Registered"}
            </div>
            <div>
              <span style={{ fontSize: "13px" }}>
                {userItem.details.profile && userItem.details.profile.email
                  ? userItem.details.profile.email
                  : "NA"}
              </span>
            </div>
            <div style={{ fontSize: "11px" }}>
              {userItem.details.profile && userItem.details.profile.phoneNumber
                ? userItem.details.profile.phoneNumber
                : "NA"}
            &nbsp;{" "}
            </div>
            <div style={{ fontSize: "11px" }}>
              Allowed Domains -{" "}
              {userItem.details.allowedDomains
                ? userItem.details.allowedDomains.length
                : 0}
            </div>
          </div>
        }
      ></ListItemText>
      <ListItemSecondaryAction className="ml-10">
        <Switch
          checked={
            // userItem.details &&
            //   userItem.details.profile &&
            //   userItem.details.profile.isActive?
            userItem.details.profile.isActive
            // : false
          }
          onChange={() => {
            onSwitchChange(userItem);
          }}
        />
        <IconButton
          aria-label="Delete"
          onClick={() => onDeleteIconClick(userItem)}
          disabled={
            (userItem.details &&
              userItem.details.profile &&
              userItem.details.profile.domain) ||
              (userItem.details &&
                userItem.details.allowedDomains &&
                userItem.details.allowedDomains.length) > 0
              ? true
              : false
          }
        >
          <i
            className={
              (userItem.details &&
                userItem.details.profile &&
                userItem.details.profile.domain) ||
                (userItem.details &&
                  userItem.details.allowedDomains &&
                  userItem.details.allowedDomains.length) > 0
                ? "zmdi zmdi-delete text-secondary"
                : "zmdi zmdi-delete text-primary"
            }
          ></i>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );

export default UsersListItem;
