/**
 * Chat
 */
import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
// components
import ChatSidebar from "./components/ChatSidebar";
// 
// const MemberDetail = require("Components/FamilyDetail");
import MemberDetail from "Components/FamilyDetail";

const drawerWidth = 420;
declare namespace JSX {
  interface IntrinsicElements {
    Hidden: { [key: string]: any };
  }
}
type PropType = {
  classes: any;
  theme: any;
};
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: "90%",
    [theme.breakpoints.up("md")]: {
      position: "relative",
      width: drawerWidth,
    },
    backgroundColor: "#fff",
  },
  content: {
    flexGrow: 1,
  },
}as const);

class ChatList extends Component<PropType> {
  state = {
    mobileOpen: false,
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  render() {
    const { classes, theme } = this.props;
    const drawer = <ChatSidebar />;
    return (
      <div className="chat-wrapper">
        <Helmet>
          <title>Tracking</title>
          <meta name="description" content="Family Tracker" />
        </Helmet>
        <div className={classes.root}>
          {/* <Hidden mdUp className="user-list-wrap"> */}
          <Hidden mdUp>
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          {/* <Hidden smDown implementation="css" className="user-list-wrap"> */}
          <Hidden smDown implementation="css">
            <Drawer
              variant="permanent"
              open
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <div className={`chat-content ${classes.content}`}>
            <MemberDetail onMenuIconPress={this.handleDrawerToggle} />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ChatList);
