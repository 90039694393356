// Horizontal Linear Stapper
import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

function getSteps() {
   return ['Select campaign settings', 'Create an ad group', 'Create an ad'];
}

function getStepContent(step) {
   switch (step) {
      case 0:
         return 'Select campaign settings...';
      case 1:
         return 'What is an ad group anyways?';
      case 2:
         return 'This is the bit I really care about!';
      default:
         return 'Unknown step';
   }
}

export default class HorizontalLabelPositionBelowStepper extends React.Component {
   static propTypes = {
      classes: PropTypes.object,
   };

   state = {
      activeStep: 0,
      skipped: new Set(),
   };
   isStepOptional = step => {
      return step === 1;
   };
   isStepSkipped(step) {
      return this.state.skipped.has(step);
   }
   handleNext = () => {
      const { activeStep } = this.state;
      let { skipped } = this.state;
      if (this.isStepSkipped(activeStep)) {
         skipped = new Set(skipped.values());
         skipped.delete(activeStep);
      }
      this.setState({
         activeStep: activeStep + 1,
         skipped,
      });
   };
   handleBack = () => {
      const { activeStep } = this.state;
      this.setState({
         activeStep: activeStep - 1,
      });
   };
   handleSkip = () => {
      const { activeStep } = this.state;
      if (!this.isStepOptional(activeStep)) {
         // You probably want to guard against something like this,
         // it should never occur unless someone's actively trying to break something.
         throw new Error("You can't skip a step that isn't optional.");
      }
      const skipped = new Set(this.state.skipped.values());
      skipped.add(activeStep);
      this.setState({
         activeStep: this.state.activeStep + 1,
         skipped,
      });
   };
   handleReset = () => {
      this.setState({
         activeStep: 0,
      });
   };

   render() {
      const steps = getSteps();
      const { activeStep } = this.state;
      return (
         <div className="hoz-linear-stepper">
            <Stepper activeStep={activeStep}>
               {steps.map((label, index) => {
                  const props = {};
                  const labelProps = {};
                  if (this.isStepOptional(index)) {
                     labelProps.optional = <p type="caption">Optional</p>;
                  }
                  if (this.isStepSkipped(index)) {
                     props.completed = false;
                  }
                  return (
                     <Step key={label} {...props}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                     </Step>
                  );
               })}
            </Stepper>
            <div className="pl-40">
               {activeStep === steps.length ? (
                  <div>
                     <p className="">
                        All steps completed - you&quot;re finished
                </p>
                     <Button variant="contained" onClick={this.handleReset} className="btn-success text-white">
                        Reset
                </Button>
                  </div>
               ) : (
                     <div>
                        <p>{getStepContent(activeStep)}</p>
                        <div>
                           <Button disabled={activeStep === 0} onClick={this.handleBack} className="btn-danger text-white mr-10 mb-10" >
                              Back
                  </Button>
                           {this.isStepOptional(activeStep) && (
                              <Button variant="contained" onClick={this.handleSkip} className="btn-info text-white mr-10 mb-10" >
                                 Skip
                    </Button>
                           )}
                           <Button variant="contained" onClick={this.handleNext} color="primary" className="text-white mr-10 mb-10">
                              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                           </Button>
                        </div>
                     </div>
                  )}
            </div>
         </div>
      );
   }
}
