/**
 * Sidebar Content
 */
import React, { Component } from "react";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import IntlMessages from "Util/IntlMessages";

import NavMenuItem from "./NavMenuItem";
//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const key =
  "pk.eyJ1IjoibXJpbmFsOTEiLCJhIjoiY2tjcDl3ZXVmMHI4YTJ5bzJqZnUweTg2MyJ9.57_E5T8QVnvbBzR-soJ-IQ";

// redux actions
import { onToggleMenu } from "Actions";

class SidebarContent extends Component {
  toggleMenu(menu, stateCategory) {
    let data = {
      menu,
      stateCategory,
    };
    this.props.onToggleMenu(data);
  }

  componentDidMount() {
    this.run;
    this.savelocation();
  }

  run = setInterval(() => {
    console.log("run the function from sidebar page");
    this.savelocation();
  }, 1000 * 60 * 10);

  savelocation = () => {
    let time = moment().format("DD-MMMM-YYYY h:mm:ss A");
    console.log("saving the location at time", time);
    let data = {};
    let AuthID = sessionStorage.getItem("user_id");
    let treeDate = moment().format("YYYY_MM_DD");
    let uuid = uuidv4();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        data["updatedAt"] = time;
        data["latitude"] = position.coords.latitude;
        data["longitude"] = position.coords.longitude;

        console.log("the details are ", data);
        axios
          .get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${position.coords.longitude},${position.coords.latitude}.json?access_token=${key}`
          )
          .then((result) => {
            console.log("the data is", result);
            data["address"] = result.data.features[0].place_name;
            let locationRef = firebase
              .database()
              .ref("track/" + AuthID + "/" + treeDate);
            locationRef
              .push({ location: data })
              .then((result) => {
                console.log("location is saved successfully", result);
              })
              .catch((error) => {
                console.log("error while saving location", error.code);
                console.log(error);
              });
          })
          .catch((error) => {
            console.log("error while getting address", error);
          });
      });
    }
  };

  render() {
    const { sidebarMenus } = this.props.sidebar;
    const userId = sessionStorage.getItem("user_id");
    //console.log("List of the options is --", sidebarMenus)
    let filteredSidebarMenus = { ...sidebarMenus };
    if (sessionStorage.getItem("admin_domain") === null) {
      let tempArr = [...filteredSidebarMenus.category2];
      tempArr = tempArr.filter(function (obj) {
        if (obj.path === "/app/EmployeeList") return false;
        else if (obj.path === "/app/tracking-organisational") return false;
        else if (obj.path === "/app/domains") return false;
        else if (obj.path === "/app/users") return false;
        else return true;
      });
      filteredSidebarMenus.category2 = [...tempArr];
    } else if (
      sessionStorage.getItem("admin_domain") !== null &&
      sessionStorage.getItem("super_admin") !== "true"
      // userId !== "OjxVtAwbzCN2E5olg9qIIuXmnwU2"
    ) {
      let tempArr = [...filteredSidebarMenus.category2];
      tempArr = tempArr.filter(function (obj) {
        if (obj.path === "/app/domains") return false;
        else if (obj.path === "/app/users") return false;
        else return true;
      });
      filteredSidebarMenus.category2 = [...tempArr];
    }
    //console.log("List of the filtered options is --", filteredSidebarMenus)
    return (
      <div className="rct-sidebar-nav">
        <nav className="navigation">
          {/*<List
            className="rct-mainMenu p-0 m-0 list-unstyled"
            subheader={
              <ListSubheader className="side-title" component="li">
                <IntlMessages id="sidebar.general" />
              </ListSubheader>
            }
          >
            {sidebarMenus.category1.map((menu, key) => (
              <NavMenuItem
                menu={menu}
                key={key}
                onToggleMenu={() => this.toggleMenu(menu, "category1")}
              />
            ))}
			</List>*/}
          <List
            className="rct-mainMenu p-0 m-0 list-unstyled"
            // subheader={
            //   <ListSubheader className="side-title" component="li">
            //     <IntlMessages id="sidebar.modules" />
            //   </ListSubheader>
            // }
          >
            {filteredSidebarMenus.category2.map((menu, key) => (
              <NavMenuItem
                menu={menu}
                key={key}
                onToggleMenu={() => this.toggleMenu(menu, "category2")}
              />
            ))}
          </List>
          {/*<List
            className="rct-mainMenu p-0 m-0 list-unstyled"
            subheader={
              <ListSubheader className="side-title" component="li">
                <IntlMessages id="sidebar.component" />
              </ListSubheader>
            }
          >
            {sidebarMenus.category3.map((menu, key) => (
              <NavMenuItem
                menu={menu}
                key={key}
                onToggleMenu={() => this.toggleMenu(menu, "category3")}
              />
            ))}
			</List>*/}
          {/*<List
            className="rct-mainMenu p-0 m-0 list-unstyled"
            subheader={
              <ListSubheader className="side-title" component="li">
                <IntlMessages id="sidebar.features" />
              </ListSubheader>
            }
          >
            {sidebarMenus.category4.map((menu, key) => (
              <NavMenuItem
                menu={menu}
                key={key}
                onToggleMenu={() => this.toggleMenu(menu, "category4")}
              />
            ))}
			</List>*/}
          {/*<List
            className="rct-mainMenu p-0 m-0 list-unstyled"
            subheader={
              <ListSubheader className="side-title" component="li">
                <IntlMessages id="sidebar.applications" />
              </ListSubheader>
            }
          >
            {sidebarMenus.category5.map((menu, key) => (
              <NavMenuItem
                menu={menu}
                key={key}
                onToggleMenu={() => this.toggleMenu(menu, "category5")}
              />
            ))}
			</List>*/}
          {/*<List
						className="rct-mainMenu p-0 m-0 list-unstyled"
						subheader={<ListSubheader className="side-title" component="li"><IntlMessages id="sidebar.extensions" /></ListSubheader>}
					>
						{sidebarMenus.category6.map((menu, key) => (
							<NavMenuItem
								menu={menu}
								key={key}
								onToggleMenu={() => this.toggleMenu(menu, 'category6')}
							/>
						))}
						</List>*/}
        </nav>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ sidebar, settings }) => {
  return { sidebar, settings };
};

export default withRouter(
  connect(mapStateToProps, {
    onToggleMenu,
  })(SidebarContent)
);
