/**
 * User List Item
 */
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import NotificationsIcon from "@material-ui/icons/Notifications";
import VisibilityIcon from "@material-ui/icons/Visibility";

declare namespace JSX {
  interface IntrinsicElements {
    ActivityListItem: { [key: string]: any };
  }
}

const ActivityListItem = ({
  key,
  selectedUser,
  user,
  isEven,
  getcurrentTime,
  onClickListItem,
  getBatteryImage,
}) => (
  <ListItem
    onClick={onClickListItem}
    className="border"
    style={
      isEven === 0 ? { backgroundColor: "#eee" } : { backgroundColor: "#fff" }
    }
  >
    <div className="d-flex justify-content-between w-100 align-items-center">
      <div className="media align-items-center w-100">
        <div className="media-body pt-5">
          <div className="d-flex w-100">
            <div className="d-flex align-items-center w-50">
              <p className="mb-0 mr-2">
                {user.severity ? user.severity : "Normal"}
              </p>
              <img
                src={require(`Assets/battery/${getBatteryImage}`)}
                className="battry-icon"
              />
              <p className="mb-0 ml-2">{` ${user.battery}%`}</p>
            </div>
            <p className="mb-0 w-50 text-right">
              {getcurrentTime(user.location.updatedAt)}
            </p>
          </div>
          <div className="w-100 mt-2">
            <div className="d-flex">
              <h4 className="mb-0 mr-2">
                {user.status ? user.status : "No Status"}
              </h4>
              <span>
                <NotificationsIcon
                  style={{ fontSize: "medium", color: "grey" }}
                />
              </span>
            </div>
            <span className="font-xs d-block">{`${
              user.activity &&
              user.activity.activity.charAt(0).toUpperCase() +
                user.activity.activity.slice(1).toLowerCase()
            } at ${user.location.address}`}</span>
          </div>
        </div>
      </div>
    </div>
  </ListItem>
);

export default ActivityListItem;
