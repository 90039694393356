/**
 * Chat Area Component
 */
import React, { Component } from "react";
// import MessageBlock from './MessageBlock';
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
const { withRouter } = require("react-router-dom");
import MenuIcon from "@material-ui/icons/Menu";

// actions
import { sendMessageToUser } from "Actions";

// app layouts
import { getAppLayout } from "Helpers/helpers";

type ChatAreaProps = {
  selectedUser: any;
  onMenuIconPress(): void;
  sendMessageToUser: any;
  location: any;
  fullHeight: any;
  admin_photo_url: any;
  employeeDetail: any;
};

type ChatAreaState = {
  message: any;
  anchorEl: any;
  chatOptions: any;
};

class ChatArea extends Component<ChatAreaProps, ChatAreaState> {
  _isMounted = false;
  chatScroll: any;
  state = {
    message: "",
    anchorEl: null,
    chatOptions: ["Mute Notifications", "Block", "Clear Chat", "Send Contact"],
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  chatOptionsHandler = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  onSubmitMessage(event) {
    event.preventDefault();
    if (this.state.message !== "") {
      let data = {
        user: this.props.selectedUser,
        message: this.state.message,
        isAdmin: true,
        time: "Just Now",
      };
      this.props.sendMessageToUser(data);
      this.setState({ message: "" });
      setTimeout(() => {
        this.chatScroll.scrollToBottom();
      }, 200);
    }
  }

  getScrollHeight() {
    const { location } = this.props;
    const appLayout = getAppLayout(location);
    if (this.props.fullHeight) {
      return "calc(100vh - 226px)";
    } else {
      switch (appLayout) {
        case "app":
          return "calc(100vh - 198px)";
        case "agency":
          return "calc(100vh - 387px)";
        case "boxed":
          return "calc(100vh - 387px)";
        case "horizontal":
          return "calc(100vh - 250px)";
        default:
          break;
      }
    }
  }

  render() {
    const { selectedUser, admin_photo_url, employeeDetail } = this.props;
    console.log("the employee details are", employeeDetail);

    return (
      <React.Fragment>
        <div className="chat-box-main">
          <div className="chat-head">
            <div className="d-flex justify-content-between align-items-center">
              <div className="media align-items-center">
                <IconButton
                  className="mt-3 ml-3"
                  onClick={this.props.onMenuIconPress}
                >
                  <div style={{ fontSize: "26px" }}>{"<"}</div>
                  <MenuIcon />
                </IconButton>
                {/* <IconButton
                  className="bg-white mr-10 mt-20 ml-20 mb-20 text-black video-icon d-md-none"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.props.onMenuIconPress}
                >
                  Back
                  <MenuIcon />
                </IconButton> */}
              </div>
            </div>
          </div>
          <div className="p-4">
            <div className="row" style={{ fontSize: "22px" }}>
              <div className="col-4">
                <b>Name:</b>
              </div>
              <div className="col-8">{employeeDetail.details.name}</div>
            </div>
            <div className="row" style={{ fontSize: "22px" }}>
              <div className="col-4">
                <b>Email Address:</b>
              </div>
              <div className="col-8">{employeeDetail.details.email}</div>
            </div>
            <div className="row" style={{ fontSize: "22px" }}>
              <div className="col-4">
                <b>Role:</b>
              </div>
              <div className="col-8">{employeeDetail.details.role}</div>
            </div>
            <div className="row" style={{ fontSize: "22px" }}>
              <div className="col-4">
                <b>Phone Number:</b>
              </div>
              <div className="col-8">{employeeDetail.details.phoneNumber}</div>
            </div>
            {employeeDetail.details.role !== "Admin" ? (
              <div className="row" style={{ fontSize: "22px" }}>
                <div className="col-4">
                  <b>Tracking Permissions:</b>
                </div>
                <div className="col-8">
                  {employeeDetail.details.allowed ? "Allowed" : "Denied"}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ chatAppReducer }) => {
  return chatAppReducer;
};

export default withRouter(
  connect(mapStateToProps, {
    sendMessageToUser,
  })(ChatArea)
);
