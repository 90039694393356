/**
 * User Block Component
 */
import React, { Component } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Badge } from "reactstrap";
import { NotificationManager } from "react-notifications";
import Gravatar from "react-gravatar";
// components
import SupportPage from "Components/Support/Support";

// redux action
import { logoutUserFromFirebase } from "Actions";

// intl messages
import IntlMessages from "Util/IntlMessages";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

class FamilyUserBlock extends Component {
  _isMounted = false;
  state = {
    userDropdownMenu: false,
    isSupportModal: false,
    user: { name: "", email: "", url: "" },
  };

  componentDidMount() {
    this._isMounted = true;

    // if (document.cookie) {
    //   let UserDetails = document.cookie.split(";");
    //   let useremail = UserDetails[0].split("=");
    //   let username = UserDetails[1].split("=");

    this.setState({
      user: {
        name: sessionStorage.getItem("username"),
        email: sessionStorage.getItem("email"),
      },
    });
    // }

    //   firebase.auth().onAuthStateChanged((user) => {
    //     if (user !== null) {
    //       this.callback(user);
    //     } else {
    //       if (this._isMounted) {
    //         this.setState({ user: { name: "", email: "", url: "" } });
    //       }
    //     }
    //   }, this.callback);
    // }

    // callback = (user) => {
    //   if (this._isMounted) {
    //     this.setState({
    //       user: {
    //         name: localStorage.getItem("username"),
    //         email: user.email,
    //         url: user.photoURL,
    //       },
    //     });
    //   }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  /**
   * Logout User
   */
  logoutUser(e) {
    e.preventDefault();
    this.props.logoutUserFromFirebase();
  }

  /**
   * Toggle User Dropdown Menu
   */
  toggleUserDropdownMenu() {
    this.setState({ userDropdownMenu: !this.state.userDropdownMenu });
  }

  /**
   * Open Support Modal
   */
  openSupportModal() {
    this.setState({ isSupportModal: true });
  }

  /**
   * On Close Support Page
   */
  onCloseSupportPage() {
    this.setState({ isSupportModal: false });
  }

  /**
   * On Submit Support Page
   */
  onSubmitSupport() {
    this.setState({ isSupportModal: false });
    NotificationManager.success("Message has been sent successfully!");
  }

  render() {
    const { email, name } = this.state.user;
    const domain = sessionStorage.getItem("admin_domain");
    return (
      <div className="top-sidebar">
        <div className="sidebar-user-block">
          <Dropdown
            isOpen={this.state.userDropdownMenu}
            toggle={() => this.toggleUserDropdownMenu()}
            className="notification-dropdown"
          >
            <Gravatar
              email={email}
              default="retro"
              alt="user profile"
              className="img-fluid rounded-circle"
              width="35"
              height="35"
              style={{ border: 0 }}
            />
            &nbsp;&nbsp;&nbsp;
            {domain && (
              <span className="domainstyle">{domain.split("_")[0]}</span>
            )}
            {/* <DropdownToggle tag="div" className="d-flex align-items-center">
              <div className="user-profile">
                <img
                  src={require("Assets/avatars/avatar-blank.jpg")}
                  alt="user profile"
                  className="img-fluid rounded-circle"
                  width="40"
                  height="40"
                  style={{ border: 0 }}
                />
                
              </div>
              <div className="user-info">
                <i className="zmdi zmdi-chevron-down dropdown-icon mx-4"></i>
              </div>
            </DropdownToggle> */}
            {/* <DropdownMenu direction="right">
              <div className="dropdown-content">
                <div className="p-15 border-bottom dropdown-top bg-primary rounded-top">
                  <p className="text-white mb-0 fs-14">
                    {name}
                  </p>
                  <span className="text-white fs-14">
                    {email}
                  </span>
                </div>
                <ul className="list-unstyled dropdown-list mb-0">
                  <li>
                    <Link
                      to={{
                        pathname: "/app/users/user-profile-1",
                        state: { activeTab: 0 },
                      }}
                    >
                      <i className="zmdi zmdi-account text-primary mr-3"></i>
                      <span>
                        <IntlMessages id="widgets.profile" />
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/app/users/user-profile-1",
                        state: { activeTab: 2 },
                      }}
                    >
                      <i className="zmdi zmdi-comment-text-alt text-success mr-3"></i>
                      <span>
                        <IntlMessages id="widgets.messages" />
                      </span>
                      <Badge color="danger" className="pull-right ml-1">
                        3
                      </Badge>
                    </Link>
                  </li>
                  <li>
                    <Link to="/app/pages/feedback">
                      <i className="zmdi zmdi-edit text-warning mr-3"></i>
                      <span>
                        <IntlMessages id="sidebar.feedback" />
                      </span>
                      <Badge color="info" className="pull-right ml-1">
                        1
                      </Badge>
                    </Link>
                  </li>
                  <li className="border-top">
                    <a href="#" onClick={(e) => this.logoutUser(e)}>
                      <i className="zmdi zmdi-power text-danger mr-3"></i>
                      <span>
                        <IntlMessages id="widgets.logOut" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </DropdownMenu> */}
          </Dropdown>
        </div>
        <SupportPage
          isOpen={this.state.isSupportModal}
          onCloseSupportPage={() => this.onCloseSupportPage()}
          onSubmit={() => this.onSubmitSupport()}
        />
      </div>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings }) => {
  return settings;
};

export default connect(mapStateToProps, {
  logoutUserFromFirebase,
})(FamilyUserBlock);
