/**
 * Simple Line Icons
 */
import React from 'react';

// page title bar
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

// intl messages
import IntlMessages from 'Util/IntlMessages';

// rct card box
import RctCollapsibleCard from 'Components/RctCollapsibleCard/RctCollapsibleCard';

const icons = [
  "icon-user",
  "icon-user-female",
  "icon-people",
  "icon-user-follow",
  "icon-user-following",
  "icon-user-unfollow",
  "icon-trophy",
  "icon-speedometer",
  "icon-social-youtube",
  "icon-social-twitter",
  "icon-social-tumblr",
  "icon-social-facebook",
  "icon-social-dropbox",
  "icon-social-dribbble",
  "icon-shield",
  "icon-screen-tablet",
  "icon-screen-smartphone",
  "icon-screen-desktop",
  "icon-plane",
  "icon-notebook",
  "icon-mouse",
  "icon-magnet",
  "icon-magic-wand",
  "icon-hourglass",
  "icon-graduation",
  "icon-ghost",
  "icon-game-controller",
  "icon-fire",
  "icon-eyeglasses",
  "icon-envelope-open",
  "icon-envelope-letter",
  "icon-energy",
  "icon-disc",
  "icon-cursor-move",
  "icon-crop",
  "icon-credit-card",
  "icon-chemistry",
  "icon-bell",
  "icon-badge",
  "icon-anchor",
  "icon-action-redo",
  "icon-action-undo",
  "icon-bag",
  "icon-basket",
  "icon-basket-loaded",
  "icon-mustache",
  "icon-cursor",
  "icon-book-open",
  "icon-briefcase",
  "icon-bubbles",
  "icon-calculator",
  "icon-call-end",
  "icon-call-in",
  "icon-call-out",
  "icon-compass",
  "icon-cup",
  "icon-diamond",
  "icon-direction",
  "icon-directions",
  "icon-docs",
  "icon-drawer",
  "icon-drop",
  "icon-earphones",
  "icon-earphones-alt",
  "icon-feed",
  "icon-film",
  "icon-folder-alt",
  "icon-frame",
  "icon-globe",
  "icon-glorct-alt",
  "icon-handbag",
  "icon-handbag",
  "icon-layers",
  "icon-map",
  "icon-picture",
  "icon-pin",
  "icon-playlist",
  "icon-present",
  "icon-printer",
  "icon-puzzle",
  "icon-speech",
  "icon-vector",
  "icon-wallet",
  "icon-arrow-down",
  "icon-arrow-left",
  "icon-arrow-right",
  "icon-arrow-up",
  "icon-bulb",
  "icon-calendar",
  "icon-control-end",
  "icon-control-forward",
  "icon-control-pause",
  "icon-control-play",
  "icon-control-rewind",
  "icon-control-start",
  "icon-cursor",
  "icon-dislike",
  "icon-equalizer",
  "icon-graph",
  "icon-grid",
  "icon-home",
  "icon-like",
  "icon-list",
  "icon-login",
  "icon-logout",
  "icon-loop",
  "icon-microphone",
  "icon-music-tone",
  "icon-music-tone-alt",
  "icon-note",
  "icon-pencil",
  "icon-pie-chart",
  "icon-question",
  "icon-rocket",
  "icon-share",
  "icon-share-alt",
  "icon-shuffle",
  "icon-size-actual",
  "icon-size-fullscreen",
  "icon-support",
  "icon-tag",
  "icon-trash",
  "icon-umbrella",
  "icon-wrench",
  "icon-ban",
  "icon-bubble",
  "icon-camera",
  "icon-check",
  "icon-clock",
  "icon-close",
  "icon-cloud-download",
  "icon-cloud-upload",
  "icon-doc",
  "icon-envelope",
  "icon-eye",
  "icon-flag",
  "icon-folder",
  "icon-heart",
  "icon-info",
  "icon-key",
  "icon-link",
  "icon-lock",
  "icon-lock-open",
  "icon-magnifier",
  "icon-magnifier-add",
  "icon-magnifier-remove",
  "icon-paper-clip",
  "icon-paper-plane",
  "icon-plus",
  "icon-power",
  "icon-refresh",
  "icon-reload",
  "icon-settings",
  "icon-star",
  "icon-symbol-female",
  "icon-symbol-male",
  "icon-target",
  "icon-volume-1",
  "icon-volume-2",
  "icon-volume-off"
]

const SimpleLineIcons = ({ match }) => {
  return (
    <div className="icons-wrapper">
      <PageTitleBar title={<IntlMessages id="sidebar.simpleLineIcons" />} match={match} />
      <div className="row">
        {icons.map((icon, key) => (
          <RctCollapsibleCard customClasses="icon-box" colClasses="col-sm-6 col-md-4 col-xl-3" key={key}
            contentCustomClasses="item"
          >
            <span aria-hidden="true" className={icon}></span> &nbsp;{icon}
          </RctCollapsibleCard>
        ))}
      </div>
    </div>
  );
};

export default SimpleLineIcons;
