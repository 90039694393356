/**
 * User List Item
 */
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import classnames from "classnames";
import NotificationsIcon from "@material-ui/icons/Notifications";
import VisibilityIcon from "@material-ui/icons/Visibility";

// helpers
import { textTruncate } from "Helpers/helpers";

const UserListItem = ({
  user,
  selectedMember,
  getcurrentTime,
  isShowMemberHistory,
  battery_image,
  showFamilyMemberHistory,
  onClickListItem,
}) => {
  console.log("User Details --" + user);
  return (
    <ListItem
      /*onClick={onClickListItem}*/
      className="border-bottom"
      className={classnames("user-list-item", {
        "item-active":
          selectedMember &&
          !isShowMemberHistory &&
          selectedMember.UID === user.UID,
      })}
    >
      <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="media align-items-center w-100">
          <div className="media-left position-relative mr-10 text-center">
            <img
              // src={
              //   user.profile.imageUrl
              //     ? user.profile.imageUrl
              //     : require("Assets/avatars/avatar-blank.jpg")
              // }
              src={
                user.profile ? (
                  user.profile.imageUrl
                    ? user.profile.imageUrl
                    : require("Assets/avatars/avatar-blank.jpg")
                )
                  : require("Assets/avatars/avatar-blank.jpg")
              }
              className="img-fluid rounded-circle"
              alt="user profile"
              style={{ width: 40, height: 40 }}
            />
            <br />
            <span className="font-xs">
              {user.lastUpdate ? (user.lastUpdate.severity ? user.lastUpdate.severity : "Normal") : "Normal"}
            </span>
            <br />
            <span>
              <NotificationsIcon
                style={{ fontSize: "medium", color: "grey" }}
              />
            </span>
          </div>
          <div className="media-body">
            <div className="d-flex w-100" onClick={onClickListItem}>
              <div className="d-flex align-items-center w-50">
                <p className="mb-0">
                  {/* {user.profile.fullname ? user.profile.fullname : "NoName"} */}
                  {user.profile ? (user.profile.fullname ? user.profile.fullname : "Unknown") : "Unknown"}
                </p>
                <img
                  src={require(`Assets/battery/${battery_image}`)}
                  className="battry-icon"
                />
              </div>
              <p className="mb-0 w-50 text-right mr-2" style={{ fontSize: 12 }}>
                {/* {getcurrentTime(user.lastUpdate.location.updatedAt)} */}
                {user.lastUpdate ? (user.lastUpdate.location ? getcurrentTime(user.lastUpdate.location.updatedAt) : "NA") : "NA"}
              </p>
            </div>
            <div className="d-flex w-100 mt-2">
              <div className="w-90" onClick={onClickListItem}>
                <h4 className="mb-0">
                  {user.status ? user.status : "No Status"}
                </h4>
                {/* <span className="font-xs d-block">{`${user.lastUpdate.activity &&
                  user.lastUpdate.activity.activity.charAt(0).toUpperCase() +
                  user.lastUpdate.activity.activity.slice(1).toLowerCase()
                  } at ${user.lastUpdate.location.address}`}</span> */}
                <span className="font-xs d-block">{
                user.lastUpdate ?
                (user.lastUpdate.activity ? `${user.lastUpdate.activity &&
                  user.lastUpdate.activity.activity.charAt(0).toUpperCase() +
                  user.lastUpdate.activity.activity.slice(1).toLowerCase()
                  } at ${user.lastUpdate.location.address}` : "NA") :
                  "NA"
                }</span>
              </div>
              <div
                onClick={showFamilyMemberHistory}
                className="w-10 mr-2 d-flex align-items-center justify-content-end"
              >
                <VisibilityIcon color="primary" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ListItem>
  );
};

export default UserListItem;
