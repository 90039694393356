import {
  GET_FAMILY_MEMBERS,
  GET_SUCCESS_FAMILY_MEMBERS,
  SET_SELECTED_FAMILY_MEMBER,
  SHOW_FAMILY_MEMBER_HISTORY,
  FILTER_MEMBERS_ON_SEVERITY,
  ADD_FAMILY_MEMBER,
  ADD_FAIL_FAMILY_MEMBER,
  ADD_SUCCESS_FAMILY_MEMBER,
  SHOW_FAMILY_MEMBERS_LOADING_INDICATOR,
  HIDE_FAMILY_MEMBERS_LOADING_INDICATOR,
} from "Actions/types";

const INITIAL_STATE = {
  allMembers: null,
  members: null,
  loading: false,
  selectedMember: null,
  isShowMemberHistory: false,
  filterString: null,
  addMemberMsg: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_FAMILY_MEMBER:
      return { ...state, loading: true };
    case ADD_SUCCESS_FAMILY_MEMBER:
      return { ...state, addMemberMsg: action.payload, loading: false };
    case ADD_FAIL_FAMILY_MEMBER:
      return { ...state, addMemberMsg: action.payload, loading: false };
    case SHOW_FAMILY_MEMBER_HISTORY:
      return { ...state, isShowMemberHistory: action.payload };
    case SET_SELECTED_FAMILY_MEMBER:
      return { ...state, selectedMember: action.payload };
    case FILTER_MEMBERS_ON_SEVERITY:
      let filteredMembers = [];
      let severity = action.payload;
      let filterString = null;
      if (severity !== "All") {
        filteredMembers = state.allMembers.filter(
          (obj) => obj.severity === severity
        );
        filterString = severity;
      } else {
        filteredMembers = state.allMembers;
        filterString = null;
      }
      return { ...state, members: filteredMembers, filterString };
    case GET_FAMILY_MEMBERS:
      return { ...state, loading: true };
    // get family members
    case GET_SUCCESS_FAMILY_MEMBERS:
      return {
        ...state,
        members: action.payload,
        allMembers: action.payload,
        loading: false,
      };
    //show loading
    case SHOW_FAMILY_MEMBERS_LOADING_INDICATOR:
      return { ...state, loading: true };
    // hide loading
    case HIDE_FAMILY_MEMBERS_LOADING_INDICATOR:
      return { ...state, loading: false };
    // default case
    default:
      return { ...state };
  }
};
