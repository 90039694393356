/**
 * User List
 */
import React, { Component } from "react";
import { Input, Button } from "reactstrap";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
// import { withRouter } from "react-router-dom";
const { withRouter } = require("react-router-dom");
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ImportExportIcon from "@material-ui/icons/ImportExport";
const moment = require("moment");
import LocationMap from "../FamilyDetail/components/LocationMap";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
// rct card box
// import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
const RctCollapsibleCard = require("Components/RctCollapsibleCard/RctCollapsibleCard");

// app layouts
import { getAppLayout } from "Helpers/helpers";
//const { getAppLayout } = require("Helpers/helpers");

// components
import RecentActivity from "./RecentActivity";
import RctSectionLoader from "../RctSectionLoader/RctSectionLoader";

// redux actions
const {
  chatWithSelectedUser,
  updateUsersSearch,
  onSearchUsers,
  getMemberHistory,
} = require("Actions");

declare namespace JSX {
  interface IntrinsicElements {
    ActivityList: { [key: string]: any };
  }
}

type ActivityListProps = {
  chatWithSelectedUser(any): void;
  onMenuIconPress(): void;
  updateUsersSearch(any): void;
  onClose(): void;
  onSearchUsers(any): void;
  getMemberHistory(arg1: any, arg2: any): void;
  onUpdateSearch(any): void;
  onInviteIconClick(): void;
  location: any;
  searchUsers: any;
  memberName: any;
  memberHistoryData: any;
  selectedMember: any;
  bothVisible: boolean;
  loading: any;
  count?: number;
};

class ActivityList extends Component<ActivityListProps> {
  state = {
    daybefore: 0,
    date: moment().format("YYYY-MM-DD").toString(),
    showMap: false,
  };

  /**
   * Swicth Chat With User
   * @param {*object} user
   */
  switchChatWithUser(user) {
    this.props.chatWithSelectedUser(user);
  }

  /**
   * On Search Chat Users
   */
  updateSearch(e) {
    this.props.updateUsersSearch(e.target.value);
    this.props.onSearchUsers(e.target.value);
  }

  handleDatePickerChange(e) {
    this.setState({
      date: moment(e.target.value).format("YYYY-MM-DD").toString(),
      daybefore: 0,
    });
    this.props.getMemberHistory(
      this.props.selectedMember.UID,
      moment(e.target.value).format("YYYY_MM_DD")
    );
  }

  handleDayBefore() {
    this.setState({
      date: moment()
        .subtract(this.state.daybefore + 1, "days")
        .startOf("day")
        .format("YYYY-MM-DD")
        .toString(),
      daybefore: this.state.daybefore + 1,
    });
    console.log("daybefore has been called");
    this.props.getMemberHistory(
      this.props.selectedMember.UID,
      moment()
        .subtract(this.state.daybefore + 1, "days")
        .startOf("day")
        .format("YYYY_MM_DD")
    );
  }

  handleDayAfter() {
    this.setState({
      date: moment()
        .subtract(this.state.daybefore - 1, "days")
        .startOf("day")
        .format("YYYY-MM-DD")
        .toString(),
      daybefore: this.state.daybefore - 1,
    });
    console.log("dayAfter has been called");
    this.props.getMemberHistory(
      this.props.selectedMember.UID,
      moment()
        .subtract(this.state.daybefore - 1, "days")
        .startOf("day")
        .format("YYYY_MM_DD")
    );
  }

  getScrollHeight() {
    const { location } = this.props;
    const appLayout = getAppLayout(location);
    switch (appLayout) {
      case "app":
        return "calc(100vh  - 236px)";
      case "agency":
        return "calc(100vh - 372px)";
      case "boxed":
        return "calc(100vh - 372px)";
      case "horizontal":
        return "calc(100vh - 238px)";
      default:
        break;
    }
  }
  getScrollHeightForSm() {
    const { location } = this.props;
    const appLayout = getAppLayout(location);
    switch (appLayout) {
      case "app":
        return "calc(100vh - 30vh  - 236px)";
      case "agency":
        return "calc(100vh - 30vh - 372px)";
      case "boxed":
        return "calc(100vh - 30vh - 372px)";
      case "horizontal":
        return "calc(100vh - 30vh - 238px)";
      default:
        break;
    }
  }

  ShowMap = () => {
    console.log("showMap");
    this.setState({
      showMap: !this.state.showMap,
    });
  };

  render() {
    const {
      searchUsers,
      memberName,
      selectedMember,
      memberHistoryData,
    } = this.props;

    return (
      <div className="bg-white pl-2 pr-2 border">
        <div className="d-flex w-100">
          <div className="w-10">
            <IconButton
              className="mr-3 chat-sidebar-toggler d-lg-none d-xl-none d-md-block d-sm-block d-xs-block"
              color="inherit"
              aria-label="open drawer"
              onClick={this.props.onMenuIconPress}
            >
              <MenuIcon color="primary" />
            </IconButton>
          </div>
          <h2 className=" w-90 text-center p-2">
            {selectedMember
              ? `${selectedMember.profile.fullname} History`
              : "History"}
          </h2>
        </div>
        <div className="d-flex">
          <IconButton onClick={this.handleDayBefore.bind(this)}>
            <ChevronLeftIcon
              style={
                this.state.daybefore > 0
                  ? { fontSize: "xx-large", color: "#4a9df7" }
                  : { fontSize: "xx-large", color: "#747474" }
              }
            />
          </IconButton>
          <TextField
            id="date"
            type="date"
            fullWidth={true}
            size="small"
            variant="outlined"
            value={this.state.date}
            className="p-2"
            onChange={(e) => {
              this.handleDatePickerChange(e);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <IconButton onClick={this.handleDayAfter.bind(this)}>
            <ChevronRightIcon
              style={
                this.state.daybefore < 0
                  ? { fontSize: "xx-large", color: "#4a9df7" }
                  : { fontSize: "xx-large", color: "#747474" }
              }
            />
          </IconButton>
        </div>

        {this.props.loading ? (
          <RctSectionLoader />
        ) : (
          <React.Fragment>
            {memberHistoryData === null || memberHistoryData.length === 0 ? (
              <div
                className="p-3 text-center"
                style={{
                  border: "1px solid #eee",
                  borderBottom: "2px solid #c5c1c1",
                }}
              >
                No data to display
              </div>
            ) : (
              <React.Fragment>
                {this.props.bothVisible ? (
                  <React.Fragment>
                    {this.state.showMap ? (
                      <React.Fragment>
                        <div
                          className="p-3 d-flex"
                          style={{
                            cursor: "pointer",
                            border: "1px solid #eee",
                            borderBottom: "2px solid #c5c1c1",
                          }}
                          onClick={this.ShowMap}
                        >
                          <div className="mr-10">Show Activity List</div>
                          <ArrowForwardIosIcon />
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div
                          className="p-3 d-flex"
                          style={{
                            cursor: "pointer",
                            border: "1px solid #eee",
                            borderBottom: "0px",
                          }}
                          onClick={this.ShowMap}
                        >
                          <div className="w-90">
                            {`Activity ${
                              this.props.count ? this.props.count : ""
                            }`}
                          </div>
                          <ArrowBackIosIcon />
                          <div className="w-10 text-right">
                            <ImportExportIcon />
                          </div>
                        </div>
                      </React.Fragment>
                    )}

                    <div className="row">
                      {this.state.showMap ? (
                        <React.Fragment>
                          <div className="col-12 pl-6 pr-6 d-none d-block">
                            {/* <RctCollapsibleCard> */}
                            <div style={{ height: "485px" }}>
                              <LocationMap
                                fullWidth={1}
                                //ShowMap={this.ShowMap}
                              />
                            </div>
                            {/* </RctCollapsibleCard> */}
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 pl-6 pr-0 d-none d-sm-block d-md-block d-lg-block d-xl-block ">
                            <div style={{ width: "100%" }}>
                              <Scrollbars
                                className="rct-scroll"
                                autoHide
                                style={{ height: this.getScrollHeight() }}
                              >
                                <RecentActivity />
                              </Scrollbars>
                            </div>
                          </div>
                          <div className="col-6 pl-0 pr-6 d-none d-block">
                            {/* <div
                              className="text-center"
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                height: "25px",
                                border: "1px solid #eee",
                                borderBottom: "2px solid #c5c1c1",
                              }}
                              onClick={this.ShowMap}
                            >
                              <ArrowBackIosIcon />
                            </div> */}
                            <LocationMap
                              fullWidth={1}
                              //ShowMap={this.ShowMap}
                            />
                          </div>
                        </React.Fragment>
                      )}

                      <div className="col-12 col-sm-12 col-md-12 p-0 d-none d-sm-block d-block d-md-none">
                        <div className="row mb-1">
                          <div className="col-12 mb-1">
                            {/* {" "} */}
                            <Scrollbars
                              className="rct-scroll"
                              autoHide
                              style={{ height: this.getScrollHeightForSm() }}
                            >
                              <RecentActivity />
                            </Scrollbars>
                          </div>
                          <div className="col-12 mt-1 ">
                            <LocationMap fullWidth={0} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="">
                    <Scrollbars
                      className="rct-scroll"
                      autoHide
                      style={{ height: this.getScrollHeight() }}
                    >
                      <RecentActivity />
                    </Scrollbars>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ chatAppReducer, memberHistory, family }) => {
  const { selectedMember } = family;
  const { memberHistoryData, loading } = memberHistory;

  const { selectedUser } = chatAppReducer;
  const count = memberHistoryData ? memberHistoryData.length : null;
  return { count, selectedMember, memberHistoryData, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    chatWithSelectedUser,
    updateUsersSearch,
    onSearchUsers,
    getMemberHistory,
  })(ActivityList)
);
