/**
 * Sign Up With Firebase
 */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input } from "reactstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import QueueAnim from "rc-queue-anim";
import axios from "axios";
import { apiURL } from "../constants/URL";
import "../assets/custom-styles/allstyles.css";
import { NotificationManager } from "react-notifications";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// app config
const AppConfig = require("Constants/AppConfig");
// redux action
import {
  signupAdminInFirebase,
  signupEmployeeInFirebase,
  signinUserWithFacebook,
  signinUserWithGoogle,
  signinUserWithGithub,
  signinUserWithTwitter,
} from "../actions/AuthActions";

import firebase from "firebase/app";
import "firebase/auth";

interface EmployeeSignUpProps {
  signupEmployeeInFirebase(arg0: any, arg1: any): void;
  loading: boolean;
  history: any;
}
interface EmployeeSignUpState {
  errors: { [key: string]: string };
  formdata: { [key: string]: string };
  datafetching?: boolean;
  userExist?: boolean;
}
interface ErrorType {
  name?: string;
  email?: string;
  phone?: string;
  password?: string;
}
class EmployeeSignUp extends Component<
  EmployeeSignUpProps,
  EmployeeSignUpState
> {
  state = {
    formdata: {
      name: "",
      email: "",
      password: "",
      phone: "91",
      role: "Employee",
    },
    errors: {
      email: "",
      name: "",
      password: "",
    },
    userExist: false,
    datafetching: true,
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const invitationID = urlParams.get("invitationID") || "";
    const domain = urlParams.get("domain");
    if (domain) {
      console.log("Invitaion through the : ", domain);
      let reqDomain = domain.split(".").join("_");
      let dataRef = firebase
        .database()
        .ref("domains/" + reqDomain + "/invitations");

      dataRef.child(invitationID).once(
        "value",
        (snapshot) => {
          console.log("Snapshot Key -- " + snapshot.key);
          if (snapshot.val() != null) {
            console.log("Invitation Exist..");
            console.log("Employee email -- " + snapshot.val().email);
            /*Domain Exists*/
            console.log("Invitation valid. --" + snapshot.val().email);
            let email = snapshot.val().email;
            axios
              .get(apiURL + `/${email}/employee`)
              .then(({ data }) => {
                console.log("Fetched user details from database -->", data);
                let phone: string = data.phoneNumber
                  ? data.phoneNumber.slice(1, data.phoneNumber.length)
                  : "91";
                let name: string = data.displayName ? data.displayName : "";
                let formdata = {
                  email,
                  phone,
                  name,
                  password: "",
                  role: "Employee",
                };
                this.setState({
                  formdata,
                  userExist: data.code ? false : true,
                  datafetching: false,
                });
              })
              .catch((error) => {
                console.log(
                  "error while sending request to get the details of the user",
                  error
                );
              });
          } else {
            /*Create new domain*/
            console.log("Invitation expired. --");
            NotificationManager.error("Invitation expired.");
          }
        },
        (error) => {
          console.log("Error : ", error["code"]);
          NotificationManager.error("Error occured." + error.message);
        }
      );
    }
  }
  //validating input on onChange
  validateInput = (e) => {
    switch (e.currentTarget.name) {
      case "name":
        if (!e.currentTarget.value.trim()) return "*Name is required";
        break;
      case "email":
        if (!e.currentTarget.value.trim()) return "*Email is required";
        else if (
          !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            e.currentTarget.value
          )
        )
          return "*not a valid email";
        break;
      case "phone":
        if (!e.currentTarget.value.trim()) return "*Contact no. is required";
        else if (!/^\d{10}$/.test(e.currentTarget.value))
          return "*should be 10 digit number";
        break;
      case "password":
        if (!e.currentTarget.value.trim()) return "*Password is required";
        else if (e.currentTarget.value.trim().length < 5)
          return "*Password should have at least 5 characters";
        break;
      default:
        break;
    }
    return "";
  };

  //validating input for making sign-up button to work
  validate = () => {
    const { formdata, userExist } = this.state;
    let errs: ErrorType = {};
    if (!formdata.name.trim()) errs.name = "*Name is required";
    if (!formdata.email.trim()) errs.email = "*Email is required";
    else if (
      !/^([A-Za-z0-9.]+)@([A-Za-z0-9]+)\.([a-z]{3})$/.test(formdata.email)
    )
      errs.email = "*not a valid email";
    if (!formdata.phone.trim()) errs.phone = "*Contact no. is required";
    else if (!/^\d{12}$/.test(formdata.phone))
      errs.phone = "*should be 10 digit number";
    if (!userExist) {
      if (!formdata.password.trim()) errs.password = "*Password is required";
      else if (formdata.password.trim().length < 5)
        errs.password = "*Password should have at least 5 characters";
    }
    return errs;
  };

  //handling input fields
  handleChange = (e) => {
    let errString = this.validateInput(e);
    const data = { ...this.state.formdata };
    let errors = { ...this.state.errors };
    errors[e.currentTarget.name] = errString;
    data[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ formdata: data, errors });
  };
  /**
   * On User Signup
   */
  handlephoneinput = (e) => {
    console.log(e);
    this.setState({
      formdata: {
        ...this.state.formdata,
        phone: e,
      },
    });
  };

  isFormInvalid = () => {
    let errs = this.validate();
    let errCount = Object.keys(errs).length;
    return errCount > 0;
  };

  onUserSignUp() {
    const { formdata, userExist } = this.state;
    const { name, email, password, phone, role } = formdata;
    const urlParams = new URLSearchParams(window.location.search);
    const invitationID = urlParams.get("invitationID");
    const domain = urlParams.get("domain") || "";
    const adminId = urlParams.get("adminId");
    let reqDomain = domain.split(".").join("_");
    this.props.signupEmployeeInFirebase(
      {
        name,
        email,
        password,
        phone,
        role,
        reqDomain,
        invitationID,
        adminId,
      },
      this.props.history
    );
  }

  render() {
    const { formdata, userExist, datafetching, errors } = this.state;
    const { name, email, password, phone } = formdata;
    const { loading } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const domain = urlParams.get("domain")!.split(".");
    return (
      <React.Fragment>
        <QueueAnim type="bottom" duration={2000}>
          <div className="empsignup">
            {loading && <LinearProgress />}
            {datafetching && <LinearProgress variant="query" />}
            <AppBar position="static" className="session-header">
              <Toolbar>
                <div className="container mt-2">
                  <div className="d-flex justify-content-between">
                    <div className="session-logo">
                      <Link to="/">
                        <img
                          src="https://i.ibb.co/8gsVdKL/white-logo.png"
                          alt="session-logo"
                          width="40"
                          height="40"
                        />
                        &nbsp; &nbsp;
                        <span className="header">
                          {AppConfig.default.brandName}
                        </span>
                      </Link>
                    </div>
                    <div></div>
                  </div>
                </div>
              </Toolbar>
            </AppBar>

            {!datafetching ? (
              <React.Fragment>
                <br />
                <div className="row">
                  <div className="col-12 text-center">
                    <div className="header">
                      <span style={{ textTransform: "capitalize" }}>
                        {domain[0]}{" "}
                      </span>
                      is inviting You
                    </div>
                  </div>
                </div>
                <br />
                <br />
              </React.Fragment>
            ) : (
              ""
            )}
            {!datafetching && (
              <div className="session-inner-wrapper">
                <div className="container">
                  <div className="row row-eq-height">
                    <div className="d-none d-sm-inline-block col-sm-1 col-md-2"></div>
                    <div className="col-12 col-sm-10 col-md-8 cardshadow">
                      <div className="session-body text-center">
                        <br />
                        <div className="session-head mb-15">
                          <h2>
                            Get started with {AppConfig.default.brandName}
                          </h2>
                        </div>
                        <Form>
                          <FormGroup className="has-wrapper">
                            <Input
                              type="text"
                              value={name}
                              name="name"
                              id="user-name"
                              className="has-input input-lg border"
                              placeholder="Enter Your Name"
                              onChange={this.handleChange}
                              disabled={
                                datafetching ? true : userExist ? true : false
                              }
                            />
                            <span className="has-icon">
                              <i className="ti-user"></i>
                            </span>
                            {errors.name ? (
                              <div
                                style={{ fontSize: "13px" }}
                                className="text-left text-danger ml-2"
                              >
                                {errors.name}
                              </div>
                            ) : (
                              ""
                            )}
                          </FormGroup>
                          <FormGroup className="has-wrapper">
                            <Input
                              type="mail"
                              value={email}
                              name="email"
                              id="user-mail"
                              className="has-input input-lg border"
                              placeholder="Enter Email Address"
                              disabled={true}
                            />
                            <span className="has-icon">
                              <i className="ti-email"></i>
                            </span>
                            {errors.email ? (
                              <div
                                style={{ fontSize: "13px" }}
                                className="text-left text-danger ml-2"
                              >
                                {errors.email}
                              </div>
                            ) : (
                              ""
                            )}
                          </FormGroup>
                          {!userExist ? (
                            <FormGroup className="has-wrapper">
                              <Input
                                value={password}
                                type="Password"
                                name="password"
                                id="pwd"
                                className="has-input input-lg border"
                                placeholder="Password"
                                onChange={this.handleChange}
                                disabled={
                                  datafetching ? true : userExist ? true : false
                                }
                              />
                              <span className="has-icon">
                                <i className="ti-lock"></i>
                              </span>
                              {errors.password ? (
                                <div
                                  style={{ fontSize: "13px" }}
                                  className="text-left text-danger ml-2"
                                >
                                  {errors.password}
                                </div>
                              ) : (
                                ""
                              )}
                            </FormGroup>
                          ) : (
                            ""
                          )}
                          <FormGroup className="has-wrapper">
                            <PhoneInput
                              isValid={(value, country) => {
                                if (value.match(/12345/)) {
                                  return (
                                    //"Invalid value: " + value + ", " + country.name
                                    "Invalid value: " + value
                                  );
                                } else if (value.match(/1234/)) {
                                  return false;
                                } else if (
                                  value.length < 12 &&
                                  value.length > 3
                                ) {
                                  return "Enter a valid number";
                                } else {
                                  return true;
                                }
                              }}
                              disabled={
                                datafetching ? true : userExist ? true : false
                              }
                              placeholder="Enter Phone Number"
                              inputStyle={{
                                width: "100%",
                                height: "55px",
                                border: "1px solid #eee",
                              }}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true,
                              }}
                              value={phone}
                              onChange={this.handlephoneinput}
                            />
                            <span className="has-icon">
                              <i className="ti-mobile"></i>
                            </span>
                            <div id="recaptcha-container"></div>
                          </FormGroup>
                          <FormGroup className="mb-15">
                            <Button
                              className="btn btn-info text-white btn-block w-100"
                              disabled={this.isFormInvalid()}
                              onClick={() => this.onUserSignUp()}
                            >
                              {datafetching
                                ? "Wait.."
                                : userExist
                                ? "Accept"
                                : "Sign up"}
                            </Button>
                          </FormGroup>
                        </Form>
                        <p>
                          <Link to="/terms-condition" className="text-muted">
                            Terms of Service
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="d-none d-sm-inline-block col-sm-1 col-md-2"></div>
                  </div>
                </div>
              </div>
            )}
            {datafetching && (
              <span className="loadingstyle">
                Loading invitation details.....
              </span>
            )}
          </div>
        </QueueAnim>
      </React.Fragment>
    );
  }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
  const { loading } = authUser;
  return { loading };
};

export default connect(mapStateToProps, {
  signupAdminInFirebase,
  signupEmployeeInFirebase,
  signinUserWithFacebook,
  signinUserWithGoogle,
  signinUserWithGithub,
  signinUserWithTwitter,
})(EmployeeSignUp);
