/**
 * Notification Component
 */
import React, { Component } from "react";
import ActivityList from "../FamilyHistory/ActivityList";
// const ActivityList = require("../FamilyHistory/ActivityList")

import { connect } from "react-redux";

import FamilyMemberMap from "./components/FamilyMemberMap";
// import { addfamilyMember, getMemberHistory } from "Actions";
const { addfamilyMember, getMemberHistory } = require("Actions")
import LocationMap from "./components/LocationMap";
import SelectingData from "../FamilyHistory/selectingData";

// const SelectingData = require("../FamilyHistory/selectingData");


type Indexprops = {
  selectedMember: any,
  onMenuIconPress(): void;
  isShowMemberHistory: any,
}



// type ReduxType = ReturnType<typeof mapStateToProps>
// <Indexprops> 

class MemberDetail extends Component<Indexprops>  {

  constructor(props: Indexprops) {
    super(props);
    // this.state = {
    //   loading: false,
    // };
  }

  render() {
    if (!this.props.selectedMember) {
      return (
        <React.Fragment>
          <SelectingData onMenuIconPress={this.props.onMenuIconPress} />
        </React.Fragment>
      );
    } else if (this.props.isShowMemberHistory) {
      return (
        <ActivityList
          onMenuIconPress={this.props.onMenuIconPress}
          bothVisible={0}
        />
      );
    } else {
      return (
        <React.Fragment>
          {this.props.selectedMember ? (
            <ActivityList
              selectedMemeber={this.props.selectedMember}
              onMenuIconPress={this.props.onMenuIconPress}
              bothVisible={1}
            />

          ) : (
              <div className="p-3 text-center"> Please Select User</div>
            )}
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = ({ family }) => {
  const { isShowMemberHistory, selectedMember } = family;

  return { isShowMemberHistory, selectedMember };
};

export default connect(mapStateToProps, {
  addfamilyMember,
})(MemberDetail);

// const connectedMemberDetail : any = connect(mapStateToProps, { addfamilyMember })(MemberDetail)
// export { connectedMemberDetail as MemberDetail };