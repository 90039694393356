/**
 * Chat Sidebar
 */
import React, { Component } from "react";
import { Input } from "reactstrap";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
const { withRouter } = require("react-router-dom");
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import RctPageLoader from "../../../components/RctPageLoader/RctPageLoader";
// app layouts
import { getAppLayout } from "Helpers/helpers";

//components
import DoaminListItem from "./DomainListItem";
import DeleteDialogueBox from "./DeleteDialoguebox";

// redux actions
import {
  chatWithSelectedUser,
  updateUsersSearch,
  onSearchUsers,
} from "Actions";

type AnyObj = {
  [key: string]: any;
};

type DoaminSidebarProps = {
  location: any;
  domainsList: AnyObj[];
  serverstatus: boolean;
  selectedDomain: AnyObj;
  open: boolean;
  searchedinput: string;
  deleteDomain: AnyObj;
  onClose(): void;
  onSearch(any): void;
  onSwitchChange(AnyObj): void;
  onDomainItemClick(AnyObj): void;
  onDeleteIconClick(AnyObj): void;
};

class DoaminSidebar extends Component<DoaminSidebarProps> {
  onDomainItemClick = (selecteddomain) => {
    this.props.onDomainItemClick(selecteddomain);
  };

  onDeleteIconClick = (selecteddomain) => {
    this.props.onDeleteIconClick(selecteddomain);
  };

  onSwitchChange = (data) => {
    this.props.onSwitchChange(data);
  };

  onClose = () => {
    this.props.onClose();
  };

  getScrollHeight() {
    const { location } = this.props;
    const appLayout = getAppLayout(location);
    switch (appLayout) {
      case "app":
        return "calc(100vh - 107px)";
      case "agency":
        return "calc(100vh - 372px)";
      case "boxed":
        return "calc(100vh - 372px)";
      case "horizontal":
        return "calc(100vh - 238px)";
      default:
        break;
    }
  }

  render() {
    const {
      domainsList,
      serverstatus,
      open,
      selectedDomain,
      searchedinput,
      deleteDomain,
    } = this.props;
    return (
      <React.Fragment>
        <div className="chat-sidebar">
          <div className="search-wrapper mb-0 position-relative">
            <Input
              type="text"
              name="search-users"
              id="search"
              className="search-input rounded-0 py-2 px-3"
              placeholder="Search"
              onChange={this.props.onSearch}
              value={searchedinput}
            />
            <i className="zmdi zmdi-search search-icon"></i>
          </div>
          <div className="chat-list">
            <Scrollbars
              className="rct-scroll"
              autoHide
              style={{ height: this.getScrollHeight() }}
            >
              {domainsList.length === 0 && !serverstatus && (
                <div style={{ position: "absolute", top: "45%", left: "45%" }}>
                  <RctPageLoader />
                </div>
              )}
              {/* {domainsList.length === 0 && serverstatus&&

        <div
          style={{
            position: "absolute",
            left: "35%",
            top: "45%",
            opacity: ".3",
            // fontWeight: "500",
            fontWeight: "bold",
            fontSize: "17px",
          }}
        >
          {serverstatus ? "No results found" : "No Employees found"}
        </div>
    } */}
              <List className="p-0 mb-0">
                {domainsList.length !== 0 &&
                  domainsList.map((domain, index) => (
                    <DoaminListItem
                      key={index}
                      domainItem={domain}
                      onDomainItemClick={this.onDomainItemClick}
                      onDeleteIconClick={this.onDeleteIconClick}
                      onSwitchChange={this.props.onSwitchChange}
                    />
                  ))}
              </List>
            </Scrollbars>
          </div>
        </div>
        {/* 
        Delete Dialogue Box
      */}
        <DeleteDialogueBox
          deleteDomain={deleteDomain}
          open={open}
          onClose={this.props.onClose}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ chatAppReducer }) => {
  return chatAppReducer;
};

export default withRouter(
  connect(mapStateToProps, {
    chatWithSelectedUser,
    updateUsersSearch,
    onSearchUsers,
  })(DoaminSidebar)
);
