/**
 * User List Item
 */
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import classnames from "classnames";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";

// helpers
import { textTruncate } from "Helpers/helpers";

const DoaminListItem = ({
  domainItem,
  onDomainItemClick,
  onDeleteIconClick,
  onSwitchChange,
}) => (
  <ListItem
    onClick={() => onDomainItemClick(domainItem)}
    // className={classnames("user-list-item", {
    //   "item-active": selectedUser && selectedUser.id === user.id,
    // })}
  >
    <img
      src={require("Assets/avatars/avatar-blank.jpg")}
      className="img-fluid rounded-circle"
      alt="user profile"
      width="40"
      height="40"
      style={{ cursor: "pointer" }}
    />
    <ListItemText
      className="mr-30 ml-5"
      primary={
        <div style={{ cursor: "pointer" }}>
          <div
            className="font-weight-bold"
            style={{ textTransform: "capitalize" }}
          >
            {domainItem.domain.split("_")[0]}
          </div>
          <div>
            <span style={{ fontSize: "13px" }}>{domainItem.url}&nbsp; </span>
          </div>
          <div style={{ fontSize: "11px" }}>
            Admin - {domainItem.totalAdmin.length}
          </div>
          <div style={{ fontSize: "11px" }}>
            Employees - {domainItem.totalEmployee.length}
          </div>
        </div>
      }
    ></ListItemText>
    <ListItemSecondaryAction className="ml-10">
      <Switch
        checked={domainItem.isActive}
        onChange={() => {
          onSwitchChange(domainItem);
        }}
      />
      <IconButton
        aria-label="Delete"
        onClick={() => onDeleteIconClick(domainItem)}
      >
        <i className="zmdi zmdi-delete text-primary"></i>
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>
);

export default DoaminListItem;
