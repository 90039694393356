import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
// import yellowM from "./markers/yellow-dot.png";
// import blueM from "./markers/blue-dot.png";
// import brownM from "./markers/orange-dot.png";
// import greenM from "./markers/green-dot.png";
// import redM from "./markers/red-dot.png";
const yellowM = require("./markers/yellow-dot.png");
const blueM = require("./markers/blue-dot.png");
const brownM = require("./markers/orange-dot.png");
const greenM = require("./markers/green-dot.png");
const redM = require("./markers/red-dot.png");

import moment from "moment";
// import { RctCard, RctCardContent } from "Components/RctCard";
const { RctCard, RctCardContent } = require("Components/RctCard");

//Google Map API key
const key = "AIzaSyBL9aK5U3tn5pr4RYibOiBlhGwToANFKcM";

const getcurrentTime = (dateString) => {
  let time = dateString
    ? moment(dateString).format(" hh:mm a")
    : moment().format("hh:mm a");
  let daysAgo = dateString ? moment(dateString).fromNow() : moment().fromNow();

  return time;
};

const makeStringContainer = (address, updatedAt, severity) => {
  let t = getcurrentTime(updatedAt);
  return "<div className='text-center'><h6><strong>" + t + "<strong><h6><div>";
};


type DisplayMapprops = {
  location: any,
  fullWidth: any,
  severity: any,

}


class DisplayingLocation extends Component<DisplayMapprops> {
  node: any;
  state = {
    avglat: 0,
    avglng: 0,
    paths: [],
    show: false,
  };

  constructor(props) {
    super(props);
    this.node = {};
  }

  componentDidMount() {
    this.getLocation();
  }

  getLocation = () => {
    let location = this.props.location;
    let avglat = 0;
    let avglng = 0;
    let paths: any = [];
    for (var i = 0; i < location.length; i++) {
      let a: any = {};
      a.lat = location[i].latitude;
      a.lng = location[i].longitude;
      avglat = avglat + location[i].latitude;

      avglng = avglng + location[i].longitude;
      paths.push(a);
    }
    avglat = avglat / location.length;
    avglng = avglng / location.length;

    this.setState({
      avglat,
      avglng,
      paths,
    });
    var Styles = this.props.fullWidth
      ? { height: "62vh", width: "100%", marginBottom: 5 }
      : { height: "30vh", width: "100%", marginBottom: 5 };
  };

  handleApiLoaded() {
    this.ShowMarkers(this.node, this.props.location);
  }

  ShowMarkers(node, locations) {
    // console.log(node, locations);
    var bounds = new node.maps_.LatLngBounds();
    let severity = this.props.severity;
    var markers = locations.map(function (l, i) {
      const { latitude: lat, longitude: lng, address, updatedAt } = l;
      let t = getcurrentTime(updatedAt);
      const infowindow = new node.maps_.InfoWindow({
        content: makeStringContainer(address, updatedAt, severity[i]),
      });
      /**
       * Use to Show Pins on Map
       */
      let map = node.map_;
      let mark = new node.maps_.Marker({
        position: { lat, lng },
        map,
        icon:
          severity[i] === "Normal"
            ? greenM
            : severity[i] === "Caution"
              ? yellowM
              : severity[i] === "Warning"
                ? brownM
                : redM,
        //  label: "A",
        // title: "Last updated " + t + "\n" + address,
        // animation: google.maps.Animation.DROP,
      });
      let loc = new node.maps_.LatLng(mark.position.lat(), mark.position.lng());
      bounds.extend(loc);
      node.map_.fitBounds(bounds);
      infowindow.open(map, mark);
      /**
       * Use to Show Info of Particular Mark
       */
      mark.addListener("click", () => {
        infowindow.open(map, mark);
      });
      return mark;
    });
  }

  // Return map bounds based on list of places
  getMapBounds(maps) {
    console.log("bound");
    const bounds = new maps.LatLngBounds();
    this.props.location.forEach((marker) => {
      bounds.extend(new maps.LatLng(marker.latitude, marker.longitude));
    });
    return bounds;
  }

  render() {
    const { avglat, avglng, show } = this.state;
    const { location } = this.props;
    console.log("State is --->", this.state);

    // if (this.mapRef && this.props.location.length > 1) {
    //   const bounds = this.getMapBounds(this.mapsRef);
    //   this.mapRef.fitBounds(bounds);
    // } else if (this.mapRef) {
    //   this.mapRef.setCenter(22, 77);
    //   this.mapRef.setZoom(15);
    // }

    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: key }}
        defaultCenter={{ lat: avglat, lng: avglng }}
        zoom={10}
        mapTypeControl={false}
        fullscreenControl={false}
        disableDefaultUI={true}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={() => {
          // var bounds = new maps.LatLngBounds();
          this.handleApiLoaded();
        }}
        // onChildClick={this._onChildClick}
        ref={(node) => {
          this.node = node;
        }}
      ></GoogleMapReact>
    );
  }
}

export default DisplayingLocation;

// import React from "react";
// import GoogleMapReact from "google-map-react";
// import moment from "moment";
// import yellowM from "./markers/yellow-dot.png";
// import blueM from "./markers/blue-dot.png";
// import brownM from "./markers/orange-dot.png";
// import greenM from "./markers/green-dot.png";
// import redM from "./markers/red-dot.png";

// //Google Map API key
// const key = "AIzaSyBL9aK5U3tn5pr4RYibOiBlhGwToANFKcM";

// const getcurrentTime = (dateString) => {
//   let time = dateString
//     ? moment(dateString).format(" hh:mm a")
//     : moment().format("hh:mm a");
//   let daysAgo = dateString ? moment(dateString).fromNow() : moment().fromNow();

//   return time;
// };

// const makeStringContainer = (address, updatedAt, severity) => {
//   let t = getcurrentTime(updatedAt);
//   return "<div className='text-center'><h6><strong>" + t + "<strong><h6><div>";
// };

// const calculateAndDisplayRoute = (
//   directionsService,
//   directionsRenderer,
//   location
// ) => {
//   //console.log("aagamInnerRender", location);

//   let wayPoints = (google.maps.DirectionsWaypoint = []);
//   for (var i = 1; i < location.length - 1; i++) {
//     wayPoints.push({
//       location: location[i].address,
//       stopover: true,
//     });
//   }

//   /*
//   directionsService.route(
//     {
//       origin: location[0].address,
//       destination: location[location.length - 1].address,
//       waypoints: wayPoints,
//       optimizeWaypoints: true,
//       travelMode: google.maps.TravelMode.DRIVING,
//     },
//     (response, status) => {
//       if (status === "OK") {
//         console.log("response", status);
//         directionsRenderer.setDirections(response);
//       } else {
//         alert("Directions request failed due to " + status);
//       }
//     }
//   );*/
// };

// const DisplayingLocation = ({ location, fullWidth, severity }) => {
//   let avglat = 0;
//   let avglng = 0;
//   let paths = [];
//   for (var i = 0; i < location.length; i++) {
//     let a = {};
//     a.lat = location[i].latitude;
//     a.lng = location[i].longitude;
//     avglat = avglat + location[i].latitude;

//     avglng = avglng + location[i].longitude;
//     paths.push(a);
//   }
//   avglat = avglat / location.length;
//   avglng = avglng / location.length;
//   var Styles = fullWidth
//     ? { height: "62vh", width: "100%", marginBottom: 5 }
//     : { height: "30vh", width: "100%", marginBottom: 5 };
//   return (
//     <div style={Styles}>
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: key }}
//         defaultCenter={{ lat: avglat, lng: avglng }}
//         zoom={15}
//         fullscreenControl={false}
//         disableDefaultUI={true}
//         yesIWantToUseGoogleMapApiInternals
//         onGoogleApiLoaded={({ map, maps }) => {
//           var boundss = new maps.LatLngBounds();

//           var markers = location.map(function (l, i) {
//             const { latitude: lat, longitude: lng, address, updatedAt } = l;
//             let t = getcurrentTime(updatedAt);
//             const infowindow = new maps.InfoWindow({
//               content: makeStringContainer(address, updatedAt, severity[i]),
//             });

//             /**
//              * Use to Show Pins on Map
//              */

//             let mark = new maps.Marker({
//               position: { lat, lng },
//               map,
//               icon:
//                 severity[i] === "Normal"
//                   ? greenM
//                   : severity[i] === "Caution"
//                   ? yellowM
//                   : severity[i] === "Warning"
//                   ? brownM
//                   : redM,
//               //  label: "A",
//               // title: "Last updated " + t + "\n" + address,
//               animation: google.maps.Animation.DROP,
//             });
//             infowindow.open(map, mark);
//             let loc = new maps.LatLng(mark.position.lat(), mark.position.lng());
//             boundss.extend(loc);

//             map.fitBounds(boundss);
//             map.panToBounds(boundss);
//             /**
//              * Use to Show Info of Particular Mark
//              */
//             mark.addListener("click", () => {
//               infowindow.open(map, mark);
//             });
//             return mark;
//           });

//           /**
//            * Use to Show Cluster of points on Map
//            */
//           //  var markerCluster = new MarkerClusterer(map, markers, {
//           //  imagePath:
//           //   "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
//           // });

//           /**
//            * Use to Show Path
//            */
//           // const directionsService = new maps.DirectionsService();
//           // const directionsRenderer = new maps.DirectionsRenderer();
//           // calculateAndDisplayRoute(
//           //  directionsService,
//           // directionsRenderer,
//           // location
//           // );
//         }}
//       ></GoogleMapReact>
//     </div>
//   );
// };

// export default DisplayingLocation;
