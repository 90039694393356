import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

type SelectingDataProps = {
  onMenuIconPress(): void;
};

class SelectingData extends Component<SelectingDataProps> {
  state = {};
  render() {
    return (
      <div className="bg-white pl-2 pr-2 border">
        <div className="d-flex w-100">
          <div className="w-10">
            <IconButton
              className="mr-3 chat-sidebar-toggler d-lg-none d-xl-none d-md-block d-sm-block d-xs-block"
              color="inherit"
              aria-label="open drawer"
              onClick={this.props.onMenuIconPress}
            >
              <MenuIcon color="primary" />
            </IconButton>
          </div>
          <h2 className=" w-90 text-center p-2">Please Select User</h2>
        </div>
      </div>
    );
  }
}

export default SelectingData;
