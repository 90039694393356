/**
 * Sign Up With Firebase
 */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
const { Link } = require("react-router-dom");
import { Form, FormGroup, Input } from "reactstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import QueueAnim from "rc-queue-anim";
import { Fab } from "@material-ui/core";
import { NotificationManager } from "react-notifications";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import firebase from "firebase";
import "firebase/auth";

// components
const { SessionSlider } = require("Components/Widgets");

// app config
const AppConfig = require("Constants/AppConfig");

// redux action
const {
  signupAdminInFirebase,
  signinUserWithFacebook,
  signinUserWithGoogle,
  signinUserWithGithub,
  signinUserWithTwitter,
} = require("Actions");

interface SignupFirebaseState {
  errors: { [key: string]: string };
  formdata: { [key: string]: string };
  status?: string;
  phone?: string;
}

type SignupFirebaseProps = {
  signupAdminInFirebase(arg1: { [key: string]: string }, arg2: any): void;
  signinUserInFirebase(arg1: { [key: string]: string }, arg2: any): void;
  loading: any;
  count: any;
  history: any;
};

class SignupFirebase extends Component<
  SignupFirebaseProps,
  SignupFirebaseState
> {
  state = {
    formdata: {
      name: "",
      email: "",
      password: "",
      phone: "91",
      companyURL: "",
      role: "Admin",
    },
    errors: {
      name: "",
      email: "",
      password: "",
      companyURL: "",
    },
    phone: "",
  };

  //validating input on onChange
  validateInput = (e) => {
    switch (e.currentTarget.name) {
      case "name":
        if (!e.currentTarget.value.trim()) return "*Name is required";
        break;
      case "email":
        if (!e.currentTarget.value.trim()) return "*Email is required";
        else if (
          !/^([A-Za-z0-9.]+)@([A-Za-z0-9]+)\.([a-z]{3})$/.test(
            e.currentTarget.value
          )
        )
          return "*not a valid email";
        break;
      case "phone":
        if (!e.currentTarget.value.trim()) return "*Contact no. is required";
        else if (!/^\d{12}$/.test(e.currentTarget.value))
          return "*should be 10 digit number";
        break;
      case "companyURL":
        if (!e.currentTarget.value.trim()) return "*URL is required";
        else if (
          !/^htt(p|ps):\/\/([a-z]{3}\.)?([a-z0-9]+)\.([a-z]{3})$/.test(
            e.currentTarget.value
          )
        )
          return "*not a valid url";
        break;
      case "password":
        if (!e.currentTarget.value.trim()) return "*Password is required";
        else if (e.currentTarget.value.trim().length < 5)
          return "*Password should have at least 5 characters";
        break;
      default:
        break;
    }
    return "";
  };

  //validating input for making sign-up button to work
  validate = () => {
    const { formdata } = this.state;
    let errs: { [key: string]: any } = {};
    if (!formdata.name.trim()) errs.name = "*Name is required";
    if (!formdata.email.trim()) errs.email = "*Email is required";
    else if (
      !/^([A-Za-z0-9.]+)@([A-Za-z0-9]+)\.([a-z]{3})$/.test(formdata.email)
    )
      errs.email = "*not a valid email";
    if (!formdata.phone.trim()) errs.phone = "*Contact no. is required";
    else if (!/^\d{12}$/.test(formdata.phone))
      errs.phone = "*should be 10 digit number";
    if (!formdata.companyURL.trim()) errs.companyURL = "*URL is required";
    else if (
      !/^htt(p|ps):\/\/([a-z]{3}\.)?([a-z0-9]+)\.([a-z]{3})$/.test(
        formdata.companyURL
      )
    )
      errs.companyURL = "*not a valid url";
    if (!formdata.password.trim()) errs.passowrd = "*Password is required";
    else if (formdata.password.trim().length < 5)
      errs.passowrd = "*Password should have at least 5 characters";
    return errs;
  };

  //handling input fields
  handleChange = (e) => {
    let errString = this.validateInput(e);
    const data = { ...this.state.formdata };
    let errors = { ...this.state.errors };
    errors[e.currentTarget.name] = errString;
    data[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ formdata: data, errors });
  };

  handlephoneinput = (e) => {
    console.log(e);
    this.setState({
      formdata: {
        ...this.state.formdata,
        phone: e,
      },
    });
  };

  isFormInvalid = () => {
    let errs = this.validate();
    let errCount = Object.keys(errs).length;
    return errCount > 0;
  };
  /**
   * On User Signup
   */
  onUserSignUp() {
    // console.log("Mrinal");
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   "recaptcha-container"
    // );
    /*if (this.state.formdata.name != "") {
      const applicationVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container"
      );

      const phoneNumber = `+${this.state.formdata.phone}`;
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, applicationVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          var verificationCode = window.prompt(
            "Please enter the verification " +
              "code that was sent to your mobile device."
          );
          console.log(verificationCode);
          confirmationResult
            .confirm(verificationCode)
            .then((result) => {
              // User signed in successfully.
              var user = result.user;
              console.log("successfull", user);*/
    const {
      name,
      email,
      password,
      phone,
      companyURL,
      role,
    } = this.state.formdata;

    if (
      name !== "" &&
      email !== "" &&
      password !== "" &&
      phone !== "" &&
      companyURL !== ""
    ) {
      this.props.signupAdminInFirebase(
        { name, email, password, phone, companyURL, role },
        this.props.history
      );
    }

    // ...
    /*.catch(function (error) {
            })
              // User couldn't sign in (bad verification code?)
              // ...
              console.log("invalid OTP", error);
              NotificationManager.error("invalid OTP!");
            });
        })
        .catch(function (error) {
          console.log("sms not send----", error);
          NotificationManager.error("sms not send");
          location.reload();
        });
    }*/
  }

  render() {
    const { formdata, errors } = this.state;
    const { name, email, password, phone, companyURL } = formdata;
    const { loading } = this.props;
    return (
      <QueueAnim type="bottom" duration={2000}>
        <div
          style={{
            backgroundColor: "#6b79ff",
            position: "absolute",
            width: "100%",
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          className=""
        >
          {loading && <LinearProgress />}
          <AppBar position="static" className="session-header">
            <Toolbar>
              <div className="container mt-2">
                <div className="d-flex justify-content-between">
                  <div className="session-logo">
                    <Link to="/">
                      <img
                        src="https://i.ibb.co/8gsVdKL/white-logo.png"
                        alt="session-logo"
                        width="40"
                        height="40"
                      />
                      &nbsp; &nbsp;
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: 500,
                          color: "white",
                          fontFamily: `"Comic Sans MS", cursive, sans-serif`,
                        }}
                      >
                        {AppConfig.default.brandName}
                      </span>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="/signin"
                      className="d-none d-md-inline-block mr-15 text-white"
                    >
                      Already have an account?
                    </Link>
                    <Button
                      component={Link}
                      to="/signin"
                      variant="contained"
                      className="btn-light"
                    >
                      Sign In
                    </Button>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <br />
          <div className="session-inner-wrapper">
            <div className="container">
              <div className="row row-eq-height">
                <div className="d-none d-sm-inline-block col-sm-1 col-md-2"></div>
                <div
                  style={{
                    backgroundColor: "white",
                    boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.3)",
                    borderRadius: "4px",
                  }}
                  className="col-12 col-sm-10 col-md-8"
                >
                  <div className="session-body text-center">
                    <br />
                    <div className="session-head mb-15">
                      <h2>Get started with {AppConfig.default.brandName}</h2>
                    </div>
                    <Form>
                      <FormGroup className="has-wrapper">
                        <Input
                          type="text"
                          value={name}
                          name="name"
                          id="user-name"
                          className="has-input input-lg border"
                          placeholder="Enter Your Name"
                          onChange={this.handleChange}
                        />
                        <span className="has-icon">
                          <i className="ti-user"></i>
                        </span>
                        {errors.name ? (
                          <div
                            style={{ fontSize: "13px" }}
                            className="text-left text-danger ml-2"
                          >
                            {errors.name}
                          </div>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                      <FormGroup className="has-wrapper">
                        <Input
                          type="mail"
                          value={email}
                          name="email"
                          id="user-mail"
                          className="has-input input-lg border"
                          placeholder="Enter Email Address"
                          onChange={this.handleChange}
                        />
                        <span className="has-icon">
                          <i className="ti-email"></i>
                        </span>
                        {errors.email ? (
                          <div
                            style={{ fontSize: "13px" }}
                            className="text-left text-danger ml-2"
                          >
                            {errors.email}
                          </div>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                      <FormGroup className="has-wrapper">
                        <Input
                          type="text"
                          value={companyURL}
                          name="companyURL"
                          id="companyURL"
                          className="has-input input-lg border"
                          placeholder="Enter Company URL"
                          onChange={this.handleChange}
                        />
                        <span className="has-icon">
                          <i className="ti-world"></i>
                        </span>
                        {errors.companyURL ? (
                          <div
                            style={{ fontSize: "13px" }}
                            className="text-left text-danger ml-2"
                          >
                            {errors.companyURL}
                          </div>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                      <FormGroup className="has-wrapper">
                        <Input
                          value={password}
                          type="Password"
                          name="password"
                          id="pwd"
                          className="has-input input-lg border"
                          placeholder="Password"
                          onChange={this.handleChange}
                        />
                        <span className="has-icon">
                          <i className="ti-lock"></i>
                        </span>
                        {errors.password ? (
                          <div
                            style={{ fontSize: "13px" }}
                            className="text-left text-danger ml-2"
                          >
                            {errors.password}
                          </div>
                        ) : (
                          ""
                        )}
                      </FormGroup>
                      <FormGroup className="has-wrapper">
                        <PhoneInput
                          isValid={(value, country: any) => {
                            if (value.match(/12345/)) {
                              return (
                                "Invalid value: " + value + ", " + country.name
                              );
                            } else if (value.match(/1234/)) {
                              return false;
                            } else if (value.length < 12 && value.length > 3) {
                              return "Enter a valid number";
                            } else {
                              return true;
                            }
                          }}
                          placeholder="Enter Phone Number"
                          inputStyle={{
                            width: "100%",
                            height: "55px",
                            border: "1px solid #eee",
                          }}
                          inputProps={{
                            name: "phone",
                            required: true,
                          }}
                          value={phone}
                          // placeholder="Enter Your Phone Number"
                          onChange={this.handlephoneinput}
                        />
                        <span className="has-icon">
                          <i className="ti-mobile"></i>
                        </span>
                        {/* {errors.phone ? (
                          <div
                            style={{ fontSize: "13px" }}
                            className="text-left text-danger ml-2"
                          >
                            {errors.phone}
                          </div>
                        ) : (
                          ""
                        )} */}
                        <div id="recaptcha-container"></div>
                      </FormGroup>
                      <FormGroup className="mb-15">
                        <Button
                          className="btn btn-info text-white btn-block w-100"
                          onClick={() => this.onUserSignUp()}
                          disabled={this.isFormInvalid()}
                        >
                          Sign Up
                        </Button>
                      </FormGroup>
                    </Form>
                    <p>
                      <Link to="/terms-condition" className="text-muted">
                        Terms of Service
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="d-none d-sm-inline-block col-sm-1 col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </QueueAnim>
    );
  }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
  const { loading } = authUser;
  return { loading };
};

export default connect(mapStateToProps, {
  signupAdminInFirebase,
  signinUserWithFacebook,
  signinUserWithGoogle,
  signinUserWithGithub,
  signinUserWithTwitter,
})(SignupFirebase);
