/**
import { apiURL } from './URL';
 * URL Config File
 */

// const baseURL = "https://us-central1-familytracker-5fe23.cloudfunctions.net";

// export const baseURL =
//   "https://us-central1-familytracker-5fe23.cloudfunctions.net";

const baseURL = "https://us-central1-familytracker-5fe23.cloudfunctions.net";

// export const baseURL =
//     "http://localhost:5001/family-tracker-5bbb6/us-central1";

// export const apiURL =
// "http://localhost:5001/familytracker-5fe23/us-central1/api";

export const apiURL = baseURL + "/api";
export const invitefamilymemberurl = baseURL + "/inviteFamilyMember";
