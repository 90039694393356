/**
 * User Profile Page
 */
import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

// Components
import Profile from "./component/Profile";
import EmailPrefrences from "./component/EmailPrefrences";
import Messages from "./component/Messages";
import Address from "./component/Address";
import UserBlock from "./component/UserBlock";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import { apiURL } from "../../../constants/URL";
import SweetAlert from "react-bootstrap-sweetalert";

// redux action
import { logoutUserFromFirebase } from "Actions";

// const { logoutUserFromFirebase } = require('Actions');

// rct card box
import { RctCard } from "Components/RctCard";

// const { RctCard } = require('Components/RctCard');

// page title bar
import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
// const PageTitleBar = require('Components/PageTitleBar/PageTitleBar')

// intl messages
import IntlMessages from "Util/IntlMessages";
import firebase from "firebase";
// const IntlMessages = require('Util/IntlMessages');

type Anyobj = {
  [key: string]: any;
};

type Indexprops = {
  location: any;
  logoutUserFromFirebase(arg0?: any): void;
  match: any;
};

type AppState = {
  formdata: any;
  activeTab: any;
  errors: Anyobj;
  loading: boolean;
  success: boolean;
  emailVerified: boolean;
};

// For Tab Content
function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class UserProfile extends Component<Indexprops, AppState> {
  _isMounted = false;

  constructor(props: Indexprops) {
    super(props);
    this.state = {
      activeTab: this.props.location.state
        ? this.props.location.state.activeTab
        : 0,
      formdata: {
        name: "",
        phone: "+91",
        email: "",
        userID: "",
        locations: "",
      },
      errors: {},
      loading: false,
      success: false,
      emailVerified: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getUserDetails();
    this.getallDetails();
  }

  getUserDetails = () => {
    firebase.auth().onAuthStateChanged((user: any) => {
      if (user.emailVerified) {
        console.log("Email is verified");
        this.setState({ activeTab: 0, emailVerified: true });
      } else {
        console.log("Email is not verified");
        this.setState({ activeTab: 1, emailVerified: false });
      }
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({ loading: true });
  }

  getallDetails = () => {
    let userId = sessionStorage.getItem("user_id") || "";
    let { formdata } = this.state;
    let UserRef = firebase.database().ref("Users/" + userId);
    UserRef.on(
      "value",
      (userSnap) => {
        console.log("The details of the user is", userSnap.val());
        let details = userSnap.val();
        formdata["locations"] = details.locations;
        formdata["name"] = details.profile.fullname;
        formdata["email"] = details.profile.email;
        formdata["phone"] = details.profile.phoneNumber;
        this.setState({ formdata, loading: false });
      },
      (error) => {
        console.log("Error while fetching users from tracking -- ", error.code);
        this.setState({
          formdata: {
            name: sessionStorage.getItem("username"),
            email: sessionStorage.getItem("email"),
            phone: sessionStorage.getItem("phone"),
            // userID = sessionStorage.getItem("user_id"),
          },
          loading: false,
        });
      }
    );
  };

  validateInput = (e) => {
    switch (e.currentTarget.name) {
      case "name":
        if (!e.currentTarget.value.trim()) return "*Name is required";
        break;
      case "email":
        if (!e.currentTarget.value.trim()) return "*Email is required";
        else if (
          !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            e.currentTarget.value
          )
        )
          return "*not a valid email";
        break;
      case "phone":
        if (!e.currentTarget.value.trim()) return "*Contact no. is required";
        //else if (!/^\d{12}$/.test(e.currentTarget.value))
        else if (
          !/^\+?([0-9]{1,4})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{5})$/.test(
            e.currentTarget.value
          )
        )
          return "*not a valid phone number";
        break;
      default:
        break;
    }
    return "";
  };

  handleInputChange = (e) => {
    const { target: input } = e;
    let errString = this.validateInput(e);
    const { formdata } = this.state;
    const data = { ...formdata };
    let errors = { ...this.state.errors };
    errors[input.name] = errString;
    data[input.name] = input.value;

    this.setState({
      formdata: data,
      errors,
    });
  };

  handlePhoneInputChange = (value) => {
    this.setState({
      formdata: {
        ...this.state.formdata,
        phone: value,
      },
    });
  };

  handleChange = (event, value) => {
    this.setState({ activeTab: value });
  };

  /**
   * On Update Profile
   */
  handleUpdateProfile = () => {
    console.log(this.state.formdata);
    let userid = sessionStorage.getItem("user_id");
    // New way
    this.setState({ loading: true });
    let data = { ...this.state.formdata };
    axios
      .put(apiURL + `/${userid}/updateprofile`, {
        name: data.name,
        phone: data.phone,
        email: data.email,
      })
      .then((response) => {
        console.log(
          "Username-- " +
            data.name +
            " UserID-- " +
            data.userID +
            " Contact-- " +
            data.phone +
            " Email-- " +
            data.email
        );
        if (response.data.code) {
          this.setState({ loading: false });
          console.log("error is --->", response.data);
          NotificationManager.error(response.data.message);
        } else {
          sessionStorage.setItem("username", response.data.displayName);
          sessionStorage.setItem("email", response.data.email);
          sessionStorage.setItem("phone", response.data.phoneNumber);
          console.log(response);
          console.log("Successfully updated user in auth and database ...");
          // NotificationManager.success("Details updated successfully!");
          this.setState({ loading: false, success: true });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("error is --->", error);
        NotificationManager.error(error.message);
      });
  };

  onConfirm = () => {
    this.setState({ success: false });
    this.props.logoutUserFromFirebase();
  };

  onUpdateAddress = (Address) => {
    console.log("the new adresses are", Address);
    let userid = sessionStorage.getItem("user_id");
    let LocationRef = firebase.database().ref("Users/").child(`${userid}`);
    LocationRef.update({ locations: Address })
      .then((result) => {
        console.log("address successfully updated", result);
      })
      .catch((error) => {
        console.log("error while updating address is", error);
      });
  };

  render() {
    const { activeTab, formdata, errors, loading, success, emailVerified } =
      this.state;
    return (
      <div className="userProfile-wrapper">
        <SweetAlert
          success
          show={success}
          title="Profile Updated!"
          btnSize="sm"
          onConfirm={() => this.onConfirm()}
        >
          Please login again.
        </SweetAlert>

        {/* {loading && <LinearProgress />} */}
        <Helmet>
          <title>User Profile</title>
          <meta name="description" content="User Profile" />
        </Helmet>
        <PageTitleBar
          title={<IntlMessages id="sidebar.userProfile" />}
          match={this.props.match}
        />
        <RctCard>
          <UserBlock formdata={formdata} />
          <div className="rct-tabs">
            <AppBar position="static">
              <Tabs
                value={activeTab}
                onChange={this.handleChange}
                variant="scrollable"
                scrollButtons="off"
                indicatorColor="primary"
              >
                <Tab
                  icon={<i className="ti-user"></i>}
                  label={<IntlMessages id="components.myProfile" />}
                />
                <Tab
                  icon={<i className="ti-email"></i>}
                  label="Email Verification"
                />
                <Tab
                  icon={<i className="ti-comment-alt"></i>}
                  label={<IntlMessages id="widgets.messages" />}
                />
                <Tab
                  icon={<i className="ti-home"></i>}
                  label={<IntlMessages id="components.address" />}
                />
              </Tabs>
            </AppBar>
            {activeTab === 0 && (
              <TabContainer>
                <Profile
                  formdata={formdata}
                  errors={errors}
                  loading={loading}
                  onInputChange={this.handleInputChange}
                  onPhoneInputChange={this.handlePhoneInputChange}
                  onUpdateProfile={this.handleUpdateProfile}
                  logoutUserFromFirebase={this.props.logoutUserFromFirebase}
                />
              </TabContainer>
            )}
            {activeTab === 1 && (
              <TabContainer>
                <EmailPrefrences emailVerified={emailVerified} />
              </TabContainer>
            )}
            {activeTab === 2 && (
              <TabContainer>
                <Messages />
              </TabContainer>
            )}
            {activeTab === 3 && (
              <TabContainer>
                <Address
                  formdata={formdata}
                  updateAddress={this.onUpdateAddress}
                />
              </TabContainer>
            )}
          </div>
        </RctCard>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  return settings;
};

export default connect(mapStateToProps, {
  logoutUserFromFirebase,
})(UserProfile);

// export default UserProfile;
