import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import RctPageLoader from "../../../components/RctPageLoader/RctPageLoader";

type Anyobj = {
  [key: string]: any;
};

type EmployeesListprops = {
  searchedInput: string;
  employees: Anyobj[];
  serverstatus: boolean;
  onEditIconClick(any: Anyobj): void;
  onDeleteIconClick(any: Anyobj): void;
  onShowEmployeeDetails(any: Anyobj): void;
};

class EmployeesList extends Component<EmployeesListprops> {
  // state = {};
  render() {
    const { employees, serverstatus, searchedInput } = this.props;
    // let adminID = localStorage.getItem("user_id");
    let adminID = sessionStorage.getItem("user_id");
    if (employees.length === 0 && !serverstatus) {
      return (
        <div style={{ position: "absolute", top: "45%", left: "45%" }}>
          <RctPageLoader />
        </div>
      );
    }
    if (employees.length === 0 && serverstatus) {
      return (
        <div
          style={{
            position: "absolute",
            left: "35%",
            top: "45%",
            opacity: ".3",
            // fontWeight: "500",
            fontWeight: "bold",
            fontSize: "17px",
          }}
        >
          {searchedInput ? "No results found" : "No Employees found"}
        </div>
      );
    }
    return (
      <React.Fragment>
        {employees.length !== 0 && serverstatus ? (
          <List className="list-unstyled p-0">
            {employees.map((data: Anyobj, index: number) => (
              <ListItem
                button
                key={index}
                onClick={() => {
                  this.props.onShowEmployeeDetails(data);
                }}
              >
                <Avatar className="bg-primary mr-10">
                  <i className="zmdi zmdi-account-circle zmdi-hc-lg"></i>
                </Avatar>
                <ListItemText
                  className="mr-30"
                  primary={
                    <div>
                      <div className="font-weight-bold">
                        {data.details.name}
                      </div>
                      <div>
                        <span>{data.details.role}&nbsp; </span>
                        {data.key === adminID && (
                          <Chip
                            className="chip-outline-primary mr-1 mb-1"
                            label="it's you"
                          />
                        )}
                      </div>
                      <div>{`+${data.details.phoneNumber}`}</div>
                    </div>
                  }
                ></ListItemText>
                <ListItemSecondaryAction className="ml-10">
                  <IconButton
                    aria-label="Edit"
                    onClick={() => this.props.onEditIconClick(data)}
                  >
                    <Tooltip title="Edit" placement="bottom">
                      <i className="zmdi zmdi-edit text-primary"></i>
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      //   this.ondelete(data.name);
                      this.props.onDeleteIconClick(data);
                    }}
                    aria-label="Delete"
                    disabled={data.key === adminID}
                  >
                    <i className="zmdi zmdi-delete text-primary"></i>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default EmployeesList;
