/**
 * Notification Component
 */
import React, { Component } from "react";
import DisplayingLocation from "./displayMap";
import googlemap from "../../../routes/maps/google-map";
import { connect } from "react-redux";
import RctSectionLoader from "../../RctSectionLoader/RctSectionLoader";

// // actions
import {
  chatWithSelectedUser,
  getRecentChatUsers,
  getFamilyMembers,
  getMemberHistory,
} from "Actions";

// const {
//   chatWithSelectedUser,
//   getRecentChatUsers,
//   getFamilyMembers,
//   getMemberHistory,
// } = require("Actions");

type LocationMapProps = {
  histroyDetails?: any;
  severity?: any;
  fullWidth?: any;
};

declare namespace JSX {
  interface IntrinsicElements {
    LocationMap: { [key: string]: any };
  }
}

class LocationMap extends Component<LocationMapProps> {
  render() {
    let {
      histroyDetails: selectedMemberHistory,
      severity,
      fullWidth,
    } = this.props;

    return (
      <DisplayingLocation
        fullWidth={fullWidth}
        severity={severity}
        location={selectedMemberHistory}
      />
      // <googlemap />
    );
  }
}

const mapStateToProps = ({ chatAppReducer, family, memberHistory }) => {
  const { selectedMember } = family;
  const { memberHistoryData, loading } = memberHistory;
  let histroyDetails =
    selectedMember && memberHistoryData
      ? memberHistoryData.map((member) => member.location)
      : [];
  let severity =
    selectedMember && memberHistoryData
      ? memberHistoryData.map((member) => member.severity)
      : [];
  console.log("===================================");
  console.log(severity);
  console.log(histroyDetails);
  console.log("===================================");
  return { histroyDetails, severity };
};

export default connect(mapStateToProps, {
  chatWithSelectedUser,
  getRecentChatUsers,
  getFamilyMembers,
  getMemberHistory,
})(LocationMap);
