import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import Helmet from "react-helmet";

import EmployeeListPanel from "./components/EmployeeListPanel";

//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

const drawerWidth = 480;

export class UserDetails {
  role?: string;
  trackingPermission: boolean;
  name: string;
  phoneNumber: string;
  email: string;

  constructor() {
    this.role = "";
    this.trackingPermission = false;
    this.name = "";
    this.phoneNumber = "";
    this.email = "";
  }
}

type PropType = {
  classes: any;
  theme: any;
};

const styles = (theme) =>
  ({
    root: {
      flexGrow: 1,
      zIndex: 1,
      overflow: "hidden",
      position: "relative",
      display: "flex",
      width: "100%",
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: "90%",
      [theme.breakpoints.up("md")]: {
        position: "relative",
        width: drawerWidth,
      },
      backgroundColor: "#fff",
    },
    content: {
      flexGrow: 1,
    },
  } as const);

class EmployeePage extends Component<PropType> {
  state = {
    mobileOpen: false,
    domain: "",
    formdata: {
      name: "",
      role: "",
      phone: "",
      email: "",
      userID: "",
      search: "",
    },
    initialEmployeeList: [],
    employeeList: [],
    open: false,
    status: "",
    errors: {},
    serverstatus: false,
  };

  componentDidMount() {
    console.log("Here in invitation block -- ");
    this.getAllInvitationsForUser();
  }

  getAllInvitationsForUser = () => {
    let userId = sessionStorage.getItem("user_id") || "";
    console.log("User ID is --" + userId);
    let domainRef = firebase.database().ref("Permissions/");
    domainRef.child(userId).on(
      "value",
      (usersSnap) => {
        let result = { ...usersSnap.val() };
        if (result !== null) {
          console.log("List of all the users in permission section -- ", result);
          this.getInvitersDetails(result);
        } else {
          this.setState({
            serverstatus: true,
            initialEmployeeList: [],
            employeeList: [],
            status: "",
            open: false,
          });
        }
      },
      (error) => {
        console.log("Error occured -- ", error.code);
      }
    );
  };

  getInvitersDetails = (users) => {
    console.log("Inviters List -->", users);
    let tempEmployeeList: any = [];
    for (let key in users) {
      console.log(key, users[key]);
      let usersRef = firebase.database().ref("Users/" + key + "/profile");
      usersRef.on(
        "value",
        (profileSnap) => {
          let profile = profileSnap.val();
          let details = new UserDetails();
          details.role = "NotRequired";
          details.name = profile
            ? profile.fullname
              ? profile.fullname
              : "Unknown"
            : "Unknown";
          details.phoneNumber = profile
            ? profile.phoneNumber
              ? profile.phoneNumber
              : "NA"
            : "NA";
          details.email = profile
            ? profile.email
              ? profile.email
              : "NA"
            : "NA";
          details.trackingPermission = users[key];
          let tempObj = { key, details };
          var index = tempEmployeeList
            .map(function (e: any) {
              return e.key;
            })
            .indexOf(key);
          if (index < 0) tempEmployeeList.push(tempObj);
          else tempEmployeeList[index] = tempObj;
          if (Object.keys(users).length === tempEmployeeList.length) {
            console.log("Members list is --", tempEmployeeList);
            this.setState({
              serverstatus: true,
              initialEmployeeList: tempEmployeeList,
              employeeList: tempEmployeeList,
              status: "",
              open: false,
            });
          }
        },
        (error) => {
          console.log("Could not fetch the friends and family -- ", error.code);
        }
      );
    }
    this.setState({
      serverstatus: true,
    });
  };

  validateInput = (e) => {
    switch (e.currentTarget.name) {
      case "name":
        if (!e.currentTarget.value.trim()) return "*Name is required";
        break;
      case "email":
        if (!e.currentTarget.value.trim()) return "*Email is required";
        else if (
          !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            e.currentTarget.value
          )
        )
          return "*not a valid email";
        break;
      case "phone":
        if (!e.currentTarget.value.trim()) return "*Contact no. is required";
        else if (!/^\d{10}$/.test(e.currentTarget.value))
          return "*should be 10 digit number";
        break;
      case "role":
        if (!e.currentTarget.value.trim()) return "*required";
        break;
      default:
        break;
    }
    return "";
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleInputChange = (e) => {
    console.log(e);
    const { target: input } = e;
    let errString = this.validateInput(e);
    const { formdata, initialEmployeeList } = this.state;
    const data = { ...formdata };
    let errors = { ...this.state.errors };
    errors[input.name] = errString;
    data[input.name] = input.value;
    let updatedList = [...initialEmployeeList];
    if (input.name === "search") {
      updatedList = updatedList.filter(function (item: any) {
        let item1 = item.details.name;
        return item1.toLowerCase().search(input.value.toLowerCase()) !== -1;
      });
    }
    console.log("Editing -----" + input.name);
    this.setState({ formdata: data, errors, employeeList: updatedList });
  };

  handlePhoneInputChange = (value) => {
    this.setState({
      formdata: {
        ...this.state.formdata,
        phone: value,
      },
    });
  };

  handleClose = () => {
    this.setState({ open: false, status: "" });
  };

  onEditIconClick = (data: { key: string, details: UserDetails }) => {
    const { name, phoneNumber, role, email } = data.details;
    this.setState({
      open: true,
      status: "edit",
      formdata: {
        name: name,
        phone: phoneNumber,
        role: role,
        userID: data.key,
        email: email,
        search: "",
      },
    });
  };

  onInviteIconClick = () => {
    this.setState({
      open: true,
      status: "invite",
      formdata: {
        name: "",
        role: "",
        phone: "91",
        email: "",
        userID: "",
        search: "",
      },
    });
  };

  onDeleteIconClick = (data: { key: string, details: UserDetails }) => {
    const { name, phoneNumber, role, email } = data.details;
    this.setState({
      open: true,
      status: "delete",
      formdata: {
        name: name,
        phone: phoneNumber,
        role: role,
        userID: data.key,
        email: email,
        search: "",
      },
    });
  };

  render() {
    const { classes, theme } = this.props;
    const {
      open,
      formdata,
      status,
      errors,
      serverstatus,
      employeeList,
      mobileOpen,
    } = this.state;
    console.log(employeeList, serverstatus);
    const drawer = (
      <div>
        <EmployeeListPanel
          formdata={formdata}
          status={status}
          serverstatus={serverstatus}
          errors={errors}
          open={open}
          employees={employeeList}
          onInviteIconClick={this.onInviteIconClick}
          onDeleteIconClick={this.onDeleteIconClick}
          onEditIconClick={this.onEditIconClick}
          onInputChange={this.handleInputChange}
          onPhoneInputChange={this.handlePhoneInputChange}
          onClose={this.handleClose}
        />
      </div>
    );
    return (
      <div className="blank-wrapper">
        <Helmet>
          <title>Employee Lists</title>
          <meta name="description" content="Reactify Blank Page" />
        </Helmet>
        <div>
          <div className={classes.root}>
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                variant="permanent"
                open
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(EmployeePage);
