import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

function getSteps() {
   return ['Select campaign settings', 'Create an ad group', 'Create an ad'];
}

function getStepContent(step) {
   switch (step) {
      case 0:
         return 'Step 1: Select campaign settings...';
      case 1:
         return 'Step 2: What is an ad group anyways?';
      case 2:
         return 'Step 3: This is the bit I really care about!';
      default:
         return 'Unknown step';
   }
}

export default class NonLinearAlternativeLabel extends React.Component {
   state = {
      activeStep: 0,
      completed: new Set(),
      skipped: new Set(),
   };

   totalSteps = () => {
      return getSteps().length;
   };

   isStepComplete(step) {
      return this.state.completed.has(step);
   }

   completedSteps() {
      return this.state.completed.size;
   }

   allStepsCompleted() {
      return this.completedSteps() === this.totalSteps() - this.skippedSteps();
   }

   isLastStep() {
      return this.state.activeStep === this.totalSteps() - 1;
   }

   isStepOptional = step => {
      return step === 1;
   };

   isStepSkipped(step) {
      return this.state.skipped.has(step);
   }

   handleSkip = () => {
      const { activeStep } = this.state;
      if (!this.isStepOptional(activeStep)) {
         // You probably want to guard against something like this
         // it should never occur unless someone's actively trying to break something.
         throw new Error("You can't skip a step that isn't optional.");
      }
      const skipped = new Set(this.state.skipped);
      skipped.add(activeStep);
      this.setState({
         activeStep: this.state.activeStep + 1,
         skipped,
      });
   };

   skippedSteps() {
      return this.state.skipped.size;
   }

   handleNext = () => {
      let activeStep;

      if (this.isLastStep() && !this.allStepsCompleted()) {
         // It's the last step, but not all steps have been completed
         // find the first step that has been completed
         const steps = getSteps();
         activeStep = steps.findIndex((step, i) => !this.state.completed.has(i));
      } else {
         activeStep = this.state.activeStep + 1;
      }
      this.setState({
         activeStep,
      });
   };

   handleBack = () => {
      this.setState({
         activeStep: this.state.activeStep - 1,
      });
   };

   handleStep = step => () => {
      this.setState({
         activeStep: step,
      });
   };

   handleComplete = () => {
      const completed = new Set(this.state.completed);
      completed.add(this.state.activeStep);
      this.setState({
         completed,
      });
      /**
       * Sigh... it would be much nicer to replace the following if conditional with
       * `if (!this.allStepsComplete())` however state is not set when we do this,
       * thus we have to resort to not being very DRY.
       */
      if (completed.size !== this.totalSteps() - this.skippedSteps()) {
         this.handleNext();
      }
   };

   handleReset = () => {
      this.setState({
         activeStep: 0,
         completed: new Set(),
         skipped: new Set(),
      });
   };

   render() {
      const { classes } = this.props;
      const steps = getSteps();
      const { activeStep } = this.state;

      return (
         <div>
            <Stepper alternativeLabel nonLinear activeStep={activeStep} className="stepper-rtl">
               {steps.map((label, index) => {
                  const props = {};
                  const buttonProps = {};
                  if (this.isStepOptional(index)) {
                     buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                  }
                  if (this.isStepSkipped(index)) {
                     props.completed = false;
                  }
                  return (
                     <Step key={label} {...props}>
                        <StepButton onClick={this.handleStep(index)} completed={this.isStepComplete(index)} {...buttonProps}>
                           {label}
                        </StepButton>
                     </Step>
                  );
               })}
            </Stepper>
            <div>
               {this.allStepsCompleted() ? (
                  <div className="pl-40">
                     <p>All steps completed - you&quot;re finished</p>
                     <Button variant="contained" className="btn-success text-white" onClick={this.handleReset}>Reset</Button>
                  </div>
               ) : (
                     <div className="pl-40">
                        <p>{getStepContent(activeStep)}</p>
                        <Button variant="contained" className="btn-danger text-white mr-10 mb-10" disabled={activeStep === 0} onClick={this.handleBack} >
                           Back
              </Button>
                        <Button variant="contained" color="primary" className="text-white mr-10 mb-10" onClick={this.handleNext}>
                           Next
              </Button>
                        {this.isStepOptional(activeStep) &&
                           !this.state.completed.has(this.state.activeStep) && (
                              <Button variant="contained" className="btn-info text-white mr-10 mb-10" onClick={this.handleSkip}>
                                 Skip
                  </Button>
                           )}
                        {activeStep !== steps.length &&
                           (this.state.completed.has(this.state.activeStep) ? (
                              <Typography variant="caption" className={classes.completed}>
                                 Step {activeStep + 1} already completed
                  </Typography>
                           ) : (
                                 <Button variant="contained" color="primary" className="text-white mr-10 mb-10" onClick={this.handleComplete}>
                                    {this.completedSteps() === this.totalSteps() - 1 ? 'Finish' : 'Complete Step'}
                                 </Button>
                              ))}
                     </div>
                  )}
            </div>
         </div>
      );
   }
}
