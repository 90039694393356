/**
 * Recent Chat Users
 */
import React, { Component } from "react";
import List from "@material-ui/core/List";
import { connect } from "react-redux";
import moment from "moment";

// components
import UserListItem from "./UserListItem";

//actions
import {
  chatWithSelectedUser,
  getRecentChatUsers,
  getFamilyMembers,
  getMemberHistory,
  setSelectedFamilyMember,
  showMemberHistory,
} from "Actions";

// const {
//   chatWithSelectedUser,
//   getRecentChatUsers,
//   getFamilyMembers,
//   getMemberHistory,
//   setSelectedFamilyMember,
//   showMemberHistory,
// } = require("Actions");

type RecentChatUsersProps = {
  //
  getRecentChatUsers(): void;
  getFamilyMembers(): void;
  setSelectedFamilyMember(any): void;
  showMemberHistory(boolean): void;
  getMemberHistory(any): void;
  //
  recentChatUsers: any,
  selectedUser: any,
  members: any,
  loading: any,
  selectedMember: any,
  isShowMemberHistory: any
};

class RecentChatUsers extends Component<RecentChatUsersProps> {
  UNSAFE_componentWillMount() {
    this.fetchRecentChatUsers();
  }

  /**
   * Fetch Recent User
   */
  fetchRecentChatUsers() {
    this.props.getRecentChatUsers();
    this.props.getFamilyMembers();
  }

  /**
   * Swicth Chat With User
   * @param {*object} user
   */
  switchChatWithUser(user) {
    this.props.setSelectedFamilyMember(user);
    this.props.showMemberHistory(false);
    this.props.getMemberHistory(user.UID);
  }

  battery_image(level) {
    let imageUrl = "";
    if (level >= 0 && level < 10) {
      imageUrl = "ic_battery_alert.png";
    } else if (level >= 10 && level < 30) {
      imageUrl = "ic_battery_10.png";
    } else if (level >= 30 && level < 50) {
      imageUrl = "ic_battery_30.png";
    } else if (level >= 50 && level < 60) {
      imageUrl = "ic_battery_50.png";
    } else if (level >= 60 && level < 90) {
      imageUrl = "ic_battery_60.png";
    } else if (level >= 90 && level < 100) {
      imageUrl = "ic_battery_90.png";
    } else if (level == 100) {
      imageUrl = "ic_battery_full.png";
    } else {
      imageUrl = "ic_battery_unknown.png";
    }
    return imageUrl;
  }

  getcurrentTime(dateString) {
    let time = dateString
      ? moment(dateString).format("hh:mm a")
      : moment().format("hh:mm a");
    let daysAgo = dateString
      ? moment(dateString).fromNow()
      : moment().fromNow();

    return daysAgo;
  }

  showFamilyMemberHistory(user) {
    this.props.setSelectedFamilyMember(user);
    this.props.getMemberHistory(user.UID);
    this.props.showMemberHistory(true);
  }

  render() {
    const {
      recentChatUsers,
      selectedUser,
      members,
      loading,
      selectedMember,
    } = this.props;

    if (members === null || members.length === 0) {
      return (
        <div className="no-found-user-wrap p-3 text-center">
          <h4>No Member Found</h4>
        </div>
      );
    }
    return (
      <List className="p-0 mb-0">
        {members &&
          members.map((user, key) => (
            <UserListItem
              selectedMember={selectedMember}
              user={user}
              key={key}
              isShowMemberHistory={this.props.isShowMemberHistory}
              battery_image={this.battery_image(user.lastUpdate ? (user.lastUpdate.battery ? user.lastUpdate.battery : "") : "")}
              getcurrentTime={this.getcurrentTime}
              showFamilyMemberHistory={() => this.showFamilyMemberHistory(user)}
              onClickListItem={() => this.switchChatWithUser(user)}
            />
          ))}
      </List>
    );
  }
}

const mapStateToProps = ({ chatAppReducer, family }) => {
  const { recentChatUsers, selectedUser } = chatAppReducer;
  const { members, loading, selectedMember, isShowMemberHistory } = family;
  const count = members && members.length;

  console.log("count: ", count);
  return {
    recentChatUsers,
    selectedUser,
    members,
    loading,
    count,
    selectedMember,
    isShowMemberHistory,
  };
};

export default connect(mapStateToProps, {
  chatWithSelectedUser,
  getRecentChatUsers,
  getFamilyMembers,
  getMemberHistory,
  setSelectedFamilyMember,
  showMemberHistory,
})(RecentChatUsers);
