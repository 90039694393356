import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import { NotificationManager } from "react-notifications";
// components
import UsersDetails from "./components/UsersDetails";
import UsersSidebar from "./components/UsersSidebar";
//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import axios from "axios";
import { apiURL } from "./../../constants/URL";

const drawerWidth = 410;

const styles: {
  [key: string]: any;
} = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: 408,
    [theme.breakpoints.up("md")]: {
      position: "relative",
      width: drawerWidth,
    },
    backgroundColor: "#fff",
  },
  content: {
    flexGrow: 1,
  },
});

export interface UsersPageProps {
  classes: any;
  theme: any;
}

type AnyObj = {
  [key: string]: any;
};

export interface UsersPageState {
  mobileOpen: boolean;
  usersList: AnyObj[];
  initialUsersList: AnyObj[];
  serverstatus: boolean;
  open: boolean;
  selecteduser: AnyObj;
  searchedinput: string;
  deleteUser: AnyObj;
}

class UsersPage extends React.Component<UsersPageProps, UsersPageState> {
  state = {
    mobileOpen: false,
    usersList: [],
    initialUsersList: [],
    serverstatus: false,
    open: false,
    selecteduser: {},
    searchedinput: "",
    deleteUser: {},
  };

  componentDidMount() {
    let usersRef = firebase.database().ref("Users/");
    console.log("created usersref ....");
    usersRef.on(
      "value",
      (usersSnap) => {
        console.log(
          "Number of users are -->",
          usersSnap.val(),
          Object.keys(usersSnap.val()).length
        );
        let users = { ...usersSnap.val() };
        if (users !== null) {
          let usersList: any = [];
          for (let key in users) {
            let resultObj = {};
            let allowedDomains: any = [];
            if (users[key]["allow_domain"]) {
              allowedDomains.push(users[key]["allow_domain"]);
            }
            resultObj["key"] = key;
            resultObj["details"] = users[key];
            resultObj["details"]["allowedDomains"] = [...allowedDomains];
            usersList.push(resultObj);
          }
          this.setState({
            usersList,
            initialUsersList: usersList,
            serverstatus: true,
            open: false,
            selecteduser: {},
            deleteUser: {},
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleSearch = (e) => {
    let domainRef = firebase.database().ref("Users/");
    const { usersList, initialUsersList } = this.state;
    let updatedList = [...initialUsersList];
    // // domainRef.startAt(e.currentTarget.value).on("child_added", (snapshot) => {
    // //   console.log(snapshot.val());
    // // });
    updatedList = updatedList.filter((item: any) => {
      //console.log(item.details.profile);
      let item1 =
        item.details.profile && item.details.profile.phoneNumber
          ? item.details.profile.phoneNumber
          : "";
      return item1.search(e.currentTarget.value) !== -1;
    });
    this.setState({
      searchedinput: e.currentTarget.value,
      usersList: updatedList,
    });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleUserItemClick = (selecteduser) => {
    this.setState({ selecteduser });
  };

  handleDeleteIconClick = (deleteUser) => {
    this.setState({ deleteUser, open: true });
  };

  onClose = () => {
    this.setState({ open: false });
  };

  handleSwitchChange = (data) => {
    console.log("data is -->", data);
    let bool = data.details.profile.isActive ? false : true;
    // axios
    //   .delete(apiURL + `/${data.key}/${data.isActive}/disableadmin`)
    axios
      .put(apiURL + `/${data.key}/disableadmin`, {
        isActive: bool,
      })
      .then((response) => {
        console.log("Admin has successfully disabled the user..", bool);
        NotificationManager.success("successfully disabled the user!");
        let domainRef = firebase
          .database()
          .ref(`Users/${data.key}/profile/isActive`);
        domainRef.set(bool);
        console.log("successfully disabled the isActive tab in user tree ...");
      })
      .catch((error) => {
        console.log("error is --->", error);
      });
  };

  render() {
    const { classes, theme } = this.props;
    const {
      usersList,
      serverstatus,
      open,
      selecteduser,
      searchedinput,
      deleteUser,
    } = this.state;
    const drawer = (
      <UsersSidebar
        usersList={usersList}
        serverstatus={serverstatus}
        open={open}
        searchedinput={searchedinput}
        selecteduser={selecteduser}
        deleteUser={deleteUser}
        onUserItemClick={this.handleUserItemClick}
        onDeleteIconClick={this.handleDeleteIconClick}
        onClose={this.onClose}
        onSearch={this.handleSearch}
        onSwitchChange={this.handleSwitchChange}
      />
    );
    return (
      <div className="chat-wrapper">
        <Helmet>
          <title>Domains</title>
          <meta name="description" content="Reactify Chat App" />
        </Helmet>
        <div className={classes.root}>
          <Hidden mdUp className="user-list-wrap">
            <Drawer
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css" className="user-list-wrap">
            <Drawer
              variant="permanent"
              open
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <div className={`chat-content ${classes.content}`}>
            <UsersDetails
              selecteduser={selecteduser}
              onMenuIconPress={this.handleDrawerToggle}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(UsersPage);

// import React, { Component } from "react";
// import { Helmet } from "react-helmet";
// // page title bar
// import PageTitleBar from "Components/PageTitleBar/PageTitleBar";
// import { Card, CardText, CardTitle, Button, CardLink } from "reactstrap";
// import RctCollapsibleCard from "Components/RctCollapsibleCard/RctCollapsibleCard";
// import Switch from "@material-ui/core/Switch";
// import IconButton from "@material-ui/core/IconButton";
// // intl messages
// import IntlMessages from "Util/IntlMessages";

// type UsersPageProps = {
//   match: any;
// };

// export default class UsersPage extends Component<UsersPageProps> {
//   render() {
//     return (
//       <div className="blank-wrapper">
//         <Helmet>
//           <title>Domains</title>
//           <meta name="description" content="Reactify Blank Page" />
//         </Helmet>
//         {/* <PageTitleBar
//           title={<IntlMessages id="sidebar.domains" />}
//           match={this.props.match}
//         /> */}
//         <RctCollapsibleCard
//         //heading={<IntlMessages id="widgets.backgroundVarient" />}
//         >
//           <div className="row">
//             {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
//               <div className="col-sm-12 col-md-3 mb-30" key={index}>
//                 <Card body inverse color="primary">
//                   <div className="row">
//                     <div className="col-3">
//                       <img
//                         src={require("Assets/avatars/avatar-blank.jpg")}
//                         alt="user profile"
//                         className="img-fluid rounded-circle"
//                         style={{ border: 0 }}
//                       />
//                     </div>
//                     <div className="col-9">
//                       <span>
//                         Edure
//                         <div style={{ fontSize: "12px" }}>
//                           <a>https://edure.com</a>
//                         </div>
//                       </span>
//                     </div>
//                   </div>
//                   <div className="row mb-10 mt-10">
//                     <div className="col-6 text-left">
//                       <CardText>Admin - 4</CardText>
//                     </div>
//                     <div className="col-6 text-left">
//                       <CardText>Employees - 54</CardText>
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-6">
//                       <Switch
//                       // checked={data.details.trackingPermission}
//                       // onChange={() => {
//                       //   this.handleSwitchChange(data);
//                       // }}
//                       />
//                     </div>
//                     <div className="col-6">
//                       <IconButton
//                         aria-label="Delete"
//                         // onClick={() => this.onDeleteIconClick(index)}
//                       >
//                         <i className="zmdi zmdi-delete text-light"></i>
//                       </IconButton>
//                     </div>
//                   </div>
//                 </Card>
//               </div>
//             ))}
//           </div>
//         </RctCollapsibleCard>
//       </div>
//     );
//   }
// }
