/**
 * Chat Sidebar
 */
import React, { Component } from 'react';

// components
import UserList from './UserList';

class ChatSidebar extends Component {
	render() {
		return (
			<div className="chat-sidebar">
				<div className="user-wrap d-flex justify-content-between">
					<div className="media align-items-center">
						<div className="media-body mt-1">
							<h1 className="text-black text-center mb-0">Members</h1>
						</div>
					</div>
				</div>
				<UserList />
			</div>
		);
	}
}

export default ChatSidebar;
