import * as firebase from "firebase/app";
import moment from "moment";
const {
  GET_FAMILY_MEMBERS_HISTORY,
  GET_SUCCESS_FAMILY_MEMBERS_HISTORY,
  SHOW_FAMILY_MEMBERS_LOADING_INDICATOR_HISTORY,
  HIDE_FAMILY_MEMBERS_LOADING_INDICATOR_HISTORY,
} = require("Actions/types");

export const getMemberHistory = (userName, date = null) => (dispatch) => {
  dispatch({ type: GET_FAMILY_MEMBERS_HISTORY });
  console.log("curUserLat -------: ");
  console.log("Date change event******** : " + date);
  var userHistory: { [key: string]: any }[] = [];
  let userHistoryMap: { [key: string]: any }[] = [];
  let promise: any = [];
  let currentDate: string | null = "";
  if (date) {
    currentDate = date;
  } else {
    console.log("--------today----" + moment().format("YYYY_MM_DD"));
    currentDate = moment().format("YYYY_MM_DD");
  }
  console.log("----currentDate value ---- " + currentDate);
  promise.push(
    firebase
      .database()
      .ref(`track/${userName}/${currentDate}`)
      .orderByChild("timestamp")
      .once("value", function (trackSnap) {
        console.log("get track tree data1 : " + trackSnap.key);
        trackSnap.forEach(function (childtrackSnap) {
          let trackData = childtrackSnap.val();
          trackData["trackID"] = childtrackSnap.key;
          userHistory.push(trackData);
          if (childtrackSnap.val().location) {
            userHistoryMap.push(childtrackSnap.val());
          }
        });
        console.log("---------------");
      })
  );

  Promise.all(promise)
    .then((res) => {
      let historyData = userHistory.reverse(); //this.sortObject(userHistory);
      let historyDataTotal = historyData.length;
      console.log(historyData);
      console.log("Get total history data" + historyDataTotal);
      let historyMapData = userHistoryMap;
      if (!historyData) {
        console.log("No data available for this user.");
      }
      console.log(historyData);
      console.log("----2---");
      dispatch({
        type: GET_SUCCESS_FAMILY_MEMBERS_HISTORY,
        payload: historyData,
      });
    })
    .catch((e) => {
      console.log("Error: ", e);
    });
};

export const showLoadingOnMemberHistory = () => ({
  type: SHOW_FAMILY_MEMBERS_LOADING_INDICATOR_HISTORY,
});

export const hideLoadingOnMemberHistory = () => ({
  type: HIDE_FAMILY_MEMBERS_LOADING_INDICATOR_HISTORY,
});
