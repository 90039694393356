import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
// import AppConfig from 'Constants/AppConfig';
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const AppConfig = require("Constants/AppConfig")

const EmployeeWelcome = () => {
  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: "#6b79ff",
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
        className=""
      >
        <AppBar position="static" className="session-header">
          <Toolbar>
            <div className="container mt-2">
              <div className="d-flex justify-content-between">
                <div className="session-logo">
                  <Link to="/">
                    <img
                      src="https://i.ibb.co/8gsVdKL/white-logo.png"
                      alt="session-logo"
                      width="40"
                      height="40"
                    />
                    &nbsp; &nbsp;
                    <span
                      style={{
                        fontSize: "25px",
                        fontWeight: 500,
                        color: "white",
                        fontFamily: `"Comic Sans MS", cursive, sans-serif`,
                      }}
                    >
                      {AppConfig.brandName}
                    </span>
                  </Link>
                </div>
                <div></div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div style={{
          position: "absolute",
          top: "38%",
          width: "100%",
          height: "100%",
          verticalAlign: "center",
        }}>
          <div className="text-center">
            <div style={{ color: "white", fontSize: "50px" }}>
              Welcome to Family Tracker!
            </div>
            <div style={{ color: "white", fontSize: "30px" }}>
              Thank you for signing up.
              &nbsp; &nbsp;
              <Button
                component={Link}
                to="/signin"
                variant="contained"
                className="btn-light"
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EmployeeWelcome;
