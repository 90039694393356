/**
 * Reactify Sidebar
 */
import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import "../../assets/custom-styles//allstyles.css";

// redux actions
import { collapsedSidebarAction } from "Actions";

// components
import UserBlock from "./UserBlock";
import SidebarContent from "./SidebarContent";
import AgencySidebar from "../AgencyMenu/AgencySidebar";

class Sidebar extends Component {
  UNSAFE_componentWillMount() {
    this.updateDimensions();
  }

  shouldComponentUpdate(nextProps) {
    const {
      enableSidebarBackgroundImage,
      selectedSidebarImage,
      isDarkSidenav,
      locale,
    } = this.props;
    if (
      enableSidebarBackgroundImage !== nextProps.enableSidebarBackgroundImage ||
      selectedSidebarImage !== nextProps.selectedSidebarImage ||
      isDarkSidenav !== nextProps.isDarkSidenav ||
      locale
    ) {
      return true;
    } else {
      return false;
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { windowWidth } = this.state;
    if (nextProps.location !== this.props.location) {
      if (windowWidth <= 1199) {
        this.props.collapsedSidebarAction(false);
      }
    }
  }

  updateDimensions = () => {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  };

  render() {
    const {
      enableSidebarBackgroundImage,
      selectedSidebarImage,
      isDarkSidenav,
      agencySidebar,
    } = this.props;
    return (
      <Fragment>
        <div
          className={classNames("rct-sidebar", {
            "background-none": !enableSidebarBackgroundImage,
          })}
          style={{
            backgroundImage: enableSidebarBackgroundImage
              ? `url(${selectedSidebarImage})`
              : "none",
          }}
        >
          <div
            className={classNames("rct-sidebar-content", {
              "sidebar-overlay-dark": isDarkSidenav,
              "sidebar-overlay-light": !isDarkSidenav,
            })}
          >
            <div className="site-logo">
              {/*<Link to="/" className="logo-mini">
								<img src={require('Assets/img/appLogo.png')} className="mr-15" alt="site logo" width="35" height="35" />
							</Link>
							<Link to="/" className="logo-normal">
								<img src={require('Assets/img/appLogoText.png')} className="img-fluid" alt="site-logo" width="67" height="17" />
		</Link>*/}
              <Link to="/" className="logo-mini mt-1 ml-1">
                <img
                  src={require("Assets/img/white-logo.png")}
                  className="mr-15"
                  alt="site logo"
                  width="30"
                  height="30"
                />
              </Link>
              <Link to="/" className="logo-normal ml-1 mt-1">
                <p
                  className="m-0 text-center"
                  style={{ fontSize: 18, color: "#fff" }}
                >
                  Family Tracker
                </p>
              </Link>
            </div>
            <div className="rct-sidebar-wrap">
              <Scrollbars
                className="rct-scroll"
                autoHide
                autoHideDuration={100}
                style={{ height: "calc(100vh - 60px)" }}
              >
                <UserBlock />
                {!agencySidebar ? <SidebarContent /> : <AgencySidebar />}
              </Scrollbars>
            </div>
            <div className="versionstyle">Version 1.0.1</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

// map state to props
const mapStateToProps = ({ settings }) => {
  const {
    enableSidebarBackgroundImage,
    selectedSidebarImage,
    collapsedSidebar,
    isDarkSidenav,
    locale,
  } = settings;
  return {
    enableSidebarBackgroundImage,
    selectedSidebarImage,
    collapsedSidebar,
    isDarkSidenav,
    locale,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    collapsedSidebarAction,
  })(Sidebar)
);
