import React, { Component } from "react";
//import * as React from 'react';

import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import RctPageLoader from "../../../components/RctPageLoader/RctPageLoader";
import Switch from "@material-ui/core/Switch";

//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
//import { NotificationManager } from "react-notifications";
const { NotificationManager } = require("react-notifications");
import { UserDetails } from "../index";

type EmployeeListProps = {
  employees: [any];
  serverstatus: boolean;
  searchedInput: string;
  onEditIconClick(any): void;
  onDeleteIconClick(any): void;
};

class EmployeesList extends Component<EmployeeListProps> {
  state = {};

  handleSwitchChange = (data: { key: string; details: UserDetails }) => {
    let userId = sessionStorage.getItem("user_id") || "";
    // let userId = localStorage.getItem("user_id") || '';
    console.log("User ID is --" + userId);
    let permissionRef = firebase.database().ref("Permissions/").child(userId);
    let trackingPermission = !data.details.trackingPermission;
    permissionRef
      .update({
        [data.key]: trackingPermission,
      })
      .then((result) => {
        console.log("permission edited successfully", result);
        NotificationManager.success("Permission edited successfully!");
      })
      .catch(function (error) {
        // Handle Errors here.
        console.log("error while updating permission", error);
        NotificationManager.error(error.message);
      });
  };

  render() {
    const { employees, serverstatus, searchedInput } = this.props;
    let adminID = sessionStorage.getItem("user_id");
    // let adminID = localStorage.getItem("user_id");
    console.log("Rendering friends and family here --");
    console.log("employees--", employees, "status of server---", serverstatus);
    if (employees.length < 1 && !serverstatus) {
      return (
        <div style={{ position: "absolute", top: "45%", left: "45%" }}>
          <RctPageLoader />
        </div>
      );
    }
    if (employees.length < 1 && serverstatus) {
      return (
        <div
          style={{
            position: "absolute",
            left: "35%",
            top: "45%",
            opacity: ".3",
            fontWeight: 500,
            fontSize: "17px",
          }}
        >
          {searchedInput ? "No results found" : "No Members Added"}
        </div>
      );
    }
    return (
      <React.Fragment>
        {employees.length > 0 && serverstatus ? (
          <List className="list-unstyled p-0">
            {employees.map((data, key) => (
              <ListItem button key={key}>
                <Avatar className="bg-primary mr-10">
                  <i className="zmdi zmdi-account-circle zmdi-hc-lg"></i>
                </Avatar>
                <ListItemText
                  className="mr-30"
                  primary={
                    <div>
                      <div className="font-weight-bold">
                        {data.details.name}
                      </div>
                      <div>{`${data.details.phoneNumber}`}</div>
                    </div>
                  }
                ></ListItemText>
                <ListItemSecondaryAction className="ml-10">
                  <Switch
                    checked={data.details.trackingPermission}
                    onChange={() => {
                      this.handleSwitchChange(data);
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default EmployeesList;
