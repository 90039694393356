export default [
    {
        'title': 'All',
    },
    {
        'title': 'None',
    },
    {
        'title': 'Starred',
    },
    {
        'title': 'Unstarred',
    }
];
