import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import RctPageLoader from "../../../components/RctPageLoader/RctPageLoader";
import Switch from "@material-ui/core/Switch";
import { Input } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
// import { withRouter } from "react-router-dom";
const { withRouter } = require("react-router-dom");
import { connect } from "react-redux";
// app layouts
const { getAppLayout } = require("Helpers/helpers");

//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { NotificationManager } from "react-notifications";

declare namespace JSX {
  interface IntrinsicElements {
    EnlistingDomains: { [key: string]: any };
  }
}

type EnlistingDomainsProps = {
  searchedInput: any;
  location: any;
};

class EnlistingDomains extends Component<EnlistingDomainsProps> {
  state = {
    serverstatus: false,
    domainList: [],
    initialDomainList: [],
    search: "",
  };

  componentDidMount() {
    console.log("Here are the Domains...");
    this.getDomainList();
  }

  getDomainList = () => {
    console.log("domain are called from firebase.");
    const userId = sessionStorage.getItem("user_id");
    // const userId = localStorage.getItem("user_id");
    console.log(userId);

    let domainRef = firebase
      .database()
      .ref("Users/" + userId + "/allow_domain");
    domainRef.on(
      "value",
      (usersSnap) => {
        let result = { ...usersSnap.val() };
        let empArr: any = [];
        if (result !== null) {
          for (let key in result) {
            let tempObj: any = {};
            let domainname = key.split("_").join(".");
            tempObj["domain"] = domainname;
            tempObj["value"] = result[key];
            console.log(key, result[key]);
            empArr.push(tempObj);
          }
          // console.log(empArr);
          this.setState({
            initialDomainList: empArr,
            domainList: empArr,
            serverstatus: true,
          });
        } else {
          this.setState({
            serverstatus: true,
            initialDomainList: [],
            domainList: [],
            //   status: "",
          });
        }
      },
      (error) => {
        console.log("Error while fetching user profile -- ", error.code);
        this.setState({
          serverstatus: true,
        });
      }
    );
  };

  handleSwitchChange = (data) => {
    let userId = sessionStorage.getItem("user_id");
    // let userId = localStorage.getItem("user_id");
    console.log("User ID is --" + userId);
    let domain = data.domain.split(".").join("_");
    let permissionRef = firebase
      .database()
      .ref("Users/" + userId)
      .child("allow_domain");
    let trackingPermission = !data.value;
    permissionRef
      .update({
        [domain]: trackingPermission,
      })
      .then(() => {
        NotificationManager.success("Permission edited successfully!");
      })
      .catch(function (error) {
        // Handle Errors here.
        NotificationManager.error(error.message);
      });
  };

  handlesearch = (e) => {
    // console.log("1111")
    const { initialDomainList, domainList } = this.state;

    let updateList = initialDomainList;
    updateList = updateList.filter((item: any) => {
      return item.domain.search(e.target.value) !== -1;
    });
    this.setState({
      domainList: updateList,
      search: e.target.value,
    });
  };

  getScrollHeight() {
    const { location } = this.props;
    const appLayout = getAppLayout(location);
    switch (appLayout) {
      case "app":
        return "calc(100vh - 188px)";
      case "agency":
        return "calc(100vh - 372px)";
      case "boxed":
        return "calc(100vh - 372px)";
      case "horizontal":
        return "calc(100vh - 238px)";
      default:
        break;
    }
  }

  render() {
    const { searchedInput } = this.props;
    const { serverstatus, domainList, search } = this.state;
    let adminID = sessionStorage.getItem("user_id");
    // let adminID = localStorage.getItem("user_id");
    // console.log(this.state)
    return (
      <React.Fragment>
        <div className="search-wrapper mb-0 position-relative">
          <Input
            type="text"
            name="search"
            id="search"
            className="search-input rounded-0 py-2 px-3"
            placeholder="Search"
            onChange={this.handlesearch}
            value={search}
          />

          <i className="zmdi zmdi-search search-icon"></i>
        </div>
        <div>
          <Scrollbars
            className="rct-scroll"
            autoHide
            style={{ height: this.getScrollHeight() }}
          >
            {domainList.length !== 0 && serverstatus ? (
              <List className="list-unstyled p-0">
                {domainList.map((data: any, index: number) => (
                  <ListItem button key={index}>
                    <Avatar className="bg-primary mr-10">
                      <i className="zmdi zmdi-account-circle zmdi-hc-lg"></i>
                    </Avatar>
                    <ListItemText
                      className="mr-30"
                      primary={
                        <div>
                          <div className="font-weight-bold">{data.domain}</div>
                          {/* <div>{`${data.value}`}</div> */}
                        </div>
                      }
                    ></ListItemText>
                    <ListItemSecondaryAction className="ml-10">
                      <Switch
                        checked={data.value}
                        onChange={() => {
                          this.handleSwitchChange(data);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
                ""
              )}
            {domainList.length === 0 && serverstatus ? (
              <div
                style={{
                  position: "absolute",
                  left: "35%",
                  top: "45%",
                  opacity: ".3",
                  fontWeight: 500,
                  fontSize: "17px",
                }}
              >
                {search ? "No results found" : "No Members Added"}
              </div>
            ) : (
                ""
              )}
            {domainList.length === 0 && !serverstatus ? (
              <div
                style={{
                  position: "absolute",
                  top: "45%",
                  left: "45%",
                }}
              >
                <RctPageLoader />
              </div>
            ) : (
                ""
              )}
          </Scrollbars>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ chatAppReducer }) => {
  return chatAppReducer;
};

// export default EnlistingDomains;
export default withRouter(connect(mapStateToProps)(EnlistingDomains));
