import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { NotificationManager } from "react-notifications";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import axios from "axios";

import { invitefamilymemberurl } from "../../../constants/URL";

declare namespace JSX {
  interface IntrinsicElements {
    InviteDialogueBox: { [key: string]: any };
  }
}

type InvitationBoxProps = {
  formdata: any;
  onClose(): void;
  open: any;
  errors: any;
  onPhoneInputChange(any): void;
};

class InvitationDialogueBox extends Component<InvitationBoxProps> {
  state = { activeTab: "" };
  confirmInvite = () => {
    let formdata = { ...this.props.formdata };
    let userId = sessionStorage.getItem("user_id");
    console.log(userId);
    console.log("Sending invitation ...");
    axios({
      url: invitefamilymemberurl,
      method: "post",
      data: {
        phoneNumber: formdata.phone,
        userId: userId,
      },
    })
      .then(() => {
        NotificationManager.success("Invitation sent.");
        console.log("Invitation sent -- ");
        this.props.onClose();
      })
      .catch(() => {
        console.log("Couldn't send invitation --");
      });
  };

  handleTabChange(value) {
    this.setState({ activeTab: value });
    console.log(value);
  }

  render() {
    const { formdata, open, errors } = this.props;
    const { activeTab } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          style={{
            width: "350px",
          }}
          id="form-dialog-title"
        >
          Invite Employee
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add an Employee, please enter his/her contact number here.
          </DialogContentText>
          <PhoneInput
            isValid={(value, country: any) => {
              if (value.match(/12345/)) {
                return "Invalid value: " + value + ", " + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else if (value.length < 12 && value.length > 3) {
                return "Enter a valid number";
              } else {
                return true;
              }
            }}
            //name="phone"
            placeholder="Enter Phone Number"
            inputStyle={{
              // color: "blue",
              width: "100%",
              height: "35px",
              border: "none",
              boxShadow: "none",
              borderBottom: "1px solid grey",
            }}
            inputProps={{
              name: "phone",
              required: true,
            }}
            value={formdata.phone}
            onChange={this.props.onPhoneInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={this.props.onClose}
            color="primary"
            className="text-white"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => this.confirmInvite()}
            className="btn-info text-white"
          >
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default InvitationDialogueBox;
