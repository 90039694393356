/**
 * Chat Sidebar
 */
import React, { Component } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import { Input } from "reactstrap";
const { Input } = require("reactstrap");
import { connect } from "react-redux";
// import { Scrollbars } from "react-custom-scrollbars";
const { Scrollbars } = require("react-custom-scrollbars");
// import { withRouter } from "react-router-dom";
const { withRouter } = require("react-router-dom");
import EmployeesList from "./EmployeesList";
import EditDialogueBox from "./EditDialogueBoxx";
import DeleteDialogueBox from "./DeleteDialogueBox";
import InviteDialogueBox from "./InviteDialogueBoxx";
// import { Button } from "reactstrap";
const { Button } = require("reactstrap");
import InvitationsList from "./InvitationsList";

// app layouts
// import { getAppLayout } from "Helpers/helpers";
const { getAppLayout } = require("Helpers/helpers");

//firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

type Anyobj = {
  [key: string]: any;
};

type EmployeeListPanelprops = {
  formdata: {
    userID: string;
    name: string;
    role: string;
    phone: string;
    email: string;
    search: any;
    searchInvitationListInput: any;
  };
  status: string;
  serverstatus: boolean;
  errors: any;
  open: boolean;
  employees: Anyobj[];
  invitationList: Anyobj[];
  location: any;
  chatAppReducer: any;
  onEditIconClick(arg0: Anyobj): void;
  onDeleteIconClick(arg0: Anyobj): void;
  onInputChange(arg0: Anyobj): void;
  onInviteIconClick(): void;
  onPhoneInputChange(arg0: Anyobj): void;
  onShowEmployeeDetails(arg0: Anyobj): void;
  onClose(): void;
};

interface AppState {
  activeTab: number;
  dense: boolean;
  secondary: boolean;
}

class EmployeeListPanel extends Component<EmployeeListPanelprops, AppState> {
  constructor(props: EmployeeListPanelprops) {
    super(props);
    this.state = {
      activeTab: 0,
      dense: false,
      secondary: true,
    };
  }

  onEditIconClick = (data: Anyobj) => {
    this.props.onEditIconClick(data);
  };

  onDeleteIconClick = (data: Anyobj) => {
    this.props.onDeleteIconClick(data);
  };

  onClose = () => {
    this.props.onClose();
  };

  handleTabChange(value: number) {
    this.setState({ activeTab: value });
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onInputChange(e);
  };

  onShowEmployeeDetails = (data: Anyobj) => {
    this.props.onShowEmployeeDetails(data);
  };
  // handleSearchInput = (e) => {
  //   this.setState({ value: e.target.value });
  //   this.props.onUpdateSearch(e);
  // };

  handlePhoneInputChange = (e: Anyobj) => {
    this.props.onPhoneInputChange(e);
  };

  getScrollHeight() {
    const { location } = this.props;
    const appLayout = getAppLayout(location);
    switch (appLayout) {
      case "app":
        return "calc(100vh - 205px)";
      case "agency":
        return "calc(100vh - 372px)";
      case "boxed":
        return "calc(100vh - 372px)";
      case "horizontal":
        return "calc(100vh - 238px)";
      default:
        break;
    }
  }

  render() {
    const { activeTab, dense } = this.state;
    const {
      open,
      status,
      formdata,
      errors,
      serverstatus,
      employees,
      invitationList,
    } = this.props;
    return (
      <div className="chat-sidebar">
        {/* Invite Dialog box */}
        {status === "invite" ? (
          <InviteDialogueBox
            formdata={formdata}
            open={open}
            onClose={this.onClose}
            onInputChange={this.handleInputChange}
            errors={errors}
          />
        ) : (
          ""
        )}

        {/* Edit Dialog box */}

        {status === "edit" ? (
          <EditDialogueBox
            formdata={formdata}
            open={open}
            onClose={this.onClose}
            onInputChange={this.handleInputChange}
            onPhoneInputChange={this.handlePhoneInputChange}
            errors={errors}
          />
        ) : (
          ""
        )}

        {/* Delete Dialog box */}

        {status === "delete" ? (
          <DeleteDialogueBox
            formdata={formdata}
            open={open}
            onClose={this.onClose}
          />
        ) : (
          ""
        )}

        {/* Tabs */}

        <React.Fragment>
          <Tabs
            value={activeTab}
            onChange={(e, value) => this.handleTabChange(value)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              icon={
                <i
                  //style={{ fontSize: "20px" }}
                  className="zmdi zmdi-accounts"
                ></i>
              }
              label="Users"
            />
            <Tab
              icon={
                <i
                  //style={{ fontSize: "20px" }}
                  className="zmdi zmdi-accounts-add"
                ></i>
              }
              label="Invitations"
            />
          </Tabs>
        </React.Fragment>

        {activeTab === 0 ? (
          <React.Fragment>
            {/* Search bar */}

            <div>
              <div className="search-wrapper mb-0 position-relative">
                <Input
                  type="text"
                  name="search"
                  id="search"
                  className="search-input rounded-0 py-2 px-3"
                  placeholder="Search Employees..."
                  onChange={this.props.onInputChange}
                  value={formdata.search}
                />

                <i className="zmdi zmdi-search search-icon"></i>
              </div>

              {/* Employee List */}

              <div className="chat-list">
                <Scrollbars
                  className="rct-scroll"
                  autoHide
                  style={{ height: this.getScrollHeight() }}
                >
                  <EmployeesList
                    searchedInput={formdata.search}
                    employees={employees}
                    serverstatus={serverstatus}
                    onEditIconClick={this.onEditIconClick}
                    onDeleteIconClick={this.onDeleteIconClick}
                    onShowEmployeeDetails={this.onShowEmployeeDetails}
                  />
                </Scrollbars>
              </div>
            </div>
            <div
              style={{
                position: "fixed",
                bottom: ".6%",
                width: "402px",
                marginLeft: "3px",
              }}
            >
              <Button
                color="primary"
                block
                onClick={() => this.props.onInviteIconClick()}
              >
                Invite Users&nbsp;<i className="zmdi zmdi-accounts-add"></i>
              </Button>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}

        {activeTab === 1 ? (
          <React.Fragment>
            <div className="search-wrapper mb-0 position-relative">
              <Input
                type="text"
                name="searchInvitationListInput"
                id="searchInvitationListInput"
                className="search-input rounded-0 py-2 px-3"
                placeholder="Search Invitaions..."
                onChange={this.props.onInputChange}
                value={formdata.searchInvitationListInput}
              />
              <i className="zmdi zmdi-search search-icon"></i>
            </div>
            <Scrollbars
              className="rct-scroll"
              autoHide
              style={{ height: this.getScrollHeight() }}
            >
              <InvitationsList
                serverstatus={serverstatus}
                searchedInput={formdata.searchInvitationListInput}
                invitationList={invitationList}
              />
            </Scrollbars>
            <div
              style={{
                position: "fixed",
                bottom: ".6%",
                width: "402px",
                marginLeft: "3px",
              }}
            >
              <Button
                color="primary"
                block
                onClick={() => this.props.onInviteIconClick()}
              >
                Invite Users&nbsp;<i className="zmdi zmdi-accounts-add"></i>
              </Button>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: Anyobj) => {
  const { chatAppReducer } = state;
  return chatAppReducer;
};

export default withRouter(connect(mapStateToProps)(EmployeeListPanel));
