/**
 * Sign Up With Firebase
 */
import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import { Form, FormGroup, Input } from "reactstrap";
import LinearProgress from "@material-ui/core/LinearProgress";
import QueueAnim from "rc-queue-anim";
import { Fab } from "@material-ui/core";
import { NotificationManager } from "react-notifications";

import firebase from "firebase";
import "firebase/auth";

// components
const { SessionSlider } = require("Components/Widgets");

// app config
const AppConfig = require("Constants/AppConfig");

// redux action
const {
  signupAdminInFirebase,
  signinUserWithFacebook,
  signinUserWithGoogle,
  signinUserWithGithub,
  signinUserWithTwitter,
  saveUserDetailsInFirebase,
} = require("Actions");

interface UserDetailsState {
  errors: { [key: string]: string };
  formdata: {
    name: string;
    email: string;
    password: string;
    uid: any;
  };
}

type UserDetailsProps = {
  saveUserDetailsInFirebase(arg1: { [key: string]: string }, arg2: any): void;
  loading: any;
  count: any;
  history: any;
};

class UserDetails extends Component<UserDetailsProps, UserDetailsState> {
  state = {
    formdata: {
      name: "",
      email: "",
      password: "",
      uid: "",
    },
    errors: {
      name: "",
      email: "",
      password: "",
    },
  };

  componentDidMount() {
    let uid = sessionStorage.getItem("user_id");
    // let uid = localStorage.getItem("user_id");
    this.setState({ formdata: { ...this.state.formdata, uid } });
  }

  //validating input on onChange
  validateInput = (e) => {
    switch (e.currentTarget.name) {
      case "name":
        if (!e.currentTarget.value.trim()) return "*Name is required";
        break;
      case "email":
        if (!e.currentTarget.value.trim()) return "*Email is required";
        else if (
          !/^([A-Za-z0-9.]+)@([A-Za-z0-9]+)\.([a-z]{3})$/.test(
            e.currentTarget.value
          )
        )
          return "*not a valid email";
        break;
      case "password":
        if (!e.currentTarget.value.trim()) return "*Password is required";
        else if (e.currentTarget.value.trim().length < 5)
          return "*Password should have at least 5 characters";
        break;
      default:
        break;
    }
    return "";
  };

  //validating input for making sign-up button to work
  validate = () => {
    const { formdata } = this.state;
    let errs: any = {};
    if (!formdata.name.trim()) errs.name = "*Name is required";
    if (!formdata.email.trim()) errs.email = "*Email is required";
    else if (
      !/^([A-Za-z0-9.]+)@([A-Za-z0-9]+)\.([a-z]{3})$/.test(formdata.email)
    )
      errs.email = "*not a valid email";
    if (!formdata.password.trim()) errs.passowrd = "*Password is required";
    else if (formdata.password.trim().length < 5)
      errs.passowrd = "*Password should have at least 5 characters";
    return errs;
  };

  //handling input fields
  handleChange = (e) => {
    let errString = this.validateInput(e);
    const data = { ...this.state.formdata };
    let errors = { ...this.state.errors };
    errors[e.currentTarget.name] = errString;
    data[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ formdata: data, errors });
  };

  isFormInvalid = () => {
    let errs = this.validate();
    let errCount = Object.keys(errs).length;
    return errCount > 0;
  };
  /**
   * On User Signup
   */
  onSaveDetails = () => {
    const { name, email, password, uid } = this.state.formdata;
    if (name !== "" && email !== "" && password !== "" && uid !== "") {
      sessionStorage.removeItem("user_id");
      // localStorage.removeItem("user_id");
      this.props.saveUserDetailsInFirebase(
        { name, email, password, uid },
        this.props.history
      );
    }
  };

  render() {
    const { formdata, errors } = this.state;
    const { name, email, password } = formdata;
    const { loading } = this.props;
    return (
      <QueueAnim type="bottom" duration={2000}>
        <div
          style={{
            backgroundColor: "#6b79ff",
            position: "absolute",
            width: "100%",
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          className=""
        >
          {loading && <LinearProgress />}
          <AppBar position="static" className="session-header">
            <Toolbar>
              <div className="container mt-2">
                <div className="d-flex justify-content-between">
                  <div className="session-logo">
                    <Link to="/">
                      <img
                        src="https://i.ibb.co/8gsVdKL/white-logo.png"
                        alt="session-logo"
                        width="40"
                        height="40"
                      />
                      &nbsp; &nbsp;
                      <span
                        style={{
                          fontSize: "25px",
                          fontWeight: 500,
                          color: "white",
                          fontFamily: `"Comic Sans MS", cursive, sans-serif`,
                        }}
                      >
                        Family Tracker
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <br />
          <br />
          <br />
          <br />
          <br />
          <div className="session-inner-wrapper">
            <div className="container">
              <div className="row row-eq-height">
                <div className="d-none d-sm-inline-block col-sm-1 col-md-2"></div>
                <div
                  style={{
                    backgroundColor: "white",
                    boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.3)",
                    borderRadius: "4px",
                  }}
                  className="col-12 col-sm-10 col-md-8"
                >
                  <div className="session-body text-center">
                    <br />
                    <div className="session-head mb-15">
                      <h2>Enter Details</h2>
                    </div>
                    <Form>
                      <FormGroup className="has-wrapper">
                        <Input
                          type="text"
                          value={name}
                          name="name"
                          id="user-name"
                          className="has-input input-lg border"
                          placeholder="Enter Your Name"
                          onChange={this.handleChange}
                        />
                        <span className="has-icon">
                          <i className="ti-user"></i>
                        </span>
                        {errors.name ? (
                          <div
                            style={{ fontSize: "13px" }}
                            className="text-left text-danger ml-2"
                          >
                            {errors.name}
                          </div>
                        ) : (
                            ""
                          )}
                      </FormGroup>
                      <FormGroup className="has-wrapper">
                        <Input
                          type="mail"
                          value={email}
                          name="email"
                          id="user-mail"
                          className="has-input input-lg border"
                          placeholder="Enter Email Address"
                          onChange={this.handleChange}
                        />
                        <span className="has-icon">
                          <i className="ti-email"></i>
                        </span>
                        {errors.email ? (
                          <div
                            style={{ fontSize: "13px" }}
                            className="text-left text-danger ml-2"
                          >
                            {errors.email}
                          </div>
                        ) : (
                            ""
                          )}
                      </FormGroup>
                      <FormGroup className="has-wrapper">
                        <Input
                          value={password}
                          type="Password"
                          name="password"
                          id="pwd"
                          className="has-input input-lg border"
                          placeholder="Password"
                          onChange={this.handleChange}
                        />
                        <span className="has-icon">
                          <i className="ti-lock"></i>
                        </span>
                        {errors.password ? (
                          <div
                            style={{ fontSize: "13px" }}
                            className="text-left text-danger ml-2"
                          >
                            {errors.password}
                          </div>
                        ) : (
                            ""
                          )}
                      </FormGroup>
                      <FormGroup className="mb-15">
                        <Button
                          className="btn btn-info text-white btn-block w-100"
                          onClick={() => this.onSaveDetails()}
                          disabled={this.isFormInvalid()}
                        >
                          Save Details
                        </Button>
                      </FormGroup>
                    </Form>
                    <p>
                      <Link to="/terms-condition" className="text-muted">
                        Terms of Service
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="d-none d-sm-inline-block col-sm-1 col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </QueueAnim>
    );
  }
}

// map state to props
const mapStateToProps = ({ authUser }) => {
  const { loading } = authUser;
  return { loading };
};

export default connect(mapStateToProps, {
  saveUserDetailsInFirebase,
  signupAdminInFirebase,
  signinUserWithFacebook,
  signinUserWithGoogle,
  signinUserWithGithub,
  signinUserWithTwitter,
})(UserDetails);
