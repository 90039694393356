/**
 * User List
 */
import React, { Component } from "react";
import { Input, Button } from "reactstrap";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { withRouter } from "react-router-dom";

// components
import RecentChatUsers from "./RecentChatUsers";

//rct section loader
import RctSectionLoader from "Components/RctSectionLoader/RctSectionLoader";

//app layouts
import { getAppLayout } from "Helpers/helpers";

//redux actions
import {
  chatWithSelectedUser,
  updateUsersSearch,
  onSearchUsers,
  filterMembersOnSeverity,
  getRecentChatUsers,
  getFamilyMembers,
} from "Actions";

// const { RctSectionLoader } = require("Components/RctSectionLoader/RctSectionLoader");
// const { getAppLayout } = require("Helpers/helpers");

//  const {
//   chatWithSelectedUser,
//   updateUsersSearch,
//   onSearchUsers,
//   filterMembersOnSeverity,
//   getRecentChatUsers,
//   getFamilyMembers,
// } = require("Actions");

 declare namespace JSX {
  interface IntrinsicElements {
    UserList: { [key: string]: any };
  }
}

type UserListProps = {
  chatWithSelectedUser(any): void;
  updateUsersSearch(any): void;
  onSearchUsers(any): void;
  filterMembersOnSeverity(any): void;
  getRecentChatUsers(): void;
  getFamilyMembers(): void;
  //
  location: any;
  filterString: any;
  loading: any;
};

class UserList extends Component<UserListProps> {

  /**
   * Swicth Chat With User
   * @param {*object} user
   */
  switchChatWithUser(user) {
    this.props.chatWithSelectedUser(user);
  }

  /**
   * On Search Chat Users
   */
  updateSearch(e) {
    this.props.updateUsersSearch(e.target.value);
    this.props.onSearchUsers(e.target.value);
  }

  fetchRecentChatUsers() {
    this.props.getRecentChatUsers();
    this.props.getFamilyMembers();
  }

  getScrollHeight() {
    const { location } = this.props;
    const appLayout = getAppLayout(location);
    switch (appLayout) {
      case "app":
        return "calc(100vh - 148px)";
      case "agency":
        return "calc(100vh - 372px)";
      case "boxed":
        return "calc(100vh - 372px)";
      case "horizontal":
        return "calc(100vh - 238px)";
      default:
        break;
    }
  }

  render() {
    const {
      filterMembersOnSeverity,
      filterString,
      loading,
    } = this.props;
    return (
      <div>
        <div className="row mb-3" style={{ height: 30, fontSize: 13 }}>
          <div className="col-1"></div>
          <div
            onClick={() => filterMembersOnSeverity("All")}
            className="col-2  d-flex align-items-center justify-content-center text-white bg-primary"
            style={{
              cursor: "pointer",
              display: "inline-block",
              width: "100%",
            }}
          >
            All
          </div>
          <div
            onClick={() => filterMembersOnSeverity("Normal")}
            className="col-2 d-flex align-items-center justify-content-center text-center text-white bg-success"
            style={
              filterString && filterString === "Normal"
                ? {
                  cursor: "pointer",
                  border: "2px solid #000",
                  display: "inline-block",
                  width: "100%",
                }
                : { cursor: "pointer", display: "inline-block", width: "100%" }
            }
          >
            Normal
          </div>
          <div
            onClick={() => filterMembersOnSeverity("Caution")}
            className="col-2 d-flex align-items-center justify-content-center  text-center text-white bg-warning"
            style={
              filterString && filterString === "Caution"
                ? {
                  cursor: "pointer",
                  border: "2px solid #000",
                  display: "inline-block",
                  width: "100%",
                }
                : { cursor: "pointer", display: "inline-block", width: "100%" }
            }
          >
            Caution
          </div>
          <div
            onClick={() => filterMembersOnSeverity("Warning")}
            className="col-2 d-flex align-items-center justify-content-center text-center text-white bg-orange"
            style={
              filterString && filterString === "Warning"
                ? {
                  cursor: "pointer",
                  border: "2px solid #000",
                  display: "inline-block",
                  width: "100%",
                }
                : { cursor: "pointer", display: "inline-block", width: "100%" }
            }
          >
            Warning
          </div>
          <div
            onClick={() => filterMembersOnSeverity("Panic")}
            className="col-2 d-flex align-items-center justify-content-center text-center text-white bg-danger"
            style={
              filterString && filterString === "Panic"
                ? {
                  cursor: "pointer",
                  border: "2px solid #000",
                  display: "inline-block",
                  width: "100%",
                }
                : { cursor: "pointer", display: "inline-block", width: "100%" }
            }
          >
            Panic
          </div>
          <div className="col-1"></div>
        </div>
        {this.props.loading && <RctSectionLoader />}
        <div className="chat-list pr-xl-1 pr-lg-1 pr-md-1 pr-sm-4 pr-6">
          <Scrollbars
            className="rct-scroll "
            autoHide
            style={{ height: this.getScrollHeight() }}
          >
            <RecentChatUsers />;
          </Scrollbars>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ family }) => {
  const { filterString, loading } = family;
  return { filterString, loading };
};


export default withRouter(
  connect(mapStateToProps, {
    chatWithSelectedUser,
    updateUsersSearch,
    onSearchUsers,
    filterMembersOnSeverity,
    getRecentChatUsers,
    getFamilyMembers,
  })(UserList)
);