import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// import { NotificationManager } from "react-notifications";
const { NotificationManager } = require("react-notifications");
import LinearProgress from "@material-ui/core/LinearProgress";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

import axios from "axios";
import { apiURL } from "../../../constants/URL";

type Inviteprops = {
  onClose(): void;
  onInputChange(arg0: any): void;
  formdata: {
    email: string;
  };
  errors: any;
  open: any;
};

interface AppState {
  loading?: boolean;
}

class InviteDialogueBox extends Component<Inviteprops, AppState> {
  constructor(props: Inviteprops) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  confirmInvite = () => {
    this.setState({ loading: true });
    let adminEmail: any = sessionStorage.getItem("email");
    let formdata = { ...this.props.formdata };
    let adminDomain = adminEmail.split("@");
    let userEmail = formdata.email;
    console.log(userEmail);
    let userDomain = userEmail.split("@");
    let reqDomain = adminDomain[1].split(".").join("_");
    let adminId = sessionStorage.getItem("user_id");
    /*Validation*/
    console.log("invitee email is --> " + userEmail);
    console.log("Admin Id is --> " + adminId);
    let invitationRef = firebase
      .database()
      .ref("domains/" + reqDomain + "/invitations")
      .push();
    let invitationID = invitationRef.key;
    console.log("Invitee mail ---" + userEmail);
    console.log("Invitation Key ---" + invitationID);
    /*Send Invitation*/
    let actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: `${window.location.origin}/employee-signup?invitationID=${invitationID}&domain=${adminDomain[1]}&adminId=${adminId}`,
      // This must be true.
      handleCodeInApp: true,
    };
    firebase
      .auth()
      .sendSignInLinkToEmail(formdata.email, actionCodeSettings)
      .then((response) => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        console.log("Send Link. Link send successfully.");
        /*Save Invitation In Firebase Database*/
        invitationRef
          .set({
            email: userEmail,
            date: Date.now(),
          })
          .then(() => {
            NotificationManager.success("Invitation send successfully!");
            this.props.onClose();
          })
          .catch((error) => {
            console.log("Error For Invitation." + error);
            NotificationManager.error(error.message);
          });
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        this.props.onClose();
        console.log("Send Link. Could not send Link." + error);
        NotificationManager.error(error.message);
      });
    //if (userDomain[1] === adminDomain[1]) {
    /*} else {
      console.log("User and Admin domains are not same.");
      NotificationManager.error(
        "Domain for admin and user email must be same."
      );
    }*/
  };

  render() {
    const { formdata, open, errors } = this.props;
    const { loading } = this.state;
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={this.props.onClose}
          aria-labelledby="form-dialog-title"
        >
          {loading && <LinearProgress />}
          <DialogTitle id="form-dialog-title">Invite Employee</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To add an Employee, please enter his/her email address here.
              Invitation Link will be sent to the given email immeadiately.
            </DialogContentText>
            <TextField
              autoFocus
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              name="email"
              value={formdata.email}
              onChange={this.props.onInputChange}
            />
            {errors.email ? (
              <div
                style={{ fontSize: "13px" }}
                className="text-left text-danger ml-2"
              >
                {errors.email}
              </div>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={this.props.onClose}
              color="primary"
              className="text-white"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => this.confirmInvite()}
              className="btn-info text-white"
            >
              Invite
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default InviteDialogueBox;
