/**
 * Chat Sidebar
 */
import React, { Component } from "react";
import { Button } from "reactstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Input } from "reactstrap";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
const { withRouter } = require("react-router-dom");
import Tooltip from "@material-ui/core/Tooltip";
import EmployeesList from "./EmployeesList";
import EnlistingDomains from "./EnlistingDomains";
import InvitationList from "./InvitationList";
import InvitationDialogueBox from "./SendInvitationDialogue";
import { Url } from "@amcharts/amcharts4/.internal/core/utils/Utils";
const { getAppLayout } = require("Helpers/helpers");
import { UserDetails } from "../index"

declare namespace JSX {
  interface IntrinsicElements {
    EmployeeListPanel: { [key: string]: any };
  }
}

type EmployeeListPanelProps = {
  onEditIconClick(any): void;
  onDeleteIconClick(any): void;
  onClose(): void;
  onPhoneInputChange(any): void;
  onInputChange(any): void;
  onUpdateSearch(any): void;
  onInviteIconClick(): void;
  location: Url;
  open: any;
  status: any;
  formdata: any;
  errors: any;
  serverstatus: any;
  employees: any;
  invitationList: any;
};

type EmployeeListPanelState = {
  activeTab: number;
};

class EmployeeListPanel extends Component<EmployeeListPanelProps, EmployeeListPanelState> {
  state = {
    activeTab: 0,
  };

  onEditIconClick = (data: { key: string, details: UserDetails }) => {
    this.props.onEditIconClick(data);
  };

  handleTabChange(value) {
    this.setState({ activeTab: value });
    console.log(value);
  }

  onDeleteIconClick = (data: { key: string, details: UserDetails }) => {
    this.props.onDeleteIconClick(data);
  };

  onClose = () => {
    this.props.onClose();
  };

  handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e);
    this.props.onPhoneInputChange(e);
  };
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onInputChange(e);
  };

  getScrollHeight() {
    const { location } = this.props;
    const appLayout = getAppLayout(location);
    switch (appLayout) {
      case "app":
        return "calc(100vh - 188px)";
      case "agency":
        return "calc(100vh - 372px)";
      case "boxed":
        return "calc(100vh - 372px)";
      case "horizontal":
        return "calc(100vh - 238px)";
      default:
        break;
    }
  }
  render() {
    console.log("In EmployeeListPanel ---");
    console.log("List of friends and family ---", this.props.employees);
    const { activeTab } = this.state;
    const {
      open,
      status,
      formdata,
      errors,
      serverstatus,
      employees,
      invitationList,
    } = this.props;
    return (
      <div className="chat-sidebar">
        {/* Invite Dialog box */}
        {status === "invite" ? (
          <InvitationDialogueBox
            formdata={formdata}
            open={open}
            onClose={this.onClose}
            errors={errors}
            onPhoneInputChange={this.handlePhoneInputChange}
          />
        ) : (
            ""
          )}
        <React.Fragment>
          <Tabs
            value={activeTab}
            onChange={(e, value) => this.handleTabChange(value)}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tooltip title="give permission to members" placement="bottom">
              <Tab
                icon={
                  <i
                    className="zmdi  zmdi-check-circle"
                  ></i>
                }
                label="Friends & Family"
              ></Tab>
            </Tooltip>
            <Tooltip
              title="give permission to organizations"
              placement="bottom"
            >
              <Tab
                icon={
                  <i
                    className="zmdi  zmdi-check-circle"
                  ></i>
                }
                label="Organisations"
              />
            </Tooltip>
            <Tooltip title="track members" placement="bottom">
              <Tab
                icon={
                  <i
                    className="zmdi zmdi-accounts-add"
                  ></i>
                }
                label="Invited Members"
              />
            </Tooltip>
          </Tabs>
        </React.Fragment>
        {activeTab === 0 ? (
          <React.Fragment>
            {/* Search bar */}
            <div>
              <div className="search-wrapper mb-0 position-relative">
                <Input
                  type="text"
                  name="search"
                  id="search"
                  className="search-input rounded-0 py-2 px-3"
                  placeholder="Search"
                  onChange={this.props.onInputChange}
                  value={formdata.search}
                />
                <i className="zmdi zmdi-search search-icon"></i>
              </div>
              {/* Invitations List */}
              <div className="chat-list">
                <Scrollbars
                  className="rct-scroll"
                  autoHide
                  style={{ height: this.getScrollHeight() }}
                >
                  <EmployeesList
                    searchedInput={formdata.search}
                    employees={employees}
                    serverstatus={serverstatus}
                    onEditIconClick={this.onEditIconClick}
                    onDeleteIconClick={this.onDeleteIconClick}
                  />
                </Scrollbars>
              </div>
            </div>
            <div
              style={{
                position: "fixed",
                bottom: ".6%",
                width: "472px",
                marginLeft: "3px",
              }}
            >
              <Button
                color="primary"
                block
                onClick={() => this.props.onInviteIconClick()}
              >
                Invite Users&nbsp;<i className="zmdi zmdi-accounts-add"></i>
              </Button>
            </div>
          </React.Fragment>
        ) : (
            ""
          )}
        {activeTab === 1 ? (
          <React.Fragment>
            {/* Search bar */}
            <div>
              {/* Invitations List */}
              <div className="chat-list">
                <EnlistingDomains
                  searchedInput={formdata.search}
                  employees={employees}
                  serverstatus={serverstatus}
                  onEditIconClick={this.onEditIconClick}
                  onDeleteIconClick={this.onDeleteIconClick}
                />
                {/* </Scrollbars> */}
              </div>
            </div>
            <div
              style={{
                position: "fixed",
                bottom: ".6%",
                width: "472px",
                marginLeft: "3px",
              }}
            >
              <Button
                color="primary"
                block
                onClick={() => this.props.onInviteIconClick()}
              >
                Invite Users&nbsp;<i className="zmdi zmdi-accounts-add"></i>
              </Button>
            </div>
          </React.Fragment>
        ) : (
            ""
          )}

        {activeTab === 2 ? (
          <React.Fragment>
            {/* Search bar */}
            <div>
              {/* Invitations List */}
              <div className="chat-list">
                <InvitationList
                  searchedInput={formdata.search}
                  employees={employees}
                  serverstatus={serverstatus}
                  onEditIconClick={this.onEditIconClick}
                  onDeleteIconClick={this.onDeleteIconClick}
                />
              </div>
            </div>
            <div
              style={{
                position: "fixed",
                bottom: ".6%",
                width: "472px",
                marginLeft: "3px",
              }}
            >
              <Button
                color="primary"
                block
                onClick={() => this.props.onInviteIconClick()}
              >
                Invite Users&nbsp;<i className="zmdi zmdi-accounts-add"></i>
              </Button>
            </div>
          </React.Fragment>
        ) : (
            ""
          )}
      </div>
    );
  }
}

const mapStateToProps = ({ chatAppReducer }: any) => {
  return chatAppReducer;
};

export default withRouter(connect(mapStateToProps)(EmployeeListPanel));
