import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { NotificationManager } from "react-notifications";
// const { NotificationManager } = require("react-notifications");
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import { apiURL } from "../../../constants/URL";

import PhoneInput from "react-phone-input-2";
// const { PhoneInput } = require("react-phone-input-2");
import "react-phone-input-2/lib/style.css";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

type Editprops = {
  onClose(): void;
  onInputChange(arg0: any): void;
  onPhoneInputChange(arg0: any): void;
  formdata: {
    userID: string;
    name: string;
    role: string;
    phone: string;
    email: string;
  };
  errors: any;
  open: any;
};

interface AppState {
  loading?: boolean;
}

class EditDialogueBox extends Component<Editprops, AppState> {
  constructor(props: Editprops) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  confirmEdit = () => {
    const { loading } = this.state;
    if (loading === false) {
      this.setState({ loading: true });
      // New way
      let adminEmail: any = sessionStorage.getItem("email");
      let adminDomain = adminEmail.split("@");
      let reqDomain = adminDomain[1].split(".").join("_");
      let data: any = { ...this.props.formdata };
      console.log("data details are", data);
      console.log("admin domain", reqDomain, "user domain", data.domain);
      if (data.domain !== reqDomain && data.role === "Admin") {
        console.log("Cannot update the user");
        NotificationManager.error(
          `${data.name} is already Admin of another company.`
        );
        this.setState({ loading: false });
      } else {
        console.log("user update started");
        axios
          .put(apiURL + `/${data.userID}/update`, {
            name: data.name,
            phone: data.phone,
            role: data.role,
            domain: reqDomain,
          })
          .then((response) => {
            console.log(
              "Username-- " +
                data.name +
                " UserID-- " +
                data.userID +
                " Contact-- " +
                data.phone +
                " Role-- " +
                data.role
            );

            console.log("Successfully updated user in auth and database ...");
            NotificationManager.success("Details updated successfully!");
            this.setState({ loading: false });
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.log("error is --->", error);
            NotificationManager.error(error.message);
          });
      }
    }
  };

  render() {
    const { formdata, open, errors } = this.props;
    const { loading } = this.state;
    let adminID = sessionStorage.getItem("user_id");
    return (
      <Dialog
        open={open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
      >
        {loading && <LinearProgress />}
        <DialogTitle id="form-dialog-title">Edit Employee Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit an Employee, please edit his/her details here.
          </DialogContentText>
          <TextField
            autoFocus
            id="name"
            label="Enter Employee name"
            type="text"
            fullWidth
            name="name"
            value={formdata.name}
            onChange={this.props.onInputChange}
          />
          {errors.name ? (
            <div
              style={{ fontSize: "13px" }}
              className="text-left text-danger ml-2"
            >
              {errors.name}
            </div>
          ) : (
            ""
          )}

          <div className="row mt-2">
            <div className="col-6">
              <div style={{ marginTop: "12px" }}>
                <PhoneInput
                  isValid={(value, country) => {
                    if (value.match(/12345/)) {
                      // return "Invalid value: " + value + ", " + country.name;
                      return "Invalid value: " + value + ", ";
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  // name="phone"
                  placeholder="Enter Phone Number"
                  inputStyle={{
                    // color: "blue",
                    width: "172px",
                    height: "35px",
                    border: "none",
                    boxShadow: "none",
                    borderBottom: "1px solid grey",
                  }}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  value={formdata.phone}
                  onChange={this.props.onPhoneInputChange}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextField
                  id="role"
                  name="role"
                  select
                  label="Role"
                  onChange={this.props.onInputChange}
                  value={formdata.role}
                  fullWidth
                  disabled={formdata.userID === adminID}
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Employee">Employee</MenuItem>
                </TextField>
                {errors.role ? (
                  <div
                    style={{ fontSize: "13px" }}
                    className="text-left text-danger ml-2"
                  >
                    {errors.role}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={this.props.onClose}
            color="primary"
            className="text-white"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => this.confirmEdit()}
            className="btn-info text-white"
          >
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditDialogueBox;
