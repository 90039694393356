/**
 * Profile Page
 */
import React, { Component } from "react";
import {
  FormGroup,
  Input,
  Form,
  Label,
  Col,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import PhoneInput from "react-phone-input-2";
// const PhoneInput = require("react-phone-input-2");
import "react-phone-input-2/lib/style.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { apiURL } from "../../../../constants/URL";
import { NotificationManager } from "react-notifications";

// intlmessages
import IntlMessages from "Util/IntlMessages";
import { logoutUserFromFirebase } from "../../../../actions";
// const IntlMessages = require('Util/IntlMessages');

type Anyobj = {
  [key: string]: any;
};

declare namespace JSX {
  interface IntrinsicElements {
    UserBlock: { [key: string]: any };
  }
}

type Indexprops = {
  formdata: {
    name: string;
    email: string;
    phone: any;
  };
  errors: Anyobj;
  loading: boolean;
  onPhoneInputChange(any): void;
  onInputChange(any): void;
  onUpdateProfile(any): void;
  logoutUserFromFirebase(arg0?: any): void;
};

type AppState = {
  // logoutUserFromFirebase?(arg0?: any): void;
  open: boolean;
  deleteloader: boolean;
};

class Profile extends Component<Indexprops, AppState> {
  _isMounted = false;
  constructor(props: Indexprops) {
    super(props);
    this.state = {
      open: false,
      deleteloader: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  confirmDelete = () => {
    console.log("user deletion is in process...");
    this.setState({ deleteloader: true });
    let userid = sessionStorage.getItem("user_id");
    // New way
    axios
      .delete(apiURL + `/${userid}/deleteprofile`)
      .then((response) => {
        console.log("User is deleted from the database completely..", response);
        this.setState({ open: false });
        this.props.logoutUserFromFirebase();
        NotificationManager.success(
          "Profile is deleted successfully, please signup again!"
        );
      })
      .catch((error) => {
        // this.setState({ loading: false });
        console.log("error is --->", error);
        NotificationManager.error(error.message);
        this.setState({ open: false, deleteloader: false });
      });
  };

  onDeleteProfile = () => {
    console.log("open the delete dialog box..");
    this.setState({ open: true });
  };

  closeDeleteDialogbox = () => {
    this.setState({ open: false, deleteloader: false });
  };

  render() {
    const { formdata, errors, loading } = this.props;
    const { name, email, phone } = formdata;
    const { open, deleteloader } = this.state;
    return (
      <div className="profile-wrapper w-50">
        <SweetAlert
          warning
          btnSize="sm"
          show={open}
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title={"Are you sure?"}
          onConfirm={this.confirmDelete}
          // onConfirm={console.log("sweet alert.")}
          onCancel={this.closeDeleteDialogbox}
        >
          {`${formdata.name}'s account  `}
          will be permanently deleted.
          <br />
          <br />
          {deleteloader ? <CircularProgress /> : null}
          <br />
        </SweetAlert>
        <h2 className="heading">
          <IntlMessages id="widgets.personalDetails" />
        </h2>
        <Form>
          <FormGroup row>
            <Label for="firstName" sm={3}>
              Name
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                name="name"
                id="firstName"
                className="input-lg"
                value={name}
                onChange={this.props.onInputChange}
              />
              {errors.name ? (
                <div
                  style={{ fontSize: "13px" }}
                  className="text-left text-danger ml-2"
                >
                  {errors.name}
                </div>
              ) : (
                ""
              )}
            </Col>
          </FormGroup>
          {/* <FormGroup row>
                  <Label for="lastName" sm={3}><IntlMessages id="components.lastName" /></Label>
                  <Col sm={9}>
                     <Input type="text" name="lastName" id="lastName" className="input-lg" />
                  </Col>
               </FormGroup> */}
          <FormGroup row>
            <Label for="occupation" sm={3}>
              E-mail ID
            </Label>
            <Col sm={9}>
              <Input
                type="text"
                name="email"
                id="occupation"
                className="input-lg"
                value={formdata.email}
                onChange={this.props.onInputChange}
              />
              {errors.email ? (
                <div
                  style={{ fontSize: "13px" }}
                  className="text-left text-danger ml-2"
                >
                  {errors.email}
                </div>
              ) : (
                ""
              )}
            </Col>
          </FormGroup>
          {/*<FormGroup row>
                  <Label for="company" sm={3}><IntlMessages id="components.companyName" /></Label>
                  <Col sm={9}>
                     <Input type="text" name="company" id="company" className="input-lg mb-20" />
                     <div className="help-text d-flex p-10">
                        <i className="ti-info-alt mr-15 pt-5"></i>
                        <span>If you want your invoices addressed to a company. Leave blank to use your full name.</span>
                     </div>
                  </Col>
               </FormGroup> */}
          <FormGroup row>
            <Label for="telephone" sm={3}>
              <IntlMessages id="components.phoneNo" />
            </Label>
            <Col sm={9}>
              {/* <Input type="tel" name="phone" id="telephone" className="input-lg" onChange={this.handleInputChange} /> */}
              <PhoneInput
                isValid={(value, country) => {
                  if (value.match(/12345/)) {
                    return "Invalid value: " + value + ", ";
                  } else if (value.match(/1234/)) {
                    return false;
                  } else if (value.length < 12 && value.length > 3) {
                    return "Enter a valid number";
                  } else {
                    return true;
                  }
                }}
                //name="phone"
                placeholder="Enter Phone Number"
                inputStyle={{
                  // color: "blue",
                  width: "100%",
                  height: "35px",
                  border: "none",
                  boxShadow: "none",
                  borderBottom: "1px solid grey",
                }}
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                value={formdata.phone}
                onChange={this.props.onPhoneInputChange}
              />
            </Col>
          </FormGroup>
        </Form>
        <hr />
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            color="primary"
            className="text-white mr-30 btn-lg"
            onClick={this.props.onUpdateProfile}
          >
            <IntlMessages id="widgets.updateProfile" />
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          className="text-white ml-30 btn-lg"
          onClick={this.onDeleteProfile}
        >
          Delete Profile
        </Button>
        <hr />

        {/* <h2 className="heading"><IntlMessages id="components.address" /></h2>
            <Form>
               <FormGroup row>
                  <Label for="address" sm={3}><IntlMessages id="components.address" /></Label>
                  <Col sm={9}>
                     <Input type="text" name="address" id="address" className="input-lg" />
                  </Col>
               </FormGroup>
               <FormGroup row>
                  <Label for="city" sm={3}><IntlMessages id="components.city" /></Label>
                  <Col sm={9}>
                     <Input type="text" name="city" id="city" className="input-lg" />
                  </Col>
               </FormGroup>
               <FormGroup row>
                  <Label for="state" sm={3}><IntlMessages id="components.state" /></Label>
                  <Col sm={9}>
                     <Input type="text" name="state" id="state" className="input-lg" />
                  </Col>
               </FormGroup>
               <FormGroup row>
                  <Label for="zip" sm={3}><IntlMessages id="components.zipCode" /></Label>
                  <Col sm={9}>
                     <Input type="text" name="zip" id="zip" className="input-lg" />
                  </Col>
               </FormGroup>
            </Form>
            <hr />
            <h2 className="heading"><IntlMessages id="components.social Connection" /></h2>
            <div>
               <InputGroup className="mb-20">
                  <InputGroupAddon addonType="prepend">
                     <IconButton aria-label="facebook">
                        <i className="zmdi zmdi-facebook"></i>
                     </IconButton>
                  </InputGroupAddon>
                  <Input defaultValue="https://www.facebook.com" />
               </InputGroup>
               <InputGroup className="mb-20">
                  <InputGroupAddon addonType="prepend">
                     <IconButton aria-label="facebook">
                        <i className="zmdi zmdi-twitter"></i>
                     </IconButton>
                  </InputGroupAddon>
                  <Input defaultValue="https://www.twitter.com" />
               </InputGroup>
               <InputGroup className="mb-20">
                  <InputGroupAddon addonType="prepend">
                     <IconButton aria-label="facebook">
                        <i className="zmdi zmdi-linkedin"></i>
                     </IconButton>
                  </InputGroupAddon>
                  <Input defaultValue="https://www.linkedin.com" />
               </InputGroup>
            </div> */}
      </div>
    );
  }
}

// const mapStateToProps = ({ settings }) => {
//    return settings;
// };

// export default connect(mapStateToProps, {
//    logoutUserFromFirebase,
// })(Profile);
export default Profile;
